import { ILanguageTexts } from '../ILanguageTexts';

const en_USLanguage: ILanguageTexts = {
  btnCancel: 'Cancel',
  btnCreate: 'Create',
  btnSave: 'Save',
  btnSaveSomething: 'Save {{something}}',
  btnEdit: 'Edit',
  btnTryAgain: 'Try again',
  btnDownloadQRCode: 'Download',
  btnAdd: 'Add {{something}}',
  btnClearSelection: 'Clear Selection',
  btnPublish: 'Publish',
  btnUnpublish: 'Unpublish',

  txtLoading: 'Loading {{something}}...',
  txtLoadFail: 'Failed to load {{type}}',
  tabDetails: 'Details',
  tabContentStream: 'Content streams',
  tabContentStreamPriority: 'Content Stream Priority',
  tabTargets: 'Targets',
  tabContent: 'Content',
  txtTarget: 'Target',
  txtWeekdays: 'Weekdays',
  txtPeriod: 'Period',
  txtAllDay: 'All Day',

  txtChoosePlaceHolder: 'Choose a file',
  txtPlaceholderSearch: 'Search',
  txtPlaceholderSelect: 'Select {{something}}',
  txtDefaulTextPlaceholder: 'Text',
  txtDefaulText: 'Default',
  txtChannelNamePlaceholder: 'Enter your channel name',
  txtChannelUnitPlaceholder: 'Choose the unit',
  txtChannelBrandPlaceholder: 'Choose the brand',
  txtPlaceholderCategoryFirst: 'Choose a category first',
  txtChooseCampaign: 'Choose a campaign',
  txtSelectedStory: 'Selected Story',
  txtSelectedContents: 'Selected Contents',

  txtWelcomeMessage: 'Welcome',
  txtUnit: 'Unit',
  txtBrands: 'Brands',
  txtChannels: 'Channels',
  txtQrStyles: 'QR Styles',
  txtStyle: 'Style',
  txtCms: 'CMS',
  txtCampaigns: 'Campaigns',
  txtCampaign: 'Campaign',
  txtTags: 'Tags',
  txtCategories: 'Categories',
  txtChooseCategory: 'Choose a category',
  txtCategory: 'Category',
  txtContentStream: 'Content Stream',
  txtUsers: 'Users',
  txtRoles: 'Roles and permissions',
  txtAdmin: 'ADMIN',
  txtDomain: 'Scannable domain',
  txtOrganisationExternalDomain: 'Organisation domain (optional)',
  txtTitle: 'Title',
  txtName: 'Name',
  txtContentLoadingErrorMessage: 'There was a problem loading the content status information. Try again later.',
  txtErrorMessage: 'An unexpected problem has occurred, please try again later',
  txtDomainExistsMessage: 'An organisation with the same domain already exists. Please choose a different one.',
  txtSuccess: 'Success',
  txtSuccessPublished: '{{something}} sucessfully published',
  txtSuccessUnpublished: '{{something}} sucessfully unpublished',
  txtSuccessCreated: '{{something}} sucessfully created',
  txtSuccessUpdated: '{{something}} sucessfully updated',
  txtError: 'Error',
  txtInformation: 'Information',
  txtLastUpdate: 'Last update',
  txtBy: 'By',
  txtJustNow: 'Just now',
  txtYesterday: 'Yesterday',
  txtAgo: 'ago',
  txtChangesSaved: 'Changes sucessfully saved',
  txtEditOrganisation: 'Edit organisation',
  txtMustSelectOrganisation: 'You must select an organisarion',
  txtChooseFile: 'Choose a file',
  txtTenantId: 'Tenant ID',
  txtUploadHere: 'to upload or drag it here',
  txtAcceptUpload: 'Only .jpg or .png file at 5 MB or less',
  txtCreateChannel: 'Create channel',
  txtLink: 'Link',
  txtLinkChannels: 'Link channels',
  txtLinkFallback: 'Link fallback',
  txtSaveToUpdate: 'Save to update',
  txtChangeFallback: 'Change fallback',
  txtFilter: 'Filters',
  txtArchive: 'Archive',
  txtSave: 'Save',
  txtEnterYourUnitName: 'Enter the name of the new unit',
  txtEnterYourBrandName: 'Enter the name of the new brand',
  txtTitlePlaceholder: 'Enter a title',
  txtChannel: 'Channel',
  txtBrand: 'Brand',
  txtOrganisation: 'Organisation',
  txtTargetingRules: 'Targeting rules',
  txtFallbackContent: 'Fallback content',
  txtFallbackQuestionMark: 'Fallback?',
  txtTargetingRulesQuestionMark: 'Targeting rules?',
  txtTime: 'Time',
  txtLocation: 'Location',
  txtScanHistory: 'Scan History',
  txtSchedule: 'Schedule',
  txtSelection: 'Selection',
  txtStories: 'Stories',

  txtMon: 'Mon',
  txtTue: 'Tue',
  txtWed: 'Wed',
  txtThu: 'Thu',
  txtFri: 'Fri',
  txtSat: 'Sat',
  txtSun: 'Sun',

  txtNoChannelsYet: 'No Channels created yet.',
  txtNoContentStreamYet: 'No Content Stream created yet.',
  txtNoContentStreamAssociatedYet: 'No Content Stream associated yet.',
  txtNoChannelsCreated:
    "It appears we don't have channels created yet. You can create a new channel using the button below.",
  txtNoContentStreamAssociated: 'Associate a Content Stream to this channel',
  txtTryAgain: 'Try Again',
  txtChannelUnit: 'Unit',
  txtChannelBrand: 'Brand',
  txtNewContentStream: 'New Content Stream',
  txtNewSomething: 'New {{something}}',
  txtCreateNewSomething: 'Create new {{something}}',
  txtFeedbackDownloadQRCode: 'It is necessary to fill and save Title, Unit and\nBrand fields to generate the QR code.',
  txtFeedbackEmptyQRCode:
    'No QR Code found for your Channel.\n Set a QR Style to generate a QR Code and personalize it for your Channel.',
  txtWatingQR: 'Waiting QR code generation',
  txtCreateContentStream: 'Create Content Stream',
  txtCreateFallback: 'Create Fallback',
  txtAssociateContentStream: 'Go to Content Streams',
  txtNoContentCreated:
    "It appears we don't have Content Streams created yet. You can create a new Content Stream using the button below.",
  txtSomethingWentWrong: 'Something went wrong',
  txtSomethingWentWrongTryAgain: 'Something went wrong. Please try again',
  txtTargetingFallbackContent: "Targets don't apply to fallback content",
  txtSaveAs: 'Save as {{something}}',
  txtFallback: 'fallback',
  txtFallbackUpCase: 'Fallback',
  txtSaveFallbackModal:
    "Content Streams without at least one targeting rule will be saved as a fallback. Are you sure you don't want to add a targeting rule?",
  txtFallbackDeleteTargetsModal:
    "Targeting rules do not apply to fallback content. If you select 'fallback content' for this content stream, all targeting rules will be removed.",
  txtDeleteSomething: 'Delete {{something}}',
  txtDiscardSomething: 'Discard {{something}}',
  txtInclude: 'Include',
  txtExclude: 'Exclude',
  txtMandatoryLocation: 'You must include at least one location to create a campaign.',
  txtSearchLocation: 'Type a location',
  txtStartDate: 'Start Date',
  txtTimeZone: 'Time Zone',
  txtEndDate: 'End Date',
  txtSetEndDate: 'Set an end date',
  txtSelectValidDate: 'Please select a valid date',
  txtSelectValidNumber: 'Please select a valid number',
  txtSelectValidEndDate: 'Please select an end date bigger than the start date',
  txtSelectValidEndDateCurrent: 'Please select an end date bigger than the current date',
  txtEnterValidEndTime: 'Please enter an end time bigger than the start time',
  txtEnterValidEndTimeCurrent: 'Please enter an end time bigger than the current time',
  txtEnterValidTime: 'Please enter a valid time',
  txtContentStreamChannels: 'Please link a channel to this content stream',
  txtFallbackContentStreamNoChannels:
    "We're sorry, but there are no channels linked to this content. To add or edit a fallback, please visit the channel page.",
  txtChannelsFallback: 'Please link a fallback to this channel',

  txtNoResultsForSearch: 'No results found with ',
  txtQRStyleCreationSuccess: 'QR style created',

  txtQRStyleUpdateSuccess: 'QR style updated',
  txtQRStyleNameUsed: 'Name is already in use',
  txtChannelsLinked: 'Channels Linked',
  txtQRTitlePlaceholder: 'Enter the title of the new QR style',
  txtDefaultQRStyleTitle: 'New QR Style',
  txtDotsTitle: 'Dots',
  txtDotsStyleLabel: 'Dots style',
  txtBackgroundTitle: 'Background',
  txtColorTypeLabel: 'Color type',
  txtSingleColor: 'Single color',
  txtGradientColor: 'Color gradient',
  txtColor: 'Color',
  txtCornersDotsTitle: 'Corners dot options',
  txtCornersDotsStyleLabel: 'Corners dot style',
  txtCornersSquareTitle: 'Corners square options',
  txtCornersSquareStyleLabel: 'Corners square style',
  txtQROptionsTitle: 'QR Options',
  txtQROptionsLabel: 'Error correction level',
  txtImageTitle: 'Image options',
  txtImageSizeLabel: 'Image size',
  txtMarginLabel: 'Margin',
  txtHideBackgroundDots: 'Hide background dots',
  txtFileUploadInfo: 'Only {{accept}} file at {{size}} MB or less',
  txtFileLabel: 'Choose a file',
  txtFileText: 'to upload or drag it here',
  txtFileSizeError: 'File exceeds size limit',
  txtFileFormatError: 'Wrong format',
  txtContentNavigation: 'Content Navigation',
  txtConfirmationModalLeaveEditTitle: 'Leave editing',
  txtConfirmationModalLeaveEditText: 'Are you sure you want to leave without saving? Your changes will not be saved.',
  txtButtonModalCancel: 'Leave without saving',
  txtButtonModal: 'Continue editing',
  txtUploadFormatError: 'Invalid file format',
  txtUploadSizeError: 'File size exceeds the maximum limit',
  txtNoContentsYet: 'No Contents created yet.',
  txtNoStoriesYet: 'No Stories created yet.',
  txtNoContentsCreated: "It appears we don't have contents created yet.",
  txtNoStoriesCreated: "It appears we don't have stories created yet. ",
  txtNoQRStylesCreatedTitle: 'No QR Styles created yet.',
  txtNoQRStylesCreated:
    "It appears we don't have QR Styles created yet. You can create a new QR Style using the button below.",
  txtCreateQRStyle: 'Create QR Style',
  txtFallbackAssociationTitle: 'Fallback Association',
  txtFallbackAssociation:
    'All channels need a fallback content associated with them. Access the channel page to link or edit.',
  txtAddChannelsToPublish: 'Add a channel to publish this Content Stream',
  txtAddTargetsToPublish: 'Add a target to publish this Content Stream',
  txtPublishedModalTitle: 'Saving will change published content',
  txtPublishedModalBody: 'This content is published, saving now can alter the customer experience.',
  txtDeleted: 'Deleted',
  txtUnpublished: 'Unpublished',
  txtCantLoadContent: "We couldn't load this content",
  txtInvalidStoryWarning: 'Add an active story to publish this content stream',
  txtInvalidContentWarning: 'Add an active content to publish this content stream',
  txtCreate: 'Create',
  txtClearAll: 'Clear All',
  txtSelectAll: 'Select All',
  txtNothingFound: 'Nothing found',
  txtSelected: 'selected',
  txtNothingFoundTypeToCreateANewOne: 'Nothing found. Type to create a new one',
  txtInvalidEmptyField: "Field can't be empty",
  txtAnalytics: 'Analytics',
  txtInvalidEmptySelection: 'Select at least one option',
  txtInvalidEmptySelectionLocation: "Selection can't be empty",
  txtOptions: 'options',
  txtValidDomainInput: "You've reached the 20 characters limit. Please shorten your input.",
  txtTitleUsed: 'Title is already in use',
  txtUniqueDomainTitle: 'Please select your domain carefully.',
  txtUniqueDomainMessage: 'Once registered, your chosen domain is permanent and cannot be modified.',
  txtOrganisationExternalDomainAlert: 'Remember to set up the link on your domain',
  txtConfigureCms: 'Access the CMS to configure this brand.',
  txtConfigureCmsTitle: 'Setup this brand style',
  txtGoToCms: 'Go to CMS',
  txtChannelFallbackWarning: 'A fallback is needed to enable this channel',
  txtTextCopied: 'Copied to clipboard!',
  txtColorErrorMessage: 'Choose a color other than the background color.',
  txtPreview: 'Preview',
  txtBrandFallbackTitle: 'Brand fallback',
  txtBrandFallbackPlaceHolder: 'Enter your brand website URL',
  txtValidURL: 'Use a valid URL',
  txtShortUrlPlaceholder: 'Channel URL',
  txtChannelLinkPlaceholder: 'Link will be generated after saving the Channel',
  txtAnalyticsEmptyStateTitle: 'No Dashboard created yet.',
  txtAnalyticsEmptyStateDescription:
    'Please wait a moment and try again later to check if the upgraded report features are available.',
  txtRotation: 'Rotation',
  txtGradientType: 'Gradient type',
};

export default en_USLanguage;
