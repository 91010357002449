import styled from '@emotion/styled';
import DSProgressIndicator from '../../../../shared/DSComponents/DSProgressIndicator/DSProgressIndicator';
import DSTree from '../../../../shared/DSComponents/DSTree/DSTree';

export const TreeContainer = styled.div({
  padding: '1.6rem',
  paddingTop: '0rem',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const ListTree = styled(DSTree)({
  padding: '0rem',
  '&::part(tree-container)': {
    padding: '0rem',
  },

  maxWidth: '100%',
  overflowX: 'hidden',
});

export const LoadingIndicator = styled(DSProgressIndicator)({
  marginTop: '60%',
  marginLeft: '35%',
});
