
export interface IQRValueChanges {
  newTitle?: boolean;
  newValue?: boolean;
}

export interface IPromise {
  loading: boolean;
  error: boolean;
  success: boolean;
  successToastMessage: string;
  errorToastMessage: string;
}

export interface IQRPageOptions {
  channelCount: number | null;
  loadingChannelCount: boolean;
  hasError: boolean;
  disableSaveButton: boolean;
}

export interface IQRImageOptions {
  imageSize: string;
  margin: string;
  hideBackgroundDots: boolean;
}
