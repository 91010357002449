import { useQuery } from '@tanstack/react-query';
import { qrStyleManager } from '../services';
import { QRStyleQueryEnum } from '../types';

export const useLoadQrStyle = (
  shouldCall: boolean,
  qrStyleId: string | undefined,
  organisationId: string | undefined,
) => {

  const canEnable = !shouldCall && !!qrStyleId && !!organisationId;

  const { data, isLoading , error, refetch } = useQuery({
    queryKey: [QRStyleQueryEnum.getQRStyles, qrStyleId, organisationId],
    queryFn: () => qrStyleManager.getQRStyleById(organisationId!, qrStyleId!),
    enabled: canEnable,
    retry: 3,
  });

  return {
    data,
    loading: isLoading,
    error: !!error,
    refetch,
  };
};
