import { useEffect } from 'react';
import { MenuItemEnum } from '../../../shared/types';
import { isArrayEmpty } from '../../../shared/utils';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useQRStyleState } from '../../QRStyle/hooks';

export const useManageQRStyleState = (dataQrCode: any) => {
  const { setHasAnyQRStyle } = useQRStyleState();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();

  useEffect(() => {
    const isQRStyleDataEmpty = selectedSideMenuItem === MenuItemEnum.QRSTYLES && isArrayEmpty(dataQrCode?.data);
    if (isQRStyleDataEmpty) {
      setHasAnyQRStyle(false);
    } else {
      setHasAnyQRStyle(true);
    }
  }, [dataQrCode?.data, selectedSideMenuItem, setHasAnyQRStyle]);
};
