import { useQuery } from '@tanstack/react-query';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar/hooks';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { qrStyleManager } from '../services';
import { QRStyleQueryEnum } from '../types';

const HOUR_IN_MILLISECONDS = 3600000;

export const useLoadChannelsLinked = () => {
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedOrganisation } = useSelectedOrganisationState();

  const { data, isFetching, isPending, error, refetch } = useQuery({
    queryKey: [QRStyleQueryEnum.getChannelsLinked],
    queryFn: () => qrStyleManager.getChannelCount(selectedOrganisation?.id ?? '', selectedSecondTierItem?.id ?? ''),
    staleTime: HOUR_IN_MILLISECONDS,
    retry: 3,
    retryDelay: 1000,
  });

  return {
    data: data ?? undefined,
    isLoading: isPending || isFetching,
    isError: !!error,
    refetch,
  };
};
