// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState FILE

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useCornersSquareOptionsState = () => {
  const cornersSquareOptions = useSliceState<IQRStyleState, 'cornersSquareOptions'>('cornersSquareOptions');
  const setCornersSquareOptions = useSliceSetter<IQRStyleActions, 'setCornersSquareOptions'>('setCornersSquareOptions');

  return { cornersSquareOptions, setCornersSquareOptions };
};
