import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSecondTierSelectedItemState } from './useSecondTierSelectedItemState';
import { MenuItemEnum } from '../../../shared/types/MenuItemEnum.enum';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';

export const useNavigateOnSelectionChange = () => {
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const navigate = useNavigate();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();

  useEffect(() => {
    if (
      selectedSecondTierItem &&
      (selectedSideMenuItem === MenuItemEnum.UNIT ||
        selectedSideMenuItem === MenuItemEnum.BRANDS ||
        selectedSideMenuItem === MenuItemEnum.QRSTYLES)
    ) {
      navigate(`${selectedSecondTierItem.id}`);
    }
  }, [navigate, selectedSecondTierItem, selectedSideMenuItem]);
};
