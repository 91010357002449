import { useContext } from 'react';
import { BoxContent } from '@connected-core-system/utils/frontend';
import { WppCard, WppInput } from '@wppopen/components-library-react';
import { translate } from '../../../shared/locales';
import { ICategoryTag } from '../../../shared/types';
import { TCategoryFormField } from '../../ChannelCreateEdit/types/CategoryFormField';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { TagCategoryForm } from '../../TagCategory';
import { TOption } from '../../TagCategory/types/TagCategoryFormField';
import { UpdatedCardInfo } from '../../UpdatedInfo';
import { ContentFormCreate } from '../types/ContentFormField';
import CampaignForm from './CampaignForm';
import { FormContainer, Container } from './styles/ContentStreamForm.styled';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface IDetailsProps {
  selectedCampaign: TOption[];
  setSelectedCampaign: (selectedCampaign: TOption[]) => void;
  setContentStreamTagsFromScreen: (contentStreamTagsFromScreen: TCategoryFormField[]) => void;
  newContent: ContentFormCreate;
  setNewContent: (newContent: ContentFormCreate) => void;
  existentEntityTags: ICategoryTag[];
  selectedContentStream?: ContentStreamTableDataType | null;
  reloadTagCategory: boolean;
  setReloadTagCategory: (reload: boolean) => void;
  requestsDone: IContentStreamRequestsDoneStates;
  setRequestsDone: (requestsDone: React.SetStateAction<IContentStreamRequestsDoneStates>) => void;
}

const Details: React.FC<IDetailsProps> = ({
  selectedContentStream,
  selectedCampaign,
  setSelectedCampaign,
  setContentStreamTagsFromScreen,
  newContent,
  setNewContent,
  existentEntityTags,
  reloadTagCategory,
  setReloadTagCategory,
  requestsDone,
  setRequestsDone,
}) => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const txtContentStreamNamePlaceholder = translate('txtTitlePlaceholder');
  const txtContentStreamTitle = translate('txtTitle');

  const { insideWppOs } = useContext(BackofficeAppContext);

  const handleInputChange = (value: string) => {
    setNewContent({
      ...newContent,
      name: value,
    });
  };

  return (
    <Container flex marg="24 0 16 0">
      <BoxContent w="75%" marg="0 16 0 0">
        <WppCard style={insideWppOs ? { marginBottom: '1rem' } : {}}>
          <FormContainer>
            <WppInput
              placeholder={txtContentStreamNamePlaceholder}
              data-testid="input-content-stream-title"
              required
              autoFocus
              name="title"
              labelConfig={{ text: txtContentStreamTitle }}
              value={newContent?.name || ''}
              onWppChange={(event: CustomEvent) => handleInputChange(event.detail.value)}
            />
          </FormContainer>
        </WppCard>
        {selectedOrganisation && (
          <TagCategoryForm
            loadedTagCategory={requestsDone.tags}
            setLoadedTagCategory={(hasLoadedTagCategory: boolean) => {
              setRequestsDone((prevLoadingStates) => ({
                ...prevLoadingStates,
                tags: hasLoadedTagCategory,
              }));
            }}
            organisationId={selectedOrganisation.id}
            setCategoryTags={setContentStreamTagsFromScreen}
            existentEntityTags={existentEntityTags}
            reloadTagCategory={reloadTagCategory}
            setReloadTagCategory={setReloadTagCategory}
          />
        )}
      </BoxContent>
      <BoxContent w="25%">
        <BoxContent marg={insideWppOs ? '0 0 10 0' : '0 0 24 0'} h="auto">
          <UpdatedCardInfo
            createMode={!selectedContentStream}
            userEmail={selectedContentStream?.updatedBy}
            lastUpdated={selectedContentStream ? selectedContentStream?.lastUpdate : new Date()}
          />
        </BoxContent>
        <BoxContent marg="0 0 24 0" h="auto">
          <CampaignForm
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            setRequestsDone={setRequestsDone}
          />
        </BoxContent>
      </BoxContent>
    </Container>
  );
};

export default Details;
