import { DotType, Gradient } from 'qr-code-styling';
import { ComponentProps, useCallback, useEffect, useMemo, useRef } from 'react';
import { RadioGroupChangeEvent, SelectChangeEventDetail } from '@wppopen/components-library';
import { WppRadioGroupCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { BoxContent, ExpandableCard } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { useQRStyleOptions } from '../hooks/useQRStyleOptions';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { QRColorType, QRGradientType } from '../types/QRStyleTypes';
import { DOTS_STYLE_DEFAULT_OPTIONS, DOTS_STYLE_OPTIONS, QRSTYLE_FORM } from '../utils/constants';
import { getColorErrorMessage, getGradientValues } from '../utils/QrStyleUtils';
import { ColorInputGrid } from './ColorInputGrid';
import { ColorTypeRadioGroup } from './ColorTypeRadioGroup';
import { CustomIconError } from './CustomIconError';
import GradientSelect from './GradientSelect';
import { GridContainer, StyledSelectDropDown } from './styles/ExpandablesStyle.styled';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';

export interface IDotsStyleProps {
  setHasError: (hasError: boolean) => void;
}

const DotsStyle: React.FC<IDotsStyleProps> = ({ setHasError }) => {
  const { dotsOptions, setDotsOptions, backgroundOptions, valueChanges, setValueChanges } = useQRStyleState();

  const hasGradient = dotsOptions?.gradient?.colorStops.length === 2;
  const { errorMessages, setErrorMessages, colorType, setColorType } = useQRStyleOptions(hasGradient);

  const isGradientTypeSelected = colorType === 'gradient' || hasGradient;

  const gradientRef = useRef<undefined | null | Gradient>(null);

  const gradientValues = useMemo(() => {
    return getGradientValues('Corners', dotsOptions?.gradient, dotsOptions?.color);
  }, [dotsOptions]);

  const getColors = useCallback(() => {
    if (isGradientTypeSelected) {
      return gradientValues.colorStops;
    } else {
      return [
        {
          color: dotsOptions?.color ?? gradientRef.current!.colorStops[0].color,
          offset: 0,
        },
      ];
    }
  }, [dotsOptions?.color, gradientValues.colorStops, isGradientTypeSelected]);

  useEffect(() => {
    setColorType(hasGradient ? 'gradient' : 'single');
  }, [hasGradient, setColorType]);

  useEffect(() => {
    const newErrorMessages = getColorErrorMessage(getColors(), backgroundOptions, isGradientTypeSelected);
    const hasErrorMessage = newErrorMessages[0] || newErrorMessages[1];

    if (hasErrorMessage) {
      setErrorMessages(newErrorMessages);
      setHasError(true);
    } else {
      setErrorMessages([]);
      setHasError(false);
    }
  }, [backgroundOptions, getColors, isGradientTypeSelected, setErrorMessages, setHasError]);

  const handleColorTypeChange = (newColorType: typeof colorType) => {
    setColorType(newColorType);
    if (newColorType === 'single') {
      gradientRef.current = dotsOptions?.gradient;
      setDotsOptions({
        ...dotsOptions,
        gradient: undefined,
      });
    } else {
      setDotsOptions({
        ...dotsOptions,
        gradient: gradientRef.current ?? undefined,
      });
    }
  };

  const handleCornersDotsTypeChange = (selectedDotType: DotType | 'default') => {
    const newDotType = selectedDotType !== 'default' ? selectedDotType : 'square';
    setDotsOptions({
      ...dotsOptions,
      type: newDotType as DotType,
    });
  };

  const handleRotationChange = (newRotation: string) => {
    if (gradientValues) {
      setDotsOptions({
        ...dotsOptions,
        gradient: { ...gradientValues, rotation: Number(newRotation) },
      });
    }
  };

  const handleGradientChange = (newGradientType: QRGradientType) => {
    if (gradientValues) {
      setDotsOptions({
        ...dotsOptions,
        gradient: { ...gradientValues, type: newGradientType },
      });
    }
  };

  const handleChangeColor = (params: ComponentProps<typeof ColorInputGrid>['color']) => {
    if (gradientValues) {
      setDotsOptions({
        ...dotsOptions,
        color: params[0].color,
        gradient: { ...gradientValues, colorStops: params },
      });
      setValueChanges({ ...valueChanges, newValue: true });
    }
  };

  const handleChange = (
    field: string,
    event: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.dotsType:
        handleCornersDotsTypeChange(event.detail.value as DotType);
        break;
      case QRSTYLE_FORM.colorType:
        handleColorTypeChange(event.target.value as QRColorType);
        break;
      case QRSTYLE_FORM.rotation:
        handleRotationChange(event.detail.value as string);
        break;
      case QRSTYLE_FORM.gradient:
        handleGradientChange(event.target.value as QRGradientType);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  return (
    <ExpandableCard title={translate('txtDotsTitle')}>
      <CustomIconError errorMessage={errorMessages} />
      <BoxContent direction="column" data-testid="dots-style-container">
        <GridContainer>
          <DSTypography type="s-strong">{translate('txtDotsStyleLabel')}</DSTypography>
          <DSTypography type="s-strong">{translate('txtColorTypeLabel')}</DSTypography>
          <div>&nbsp;</div>

          <StyledSelectDropDown
            data-testid='dots-style-dropdown'
            options={DOTS_STYLE_OPTIONS}
            value={dotsOptions?.type ?? DOTS_STYLE_DEFAULT_OPTIONS.DOTS_TYPE}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.dotsType, e)}
          />
          <ColorTypeRadioGroup colorType={colorType} handleChange={handleChange} />
        </GridContainer>
        <GradientSelect
          isGradientTypeSelected={isGradientTypeSelected}
          rotation={gradientValues?.rotation?.toString() ?? DOTS_STYLE_DEFAULT_OPTIONS.ROTATION}
          gradientType={gradientValues?.type ?? DOTS_STYLE_DEFAULT_OPTIONS.GRADIENT_TYPE}
          handleChange={handleChange}
        />
        <ColorInputGrid
          color={getColors()}
          onChangeColor={handleChangeColor}
          disabled={false}
          isGradient={isGradientTypeSelected}
          errorMessage={errorMessages}
        />
      </BoxContent>
    </ExpandableCard>
  );
};

export default DotsStyle;
