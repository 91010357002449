// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleSlice

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useQRCodeOptionsState = () => {
  const qrCodeOptions = useSliceState<IQRStyleState, 'qrCodeOptions'>('qrCodeOptions');
  const setQRCodeOptions = useSliceSetter<IQRStyleActions, 'setQRCodeOptions'>('setQRCodeOptions');

  return { qrCodeOptions, setQRCodeOptions };
};
