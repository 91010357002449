import { useState } from 'react';
import { QRColorType } from '../types';

export const useQRStyleOptions = (hasGradient?: boolean) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [colorType, setColorType] = useState<QRColorType>(() => {
    return hasGradient ? 'gradient' : 'single';
  });

  return {
    colorType,
    errorMessages,
    setErrorMessages,
    setColorType,
  };
};
