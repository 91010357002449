import { useEffect, useState } from 'react';
import { useSecondTierItemIdToSelectOnReloadState } from './useSecondTierItemIdToSelectOnReloadState';
import { useSecondTierSelectedItemState } from './useSecondTierSelectedItemState';
import { SecondTierSidebarItem } from '../types/SecondTierSidebarItem';
import { compareArrays } from '../../../shared/utils';

export const useInitializeSelectedItem = (itemList: SecondTierSidebarItem[]) => {
  const { itemIdToSelectOnReload, setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const [previousItemList, setPreviousItemList] = useState<SecondTierSidebarItem[]>([]);

  useEffect(() => {
    const hasChangedItemList = itemList?.length > 0 && !compareArrays(itemList, previousItemList);

    if (hasChangedItemList) {
      const itemToSelect = itemList.find((item) => item.id === itemIdToSelectOnReload) ?? itemList[0];

      setSelectedSecondTierItem(itemToSelect);
      setPreviousItemList(itemList);
    }
  }, [itemList, itemIdToSelectOnReload, setSelectedSecondTierItem, setItemIdToSelectOnReload, previousItemList]);
};
