// TODO 4231 - CHECK IMPORTS ORDER: 3RD PARTY IMPORTS, SHARED, FEATURES, CURRENT FEATURE
import React, { useEffect, useState } from 'react';
import { WppInput } from '@wppopen/components-library-react';
import { translate } from '../../../shared/locales';
import { TitleContainerBare, TitleContainerOs } from '../styles/QRStylePage.styled';
import { useValueChangesState } from '../hooks/useValueChangesState';
import { useQRTitleState } from '../hooks/useQRTitleState';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { StatusEnum } from '@connected-core-system/utils/types';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQRTitleProps {}

const QRTitle: React.FC<IQRTitleProps> = () => {

  // TODO 4231 - IS IT REALLY NECESSARY THIS LOCAL STATE? 
  // MAYBE, SINCE THERE IS A GLOBAL STATE FOR THE TITLE THE COMPONENT SHOULD REFLECT WHAT IS IN THE GLOBAL STATE AND CHANGE THERE 
  // WHEN handleInputChange
  // WITH THAT, WE'LL NOT NEED EVEN THE USE EFFECTS DOWN THERE TO SYNC A LOCAL STATE WITH THE GLOBAL
  const [styleTitle, setStyleTitle] = useState<string>('');

  const { valueChanges, setValueChanges } = useValueChangesState();
  const { setTitle } = useQRTitleState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const handleInputChange = (value: string) => {
    setStyleTitle(value);
    setValueChanges({
      ...valueChanges,
      newTitle: selectedSecondTierItem?.name !== value,
    });
  };

  // TODO 4231 - MAYBE THIS IS NOT NECESSARY
  useEffect(() => {
    if (selectedSecondTierItem?.name && selectedSecondTierItem?.status !== StatusEnum.UNPUBLISHED) {
      setStyleTitle(selectedSecondTierItem?.name);
    } else {
      setStyleTitle('');
    }
  }, [selectedSecondTierItem]);

  // TODO 4231 - MAYBE THIS IS NOT NECESSARY
  useEffect(() => {
    setTitle(styleTitle);
  }, [styleTitle, setTitle]);

  return (
    <PlatformWrapper componentBare={TitleContainerBare} componentOs={TitleContainerOs} data-testid="title-container">
      <WppInput
        data-testid="input-title"
        autoFocus
        name="title"
        labelConfig={{ text: translate('txtTitle') }}
        size="m"
        required
        placeholder={translate('txtQRTitlePlaceholder')}
        value={styleTitle}
        onWppChange={(event: CustomEvent) => handleInputChange(event.detail.value)}
      />
    </PlatformWrapper>
  );
};

export default QRTitle;
