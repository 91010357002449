import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useSecondTierData } from './useSecondTierData';

export const useSecondTierSidebar = () => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();

  const { getData, getLoadingStatus, getErrorStatus, refetchData } = useSecondTierData(
    selectedOrganisation?.id ?? '',
    selectedSideMenuItem,
  );

  const currentData = selectedSideMenuItem ? getData(selectedSideMenuItem) : [];
  const isLoading = selectedSideMenuItem ? getLoadingStatus(selectedSideMenuItem) : false;
  const isError = selectedSideMenuItem ? getErrorStatus(selectedSideMenuItem) : false;

  return {
    data: currentData,
    isLoading,
    isError,
    refetchData,
  };
};
