/* eslint-disable @typescript-eslint/no-empty-interface */
import { TabsChangeEventDetail } from '@wppopen/components-library';
import { WppTabs } from '@wppopen/components-library-react';
import React from 'react';
import { StyledIconWarning, StyledTab } from './Tabs.styled';

export interface ITabsProps {
  tabs: Array<{
    title: string;
    value: string;
    hasWarning?: boolean;
  }>;
  handleTabChange?: (event: CustomEvent<TabsChangeEventDetail>) => void;
  selectedTab: string;
}

const Tabs: React.FC<ITabsProps> = ({ tabs, handleTabChange, selectedTab }) => {
  return (
    <WppTabs value={selectedTab} onWppChange={handleTabChange}>
      {tabs.map((a) => (
        <StyledTab value={a.value} key={a.value}>
          {a.hasWarning && <StyledIconWarning data-testid="wpp-icon-warning" size="s" />}
          {a.title}
        </StyledTab>
      ))}
    </WppTabs>
  );
};

export default Tabs;
