import { Options } from 'qr-code-styling';

export type QRGradientType = 'linear' | 'radial';
export type QRColorType = 'single' | 'gradient';

export type QRDotsOptions = Options['dotsOptions'];
export type QRCornersSquareOptions = Options['cornersSquareOptions'];
export type QRCornersDotsOptions = Options['cornersDotOptions'];
export type QROptions = Options['qrOptions'];
export type QRImageOptions = Options['imageOptions'];
export type QRBackgroundOptions = Options['backgroundOptions'];

export type QRCodeOptions = Options;
export type QRDefaultType = 'Background' | 'Corners' | 'Dots';
export type CallbackType = 'create' | 'update';
