import { GradientType } from 'qr-code-styling';
import { SelectChangeEventDetail, RadioGroupChangeEvent } from '@wppopen/components-library';
import { WppSelectCustomEvent, WppRadioGroupCustomEvent } from '@wppopen/components-library/dist/types/components';
import { translate } from '../../../shared/locales';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import DSRadio from '../../../shared/DSComponents/DSRadio/DSRadio';
import { QRSTYLE_FORM } from '../utils/constants';
import { GridContainer, StyledInput, StyledRadioGroup, RadioContainer } from './styles/ExpandablesStyle.styled';

const GradientSelect = ({
  isGradientTypeSelected,
  rotation,
  gradientType,
  handleChange,
}: {
  isGradientTypeSelected: boolean;
  rotation: string;
  gradientType: GradientType;
  handleChange: (
    field: string,
    event: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => void;
}) => {
  if (!isGradientTypeSelected) {
    return null;
  }

  return (
    <GridContainer data-testid="grid-container">
      <DSTypography type="s-strong">{translate('txtRotation')}</DSTypography>
      <DSTypography type="s-strong">{translate('txtGradientType')}</DSTypography>
      <div>&nbsp;</div>

      <StyledInput
        type="number"
        value={rotation as string}
        onWppChange={(e) => handleChange(QRSTYLE_FORM.rotation, e)}
        data-testid="rotation-input"
      />

      <StyledRadioGroup
        value={gradientType}
        onWppChange={(e) => handleChange(QRSTYLE_FORM.gradient, e)}
        data-testid="gradient-type-radio-group">
        <RadioContainer>
          <DSRadio
            name="linear"
            value="linear"
            labelConfig={{ text: 'Linear' }}
            required
            checked={gradientType === 'linear'}
            data-testid="linear-radio"
          />
          <DSRadio
            name="radial"
            value="radial"
            labelConfig={{ text: 'Radial' }}
            required
            checked={gradientType === 'radial'}
            data-testid="radial-radio"
          />
        </RadioContainer>
      </StyledRadioGroup>
    </GridContainer>
  );
};

export default GradientSelect;
