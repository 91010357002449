import { WppGrid } from '@wppopen/components-library-react';
import { ComponentProps } from 'react';

export interface IDSGrid extends ComponentProps<typeof WppGrid> {}

const DSGrid = ({ children, ...wppProps }: IDSGrid) => {
  return <WppGrid {...wppProps}>{children}</WppGrid>;
};

export default DSGrid;
