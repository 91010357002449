import { useQuery } from "@tanstack/react-query";
import { OrganisationManager } from "../services";
import { OrganisationQueryEnum } from "../types/OrganisationQueryEnum";

const HOUR_IN_MILLISECONDS = 3600000;

export const useLoadOrganisation = () => {
  const { data, isFetching, isPending, error, refetch } = useQuery({
    queryKey: [OrganisationQueryEnum.getOrganisations],
    queryFn: () => OrganisationManager.getOrganisations(),
    staleTime: HOUR_IN_MILLISECONDS,
    retry: 3,
    retryDelay: 1000,
  });

  return {
    data: data ?? undefined,
    loading: isPending || isFetching,
    error: !!error,
    refetch,
  };
}