import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { ISecondTierSidebarActions, ISecondTierSidebarState } from '../sliceStore/secondTierSidebarSlice';

export const useSecondTierSelectedItemState = () => {
  const selectedSecondTierItem = useSliceState<ISecondTierSidebarState, 'selectedSecondTierItem'>(
    'selectedSecondTierItem',
  );
  const setSelectedSecondTierItem = useSliceSetter<ISecondTierSidebarActions, 'setSelectedSecondTierItem'>(
    'setSelectedSecondTierItem',
  );

  return { selectedSecondTierItem, setSelectedSecondTierItem };
};
