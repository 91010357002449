import { useState } from 'react';
import { IPromise, IQRPageOptions } from '../types';

export const useQRPageOptions = () => {
  const [hasDotStyleError, setHasDotStyleError] = useState<boolean>(false);
  const [hasCornerSquareError, setHasCornerSquareError] = useState<boolean>(false);
  const [hasCornerDotError, setHasCornerDotError] = useState<boolean>(false);
  const [qrPageOptions, setQRPageOptions] = useState<IQRPageOptions>({
    channelCount: null,
    loadingChannelCount: false,
    hasError: false,
    disableSaveButton: true,
  });

  return {
    qrPageOptions,
    setQRPageOptions,
    hasDotStyleError,
    setHasDotStyleError,
    hasCornerSquareError,
    setHasCornerSquareError,
    hasCornerDotError,
    setHasCornerDotError,
  };
};
