// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useDotsOptionsState = () => {
  const dotsOptions = useSliceState<IQRStyleState, 'dotsOptions'>('dotsOptions');
  const setDotsOptions = useSliceSetter<IQRStyleActions, 'setDotsOptions'>('setDotsOptions');

  return { dotsOptions, setDotsOptions };
};
