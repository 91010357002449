import { useMutation } from '@tanstack/react-query';
import { QRCodeTemplateDTO, QRCodeTemplatePutOrPostDto } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { UniquePropertyExistsException } from '@core/exceptions';
import { qrStyleManager } from '../services';
import { CallbackType } from '../types';

export const useQRStyleSendData = (
  onSuccessCallback: (type: CallbackType, response: void | QRCodeTemplateDTO | UniquePropertyExistsException) => void,
  onErrorCallback: () => void,
) => {
  const { mutate: createQRStyle, isPending: isPendingCreateQR, isSuccess: isSuccessCreateQR, isError: isErrorCreateQR  } = useMutation({
    mutationFn: ({ organisationId, qrStyle }: { organisationId: string; qrStyle: QRCodeTemplatePutOrPostDto }) =>
      qrStyleManager.createQRStyle(organisationId, qrStyle),
    onSuccess: (data: void | QRCodeTemplateDTO | UniquePropertyExistsException) => {
      onSuccessCallback('create', data);
    },
    onError: () => {
      onErrorCallback();
    },
  });

  const { mutate: updateQRStyle, isPending: isPendingUpdateQR, isSuccess: isSuccessUpdateQR, isError: isErrorUpdateQR } = useMutation({
    mutationFn: ({
      organisationId,
      templateId,
      qrStyle,
    }: {
      organisationId: string;
      templateId: string;
      qrStyle: QRCodeTemplatePutOrPostDto;
    }) => qrStyleManager.updateQRStyle(organisationId, templateId, qrStyle),
    onSuccess: (data: void | QRCodeTemplateDTO | UniquePropertyExistsException) => {
      onSuccessCallback('update', data);
    },
    onError: () => {
      onErrorCallback();
    },
  });

  return {
    isLoading: isPendingCreateQR || isPendingUpdateQR,
    isSuccess: isSuccessCreateQR || isSuccessUpdateQR,
    isError: isErrorCreateQR || isErrorUpdateQR,
    createQRStyle,
    updateQRStyle,
  };
};
