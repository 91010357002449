/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  WppIconSearch,
  WppIconCross,
  WppInput,
  WppGrid,
  WppDivider,
  WppAvatar,
  WppActionButton,
} from '@wppopen/components-library-react';
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { BoxContent, ConditionalTooltip } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import { ISideMenuState } from '../../SideMenu/sliceStore/sideMenuSliceStore';
import OrganisationOption from './OrgarnisationOption';
import {
  OrganisationsListContainer,
  SearchInputContainer,
  SyledWppSelect,
  SyledWppProgressIndicator,
} from './styles/SelectOrganisation.styled';
import { filterOrganisations, findOrganisationById } from '../../SideMenu/utils/sideMenuUtils';
import { OrganisationForm } from '../../OrganisationCreate';
import {
  IOrganisationCreateActions,
  IOrganisationCreateState,
} from '../../OrganisationCreate/sliceStore/organisationCreateSliceStore';
import { IOrganisation, MenuItemEnum } from '../../../shared/types';
import OrganisationMoreActions from './OrganisationMoreActions';
import { useSelectedOrganisation } from '../../SideMenu';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { getStringEllipsed } from '../../../shared/utils';
import { useLoadOrganisation } from '../hooks/useLoadOrganisation';

export interface ISelectOrganisationProps {}

const ORGANISATION_TITLE_MAX_LENGHT = 16;
const ORGANISATION_OPTION_MAX_LENGHT = 16;

const SelectOrganisation: React.FC<ISelectOrganisationProps> = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedOrganisation, setSelectedOrganisation } = useSelectedOrganisation();
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();

  // TODO 4228 - MOVE THIS useSliceState TO A CUSTOM HOOK
  
  const isModalOpened = useSliceState<IOrganisationCreateState, 'isModalOpened'>('isModalOpened');
  const isMenuOpened = useSliceState<ISideMenuState, 'isMenuOpened'>('isMenuOpened');
  const setOpenModalWithAction = useSliceSetter<IOrganisationCreateActions, 'setOpenModalWithAction'>(
    'setOpenModalWithAction',
  );

  // REMOVE AND USE "data" OF CUSTOM HOOK IN listOrganisation places
  const [listOrganisation, setListOrganisation] = useState<IOrganisation[]>([]);

  const { data, loading, error, refetch } = useLoadOrganisation();

  // TODO 4228 - NOW WE HAVE A HOOK WITH USE QUERY, THEN REMOVE THIS USE EFFECT AND USE "DATA" IN PLACES WHERE listOrganisation IS USED
  useEffect(() => {
    if (data && !loading) {
      setListOrganisation(data);
    } else {
      setListOrganisation([]);
    }
  }, [data, loading]);

  const handleSelectOrganisation = (organisationId: string) => {
    if (listOrganisation) {
      const organisation = findOrganisationById(listOrganisation, organisationId);
      if (organisation) {
        setSelectedOrganisation(organisation);
        setSelectedSideMenuItem(MenuItemEnum.UNIT);
        navigate(`${organisation.id}/${MenuItemEnum.UNIT}`);
      }
    }
  };

  const handleClickCreateOrganisation = () => {
    setOpenModalWithAction(true);
  };

  const renderColapsed = () => {
    return (
      <BoxContent flex center direction="column">
        <div>
          {isModalOpened && (
            <OrganisationForm organisationList={listOrganisation} setOrganisationList={setListOrganisation} />
          )}
        </div>
        <WppAvatar src={selectedOrganisation?.filePath} size="m" color={selectedOrganisation?.avatarColor} />
        <div>
          <OrganisationMoreActions />
        </div>
      </BoxContent>
    );
  };

  const renderOrganisationsList = () => {
    return (
      <OrganisationsListContainer flex direction="column">
        {/* TODO 4228 - AVOID CONDITIONAL RENDERING WITH EXPRESSIONS ({... && ...}) OR TERNARY OPERATOR IN JSX- CREATE A RENDER FUNCTION WITH IF STATEMENT (MAKE THE CODE MORE READABLE) */}
        {loading && (
          <BoxContent marg="0 0 0 0">
            <SyledWppProgressIndicator variant="circle" data-testid='spinner' />
          </BoxContent>
        )}
        {listOrganisation &&
          filterOrganisations(listOrganisation, searchTerm).map((o) => (
            <BoxContent marg="2 0" key={o.id}>
              <OrganisationOption
                organisation={o}
                maxLenght={ORGANISATION_OPTION_MAX_LENGHT}
                active={o.id === selectedOrganisation?.id}
              />
            </BoxContent>
          ))}
        {error && (
          <EmptyState
            title={translate('txtSomethingWentWrong')}
            text={translate('txtErrorMessage')}
            buttonText={translate('txtTryAgain')}
            buttonAction={() => {
              refetch();
            }}
          />
        )}
      </OrganisationsListContainer>
    );
  };

  const renderWppSelect = () => {
    return (
      <ConditionalTooltip
        showTooltip={selectedOrganisation ? selectedOrganisation.title?.length > ORGANISATION_TITLE_MAX_LENGHT : false}
        tooltipText={selectedOrganisation ? selectedOrganisation.title : ''}
        config={{ placement: 'bottom' }}>
        <SyledWppSelect
          type="text"
          onWppChange={(e: CustomEvent) => handleSelectOrganisation(e.detail.value)}
          placeholder={
            selectedOrganisation
              ? getStringEllipsed(selectedOrganisation.title, ORGANISATION_TITLE_MAX_LENGHT)
              : translate('txtPlaceholderSelect', { something: '' })
          }
          value={selectedOrganisation?.id}>
          <SearchInputContainer>
            <WppInput
              data-testid="input-search-org"
              placeholder={translate('txtPlaceholderSearch')}
              message=""
              value={searchTerm}
              onWppChange={(e: CustomEvent) => {
                setSearchTerm(e.detail.value);
              }}
              type="search">
              <WppIconSearch slot="icon-start" aria-label="Search icon" />
              <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={() => setSearchTerm('')} />
            </WppInput>
            {renderOrganisationsList()}
          </SearchInputContainer>
          {/* TODO 4228 - COMPONENT AUTO-CLOSED */}
          <WppDivider></WppDivider>
          <div>
            <BoxContent pad="8 16 4 16" w="auto">
              <WppActionButton onClick={() => handleClickCreateOrganisation()} variant="primary">
                {translate('txtCreateNewSomething', { something: translate('txtOrganisation') })}
              </WppActionButton>
            </BoxContent>
          </div>
        </SyledWppSelect>
      </ConditionalTooltip>
    );
  };

  const renderExpanded = () => {
    return (
      <WppGrid container fullHeight direction="row" alignItems="center" justifyContent="space-between">
        <WppGrid container item direction="row" all={20} alignItems="center">
          <BoxContent flex align="center">
            <BoxContent marg="0 4 0 0" w="auto">
              <WppAvatar src={selectedOrganisation?.filePath} size="m" color={selectedOrganisation?.avatarColor} />
            </BoxContent>
            <div>
            {/* TODO 4228 - ADJUST THIS CONDITIONAL RENDERING */}
              {isModalOpened && renderWppSelect()}
              {!isModalOpened && renderWppSelect()}
              {isModalOpened && (
                <OrganisationForm organisationList={listOrganisation} setOrganisationList={setListOrganisation} />
              )}
            </div>
          </BoxContent>
        </WppGrid>
        <WppGrid item fullHeight container all={4} alignItems="center" justifyContent="flex-end">
          <OrganisationMoreActions />
        </WppGrid>
      </WppGrid>
    );
  };

  return (
    <BoxContent flex align="center">
      {/* TODO 4228 - AVOID TERNARY RENDERING  */}
      {isMenuOpened ? renderExpanded() : renderColapsed()}
    </BoxContent>
  );
};

export default SelectOrganisation;
