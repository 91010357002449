// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useImageOptionsState = () => {
  const imageOptions = useSliceState<IQRStyleState, 'imageOptions'>('imageOptions');
  const setImageOptions = useSliceSetter<IQRStyleActions, 'setImageOptions'>('setImageOptions');

  return { imageOptions, setImageOptions };
};
