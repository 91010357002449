import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { ISecondTierSidebarActions, ISecondTierSidebarState } from '../sliceStore/secondTierSidebarSlice';

export const useSecondTierItemIdToSelectOnReloadState = () => {
  const setItemIdToSelectOnReload = useSliceSetter<ISecondTierSidebarActions, 'setItemIdToSelectOnReload'>(
    'setItemIdToSelectOnReload',
  );
  const itemIdToSelectOnReload = useSliceState<ISecondTierSidebarState, 'itemIdToSelectOnReload'>(
    'itemIdToSelectOnReload',
  );

  return { itemIdToSelectOnReload, setItemIdToSelectOnReload };
};
