// TODO 4231 - CHECK IMPORTS ORDER: 3RD PARTY IMPORTS, SHARED, FEATURES, CURRENT FEATURE
import React, { useMemo, useEffect, useState } from 'react';
import {
  CornerDotType,
  CornerSquareType,
  DrawType,
  ErrorCorrectionLevel,
  Mode,
  Options,
  TypeNumber,
} from 'qr-code-styling';
import { useBackgroundOptionsState } from '../hooks/useBackgroundOptionsState';
import { useDotsOptionsState } from '../hooks/useDotsOptionsState';
import { useCornersSquareOptionsState } from '../hooks/useCornersSquareOptionsState';
import { useCornersDotsOptionsState } from '../hooks/useCornersDotsOptionsState';
import { useImageOptionsState } from '../hooks/useImageOptionsState';
import { useQROptionsStyleState } from '../hooks/useQROptionsStyleState';
import { useImageState } from '../hooks/useImageState';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import QRCodePreview from '../../../shared/components/QRCodePreview/QRCodePreview';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQRStylePreviewProps {}

// TODO 4231 - MOVE INITIAL OPTIONS TO FEATURE UTILS FILE
const DEFAULT_OPTIONS: Options = {
  width: 300,
  height: 300,
  type: 'svg' as DrawType,
  data: 'Https://url.url.com/channel/1e0f2b22-2beb-4c64-9e08',
  margin: 10,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel,
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 0,
    crossOrigin: 'anonymous',
  },
  backgroundOptions: {
    color: '#FFF',
    gradient: undefined,
    round: undefined,
  },
  dotsOptions: {
    color: '#000',
    type: 'square',
    gradient: undefined,
  },
  cornersDotOptions: {
    color: '#000',
    type: 'square',
    gradient: undefined,
  },
  cornersSquareOptions: {
    color: '#000',
    type: 'square',
    gradient: undefined,
  },
  image: '',
};

const QRStylePreview: React.FC<IQRStylePreviewProps> = () => {
  const { dotsOptions } = useDotsOptionsState();
  const { cornersSquareOptions } = useCornersSquareOptionsState();
  const { cornersDotOptions } = useCornersDotsOptionsState();
  const { backgroundOptions } = useBackgroundOptionsState();
  const { imageOptions } = useImageOptionsState();
  const { qrOptions } = useQROptionsStyleState();
  const { image } = useImageState();

  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const validImage = useMemo(() => {
    const isValidDataURL = (url: string): boolean => {
      const regex = /^data:([a-z]+\/[a-z\-]+(;[a-z\-]+=[a-z\-]+)?)?(;base64)?,[a-zA-Z0-9!$&',()*+;=\-._~:@\/?\s]*$/i;
      return regex.test(url);
    };
    return image && isValidDataURL(image) ? image : undefined;
  }, [image]);

  const [options, setOptions] = useState<Options>(DEFAULT_OPTIONS);

  useEffect(() => {
    setOptions((options) => ({
      ...options,
      dotsOptions,
      cornersSquareOptions: {
        ...cornersSquareOptions,
        type: !cornersSquareOptions?.type ? ('' as CornerSquareType) : cornersSquareOptions.type,
      },
      cornersDotOptions: {
        ...cornersDotOptions,
        type: !cornersDotOptions?.type ? ('' as CornerDotType) : cornersDotOptions.type,
      },
      backgroundOptions,
      imageOptions,
      qrOptions,
      image: validImage,
    }));
  }, [
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    imageOptions,
    qrOptions,
    image,
    validImage,
  ]);

  useEffect(() => {
    // TODO 4231 - AVOID ONE LINE IF STATEMENT
    const resetStateOnCreation = () => {
      if (selectedSecondTierItem?.id === 'new') setOptions(DEFAULT_OPTIONS);
    };

    resetStateOnCreation();
  }, [selectedSecondTierItem]);

  return <QRCodePreview configuration={options} />;
};

export default QRStylePreview;
