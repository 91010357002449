import { WppButton } from '@wppopen/components-library-react';
import { ComponentProps } from 'react';

export interface IDSButton extends ComponentProps<typeof WppButton> {}

const DSButton = ({ children, ...wppProps }: IDSButton) => {
  return <WppButton {...wppProps}>{children}</WppButton>;
};

export default DSButton;
