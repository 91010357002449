import { ConflictException, ExceptionBase, UniquePropertyExistsException } from '@core/exceptions';
import { TabsChangeEventDetail } from '@wppopen/components-library';
import { WppIconArchive } from '@wppopen/components-library-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderActions, Tabs } from '../../../shared/components';
import { IHeaderActionsItem } from '../../../shared/components/HeaderActions/HeaderActions';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';
import { translate } from '../../../shared/locales/i18n';
import { MenuItemEnum, StatusEnum } from '../../../shared/types';
import { ChannelTable } from '../../Channel';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { tabs } from '../../Home/utils/homeUtils';
import {
  useSecondTierItemIdToSelectOnReloadState,
  useSecondTierReloadState,
  useSecondTierSelectedItemState,
} from '../../SecondTierSidebar/hooks';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types/SecondTierSidebarItem';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { useBrandValueChangesState } from '../hooks';
import { useBrandSendData } from '../hooks/useBrandSendData';
import { BrandPageTabs } from '../types/BrandPageTabsEnum';
import { verifyWebSiteLink } from '../utils/brandPageUtils';
import {
  ContainerBare,
  ContainerOs,
  HeaderActionsContainer,
  HeaderContainer,
  MainContentTableContainer,
  TabsContainer,
} from './styles/BrandPage.styled';
import TabDetails from './TabDetails';

export interface IBrandPageProps {}

const BrandPage: React.FC<IBrandPageProps> = () => {
  const navigate = useNavigate();
  const { setSecondTierReload } = useSecondTierReloadState();
  const { selectedSecondTierItem, setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { brandValueChanges, setBrandValueChanges } = useBrandValueChangesState();
  const [selectedTab, setSelectedTab] = useState(BrandPageTabs.DETAILS);
  const [newBrandTitle, setNewBrandTitle] = useState(selectedSecondTierItem?.name || '');
  const [brandUrl, setBrandUrl] = useState<string | undefined>(selectedSecondTierItem?.brandUrl);
  const [hasErrorMessage, setHasErrorMessage] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  const isCreateMode = selectedSecondTierItem ? selectedSecondTierItem.status === StatusEnum.UNPUBLISHED : false;

  const onErrorCallback = (error: ExceptionBase) => {
    if (error instanceof ConflictException) setHasErrorMessage(true);
    const errorMessage =
      error instanceof UniquePropertyExistsException ? translate('txtTitleUsed') : translate('txtErrorMessage');
    setToastMessage(errorMessage);
  };

  const onSuccessCallback = (returnedId?: string | void) => {
    if (selectedOrganisation && selectedSecondTierItem) {
      if (isCreateMode) {
        setHasErrorMessage(false);
        setBrandValueChanges(false);
        setSecondTierReload(true);
        setItemIdToSelectOnReload(returnedId ?? null);
        setSelectedSecondTierItem({
          name: newBrandTitle,
          id: returnedId ?? '',
          status: StatusEnum.ACTIVE,
        });
        navigate(`/organisation/${selectedOrganisation.id}/brands/${returnedId}`);
        setToastMessage(translate('txtSuccessCreated', { something: translate('txtBrand') }));
      } else {
        setHasErrorMessage(false);
        setBrandValueChanges(false);
        setSecondTierReload(true);
        setItemIdToSelectOnReload(selectedSecondTierItem.id);
        navigate(`/organisation/${selectedOrganisation.id}/brands/${selectedSecondTierItem.id}`);
        setToastMessage(translate('txtChangesSaved'));
      }
    }
  };
  const { isError, isSuccess, isLoading, createBrand, updateBrand } = useBrandSendData(
    onSuccessCallback,
    onSuccessCallback,
    onErrorCallback,
  );

  useEffect(() => {
    setHasErrorMessage(false);
    if (!isCreateMode) {
      setNewBrandTitle(selectedSecondTierItem?.name ?? '');
      setSelectedTab(BrandPageTabs.DETAILS);
      setBrandUrl(selectedSecondTierItem?.brandUrl);
    } else {
      setNewBrandTitle('');
      setBrandUrl(undefined);
    }
  }, [selectedSecondTierItem, isCreateMode]);

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setSelectedTab(event.detail.value === BrandPageTabs.CHANNEL ? BrandPageTabs.CHANNEL : BrandPageTabs.DETAILS);
  };

  const archiveAction: IHeaderActionsItem = {
    title: translate('txtArchive'),
    type: 'secondary',
    icon: <WppIconArchive slot="icon-start" />,
    action: () => {
      alert('Archive Clicked');
    },
  };

  const saveAction: IHeaderActionsItem = {
    title: translate('txtSave'),
    type: 'primary',
    disabled: !brandValueChanges,
    loading: isLoading,
    action: () => {
      const params = {
        organisationId: selectedOrganisation?.id ?? '',
        newBrandTitle,
        brandUrl: brandUrl ? verifyWebSiteLink(brandUrl) : undefined,
      };

      if (isCreateMode) {
        createBrand(params);
      } else {
        updateBrand({
          ...params,
          secondTierItem: selectedSecondTierItem!,
        });
      }
    },
  };

  const getTagColor = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? 'positive' : 'neutral';
  };

  const getHeaderActions = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? [archiveAction, saveAction] : [saveAction];
  };

  const renderDetailsContent = () => {
    return (
      <TabDetails
        newBrandTitle={newBrandTitle}
        setNewBrandTitle={setNewBrandTitle}
        brandUrl={brandUrl}
        setBrandUrl={setBrandUrl}
        hasErrorMessage={hasErrorMessage}
        setHasErrorMessage={setHasErrorMessage}
      />
    );
  };

  const renderChannelsContent = () => {
    return (
      <MainContentTableContainer>
        <ChannelTable location={MenuItemEnum.BRANDS} data-testid={'channel-table-brand'} />
      </MainContentTableContainer>
    );
  };

  const renderContent = () => {
    if (selectedTab === BrandPageTabs.CHANNEL) {
      return renderChannelsContent();
    }
    return renderDetailsContent();
  };

  if (!selectedSecondTierItem) {
    return null;
  }

  return (
    <PlatformWrapper componentBare={ContainerBare} componentOs={ContainerOs}>
      <FallbackWrapper
        success={isSuccess}
        error={isError}
        successToastMessage={toastMessage}
        errorToastMessage={toastMessage}
      />
      <HeaderContainer>
        <Header
          title={selectedSecondTierItem?.name || ''}
          tag={selectedSecondTierItem.status}
          tagColor={getTagColor()}
        />
      </HeaderContainer>
      <HeaderActionsContainer>
        <HeaderActions actions={getHeaderActions()} />
      </HeaderActionsContainer>
      <TabsContainer>
        <Tabs tabs={tabs} handleTabChange={handleTabChange} selectedTab={selectedTab} />
      </TabsContainer>
      {renderContent()}
    </PlatformWrapper>
  );
};

export default BrandPage;
