import { BoxContent } from '@connected-core-system/utils/frontend';
import DSSpinner from '../../../shared/DSComponents/DSSpinner/DSSpinner';

interface ILoadingSpinner {}

 const LoadingSpinner: React.FC<ILoadingSpinner> = () => {
  return (
    <BoxContent data-testid="loading-state">
      <DSSpinner size="m" />
    </BoxContent>
  );
};

export default LoadingSpinner;
