import { WppTypography } from '@wppopen/components-library-react';
import { ComponentProps } from 'react';

export interface IDSTypography extends ComponentProps<typeof WppTypography> {}

const DSTypography = ({ children, ...wppProps }: IDSTypography) => {
  return <WppTypography {...wppProps}>{children}</WppTypography>;
};

export default DSTypography;
