import React, { useState } from 'react';
import { useEffect } from 'react';
import { UniquePropertyExistsException } from '@core/exceptions';
import { BoxContent } from '@connected-core-system/utils/frontend';
import { QRCodeTemplateDTO } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { Header, HeaderActions, PlatformWrapper } from '../../../shared/components';
import { IHeaderActionsItem } from '../../../shared/components/HeaderActions/HeaderActions';
import { translate } from '../../../shared/locales';
import { MenuItemEnum, StatusEnum } from '../../../shared/types';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import {
  useSecondTierItemIdToSelectOnReloadState,
  useSecondTierReloadState,
  useSecondTierSelectedItemState,
} from '../../SecondTierSidebar/hooks';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useQRPageOptions, useQRStyleSendData, useQRStyleState } from '../hooks';
import { CallbackType } from '../types';
import CornersDotsStyle from './CornersDotsStyle';
import BackgroundStyle from './BackgroundStyle';
import CornersSquareStyle from './CornersSquareStyle';
import DotsStyle from './DotsStyle';
import ImageStyle from './ImageStyle';
import LoadingSpinner from './LoadingSpinner';
import { QRChannelCount } from './QRChannelCount';
import QRTitle from './QRTitle';
import QROptionsStyle from './QROptionsStyle';
import QRStylePreview from './QRStylePreview';
import {
  ContainerBare,
  ContainerOs,
  HeaderContainer,
  HeaderActionsContainer,
  ContentContainer,
  ChannelsContainerBare,
  ChannelsContainerOs,
  PreviewContainerBare,
  PreviewContainerOs,
  PreviewHeader,
} from './styles/QRStylePage.styled';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import DSIconError from '../../../shared/DSComponents/DSIconError/DSIconError';

interface ContentProps {}

const Content: React.FC<ContentProps> = () => {
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();
  const { qrCodeOptions, title, valueChanges, setValueChanges, syncQRStylesOptions } = useQRStyleState();
  const { setSecondTierReload } = useSecondTierReloadState();
  const {
    qrPageOptions,
    setQRPageOptions,
    hasDotStyleError,
    setHasDotStyleError,
    hasCornerSquareError,
    setHasCornerSquareError,
    hasCornerDotError,
    setHasCornerDotError,
  } = useQRPageOptions();
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const hasError = hasDotStyleError || hasCornerSquareError || hasCornerDotError;
    const valueChanged = valueChanges?.newTitle || valueChanges?.newValue;
    const isDisable = hasError || !title || !valueChanged;
    setQRPageOptions((prev) => ({
      ...prev,
      disableSaveButton: isDisable,
      hasError: hasError,
    }));
  }, [
    hasCornerDotError,
    hasCornerSquareError,
    hasDotStyleError,
    setQRPageOptions,
    title,
    valueChanges?.newTitle,
    valueChanges?.newValue,
  ]);

  const onSuccessCallback = (
    type: CallbackType,
    response: void | QRCodeTemplateDTO | UniquePropertyExistsException,
  ) => {
    const titleAlreadyExists = response && 'message' in response;

    if (titleAlreadyExists) {
      syncQRStylesOptions('new');
      setToastMessage(translate('txtQRStyleNameUsed'));
    } else {
      const isCreate = type === 'create';
      const successMessage = isCreate ? 'txtQRStyleCreationSuccess' : 'txtQRStyleUpdateSuccess';
      setToastMessage(translate(successMessage));
      setSecondTierReload(true);
      setQRPageOptions((prev) => ({
        ...prev,
        disableSaveButton: true,
      }));
      setSelectedSideMenuItem(MenuItemEnum.QRSTYLES);
      setValueChanges({ newTitle: false, newValue: false });

      if (isCreate) {
        setItemIdToSelectOnReload(selectedSecondTierItem?.id ?? null);
      } else {
        const templateId = selectedSecondTierItem?.id ? selectedSecondTierItem.id : '';
        setItemIdToSelectOnReload(templateId);
      }
    }
  };

  const onErrorCallback = () => {
    setToastMessage(translate('txtSomethingWentWrongTryAgain'));
  };

  const { createQRStyle, updateQRStyle, isError, isLoading, isSuccess } = useQRStyleSendData(
    onSuccessCallback,
    onErrorCallback,
  );

  const save: IHeaderActionsItem = {
    title: translate('txtSave'),
    type: 'primary',
    disabled: qrPageOptions.disableSaveButton,
    loading: isLoading,
    action: () => {
      const isCreateSave = selectedSecondTierItem?.status === StatusEnum.UNPUBLISHED && selectedOrganisation?.id;
      if (isCreateSave) {
        const qrStyle = {
          name: title,
          configuration: qrCodeOptions || {},
          status: StatusEnum.ACTIVE,
        };
        createQRStyle({
          organisationId: selectedOrganisation.id,
          qrStyle: qrStyle,
        });
      } else {
        if (selectedSecondTierItem?.id && selectedOrganisation) {
          const templateId = selectedSecondTierItem?.id ? selectedSecondTierItem.id : '';
          const qrStyle = {
            name: title,
            configuration: qrCodeOptions || {},
            status: StatusEnum.ACTIVE,
            templateId: templateId,
          };
          updateQRStyle({ organisationId: selectedOrganisation.id, templateId, qrStyle });
        }
      }
    },
  };

  const getTagColor = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? 'positive' : 'neutral';
  };

  const renderSave = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    return <HeaderActions actions={[save]} />;
  };

  const renderError = () => {
    if (!isError) {
      return null;
    }

    return <DSIconError />;
  };

  if (!selectedSecondTierItem) {
    return null;
  }

  return (
    <PlatformWrapper componentBare={ContainerBare} componentOs={ContainerOs} data-testid="qrstyle-page">
      <FallbackWrapper
        error={isError}
        success={isSuccess}
        errorToastMessage={toastMessage}
        successToastMessage={toastMessage}
      />
      <HeaderContainer>
        <Header
          title={title || translate('txtDefaultQRStyleTitle')}
          tag={selectedSecondTierItem.status}
          tagColor={getTagColor()}
        />
      </HeaderContainer>
      <HeaderActionsContainer>{renderSave()}</HeaderActionsContainer>

      <ContentContainer data-testid="content-container">
        <BoxContent direction="column">
          <QRTitle />
          <DotsStyle setHasError={setHasDotStyleError} />
          <CornersSquareStyle setHasError={setHasCornerSquareError} />
          <CornersDotsStyle setHasError={setHasCornerDotError} />
          <BackgroundStyle />
          <ImageStyle />
          <QROptionsStyle />
        </BoxContent>
        <BoxContent>
          <PlatformWrapper
            componentBare={ChannelsContainerBare}
            componentOs={ChannelsContainerOs}
            flex
            align="center"
            justify="space-between">
            <DSTypography type="xs-body">{translate('txtChannelsLinked')}</DSTypography>
            <QRChannelCount setMessage={setToastMessage} />
          </PlatformWrapper>
          <PlatformWrapper componentBare={PreviewContainerBare} componentOs={PreviewContainerOs}>
            <PreviewHeader>
              <DSTypography type="m-strong">{translate('txtPreview')}</DSTypography>
              {renderError()}
            </PreviewHeader>
            <QRStylePreview />
          </PlatformWrapper>
        </BoxContent>
      </ContentContainer>
    </PlatformWrapper>
  );
};

export default Content;
