import { WppInput } from '@wppopen/components-library-react';
import { ChangeEvent, MouseEvent, FocusEvent, ComponentProps } from 'react';

export const DSInputMock = ({
  onWppChange,
  onWppBlur,
  onWppClick,
  ...props
}: {
  onWppChange?: (event: { detail: { value: string } }) => void;
  onWppBlur?: (event: { detail: { value: string } }) => void;
  onWppClick?: (event: { detail: { value: string } }) => void;
  'data-testid': string;
} & ComponentProps<typeof WppInput>) => (
  <div>
    <input
      data-testid={props?.['data-testid'] ?? 'mock-input'}
      value={props.value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onWppChange?.({ detail: { value: event.target.value } })}
      onClick={(event: MouseEvent<HTMLInputElement>) =>
        onWppClick?.({ detail: { value: (event.target as HTMLInputElement).value } })
      }
      onBlur={(event: FocusEvent<HTMLInputElement>) => onWppBlur?.({ detail: { value: event.target.value } })}
    />
    {props.message && <span>{props.message}</span>}
  </div>
);
