import styled from '@emotion/styled';
import { WppFileUpload } from '@wppopen/components-library-react';
import { DSInput } from '../../../../shared/DSComponents';
import DSRadioGroup from '../../../../shared/DSComponents/DSRadioGroup/DSRadioGroup';
import DSTypography from '../../../../shared/DSComponents/DSTypography/DSTypography';
import { SelectDropDownBottom } from '../../../../shared/components';

export const GridContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1.5fr 1.5fr 0fr',
  gridTemplateRows: 'repeat(auto-fit, 0.5fr 1fr)',
  columnGap: '2rem',
  rowGap: '0.8rem',
  marginBottom: '1.2rem',
  width: '100%',
});

export const CheckboxContainer = styled.div({
  marginBottom: '1.2rem',
});

export const LastGridContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: 'repeat(auto-fit, 0.5fr 1fr)',
  columnGap: '2.4rem',
  rowGap: '0.8rem',
});

export const RadioContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '1.6rem',
  alignItems: 'center',
});

export const StyledRadioGroup = styled(DSRadioGroup)(({ hasStyleField = true }: { hasStyleField?: boolean }) => ({
  gridColumnStart: hasStyleField ? 2 : 1,
  gridColumnEnd: 4,
}));

export const StyledInput = styled(DSInput)(() => ({
  minWidth: '12rem',
}));

export const StyledQROptionsLabel = styled(DSTypography)(() => ({
  color: '#4D5358',
  fontSize: '0.8rem',
}));

export const StyledFileUpload = styled(WppFileUpload)({
  flexGrow: 1,
  width: '100%',
  maxWidth: '100%',

  '&::part(file-list)': {
    width: '100%',
  },

  '&::part(file-item)': {
    maxWidth: 'unset',
  },
});

export const StyledSelectDropDown = styled(SelectDropDownBottom)({
  minWidth: '12rem',
  position: 'relative',
  '&::part(wrapper)': {
    display: 'inline-block',
    minWidth: '12rem',
    width: '100%',
  },
});
