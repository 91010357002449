// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState FILE

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useCornersDotsOptionsState = () => {
  const cornersDotOptions = useSliceState<IQRStyleState, 'cornersDotOptions'>('cornersDotOptions');
  const setCornersDotOptions = useSliceSetter<IQRStyleActions, 'setCornersDotOptions'>('setCornersDotOptions');

  return { cornersDotOptions, setCornersDotOptions };
};
