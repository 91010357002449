import React, { useCallback, useEffect, useState } from 'react';
import { BoxContent } from '../../../shared/components';
import { WppTypography, WppRadio, WppIconError } from '@wppopen/components-library-react';
import ExpandableCard from '../../../shared/components/ExpandableCard/ExpandableCard';
import {
  LastGridContainer,
  RadioContainer,
  GridContainer,
  StyledInput,
  StyledRadioGroup,
  StyledSelectDropDown,
} from '../styles/ExpandablesStyle.styled';
import { QRColorType, QRGradientType, QRDotsOptions } from '../types/QRStyleTypes';
import { translate } from '../../../shared/locales';
import ColorField from '../../../shared/components/SelectColor/ColorField';
import { useDotsOptionsState } from '../hooks/useDotsOptionsState';
import { DotType, Options } from 'qr-code-styling';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { WppRadioGroupCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { RadioGroupChangeEvent, SelectChangeEventDetail } from '@wppopen/components-library';
import { useValueChangesState } from '../hooks/useValueChangesState';
import { DEFAULT_COLOR, QRSTYLE_FORM } from '../../../shared/Constants/Constants';
import { useBackgroundOptionsState } from '../hooks/useBackgroundOptionsState';
import { getColorErrorMessage } from '../utils/QrStyleUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDotsStyleProps {
  setHasError: (hasError: boolean) => void;
}

const DOTS_STYLE_OPTIONS: Array<{ value: DotType; label: string }> = [
  { label: 'Rounded', value: 'rounded' },
  { label: 'Dots', value: 'dots' },
  { label: 'Classy', value: 'classy' },
  { label: 'Classy rounded', value: 'classy-rounded' },
  { label: 'Square', value: 'square' },
  { label: 'Extra rounded', value: 'extra-rounded' },
];

const DEFAULT_OPTIONS = {
  DOTS_TYPE: 'square' as DotType,
  COLOR_TYPE: 'single' as QRColorType,
  ROTATION: '0',
  GRADIENT_TYPE: 'linear' as QRGradientType,
  COLOR: [
    {
      color: DEFAULT_COLOR,
      offset: 0,
    },
    {
      color: DEFAULT_COLOR,
      offset: 1,
    },
  ],
};

const DotsStyle: React.FC<IDotsStyleProps> = ({ setHasError }) => {
  const [dotsType, setDotsType] = useState<DotType>(DEFAULT_OPTIONS.DOTS_TYPE);
  const [colorType, setColorType] = useState<QRColorType>(DEFAULT_OPTIONS.COLOR_TYPE);
  const [rotation, setRotation] = useState<string>(DEFAULT_OPTIONS.ROTATION);
  const [gradientType, setGradientType] = useState<QRGradientType>(DEFAULT_OPTIONS.GRADIENT_TYPE);
  const [color, setColor] = useState(DEFAULT_OPTIONS.COLOR);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const { backgroundOptions } = useBackgroundOptionsState();
  const { setDotsOptions } = useDotsOptionsState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const { valueChanges, setValueChanges } = useValueChangesState();

  const isGradientTypeSelected = colorType === 'gradient';

  useEffect(() => {
    setDotsOptions({
      color: color[0].color,
      type: dotsType,
      gradient: isGradientTypeSelected
        ? {
            rotation: Number(rotation),
            type: gradientType,
            colorStops: color,
          }
        : undefined,
    });
  }, [color, dotsType, colorType, rotation, gradientType, isGradientTypeSelected, setDotsOptions]);

  useEffect(() => {
    setErrorMessage(getColorErrorMessage(color, backgroundOptions, isGradientTypeSelected));
  }, [backgroundOptions, color, isGradientTypeSelected]);

  useEffect(() => {
    if (errorMessage[0] || errorMessage[1]) {
      setHasError(true);
    } else setHasError(false);
  }, [errorMessage, setHasError]);

  const fillStyleFields = useCallback(() => {
    if ((selectedSecondTierItem as any)?.configuration) {
      const options: Options = (selectedSecondTierItem as any)?.configuration;
      const dotsOptions: QRDotsOptions = options.dotsOptions;

      const setDotsOptionsData = () => {
        if (dotsOptions?.type) setDotsType(dotsOptions?.type);

        if (dotsOptions?.gradient) {
          setColorType('gradient');
          setGradientType(dotsOptions.gradient.type);
          setColor(dotsOptions.gradient.colorStops);
          if (dotsOptions.gradient.rotation) setRotation(dotsOptions.gradient.rotation?.toString());
        } else {
          setColorType('single');
          if (dotsOptions?.color) setColor([{ color: dotsOptions.color, offset: 0 }]);
        }
      };

      setDotsOptionsData();
    } else {
      const setDefaultOptions = () => {
        setDotsType(DEFAULT_OPTIONS.DOTS_TYPE);
        setColorType(DEFAULT_OPTIONS.COLOR_TYPE);
        setRotation(DEFAULT_OPTIONS.ROTATION);
        setGradientType(DEFAULT_OPTIONS.GRADIENT_TYPE);
        setColor(DEFAULT_OPTIONS.COLOR);
      };

      setDefaultOptions();
    }
  }, [selectedSecondTierItem]);

  useEffect(() => {
    fillStyleFields();
  }, [fillStyleFields, selectedSecondTierItem]);

  const handleChange = (
    field: string,
    e: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.dotsType:
        setDotsType(e.detail.value as DotType);
        break;
      case QRSTYLE_FORM.colorType:
        setColorType(e.target.value as QRColorType);
        break;
      case QRSTYLE_FORM.rotation:
        setRotation(e.detail.value as string);
        break;
      case QRSTYLE_FORM.gradient:
        setGradientType(e.target.value as QRGradientType);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  return (
    <ExpandableCard title={translate('txtDotsTitle')}>
      {(errorMessage[0] || errorMessage[1]) && <WppIconError slot="actions" />}
      <BoxContent direction="column" data-testid="dots-style-container">
        <GridContainer>
          <WppTypography type="s-strong">{translate('txtDotsStyleLabel')}</WppTypography>

          <WppTypography type="s-strong">{translate('txtColorTypeLabel')}</WppTypography>
          <div>&nbsp;</div>

          <StyledSelectDropDown
            options={DOTS_STYLE_OPTIONS}
            value={dotsType}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.dotsType, e)}
          />
          <StyledRadioGroup
            value={colorType}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.colorType, e)}
            data-testid="color-type-radio-group">
            <RadioContainer>
              <WppRadio
                name="single-color"
                value="single"
                labelConfig={{ text: translate('txtSingleColor') }}
                required
                checked={colorType === 'single'}
              />
              <WppRadio
                name="gradient-color"
                value="gradient"
                labelConfig={{ text: translate('txtGradientColor') }}
                required
                checked={isGradientTypeSelected}
              />
            </RadioContainer>
          </StyledRadioGroup>
        </GridContainer>
        {isGradientTypeSelected && (
          <GridContainer>
            <WppTypography type="s-strong">Rotation</WppTypography>
            <WppTypography type="s-strong">Gradient type</WppTypography>
            <div>&nbsp;</div>

            <StyledInput
              type="number"
              value={rotation as string}
              onWppChange={(e) => handleChange(QRSTYLE_FORM.rotation, e)}
              data-testid="rotation-input"
            />

            <StyledRadioGroup
              value={gradientType}
              onWppChange={(e) => handleChange(QRSTYLE_FORM.gradient, e)}
              data-testid="gradient-type-radio-group">
              <RadioContainer>
                <WppRadio
                  name="linear"
                  value="linear"
                  labelConfig={{ text: 'Linear' }}
                  required
                  checked={gradientType === 'linear'}
                  data-testid="linear-radio"
                />
                <WppRadio
                  name="radial"
                  value="radial"
                  labelConfig={{ text: 'Radial' }}
                  required
                  checked={gradientType === 'radial'}
                  data-testid="radial-radio"
                />
              </RadioContainer>
            </StyledRadioGroup>
          </GridContainer>
        )}

        <LastGridContainer>
          <WppTypography type="s-strong">{translate('txtColor')}</WppTypography>
          <div>&nbsp;</div>
          <div>&nbsp;</div>

          <ColorField
            color={color}
            setColor={setColor}
            disabled={false}
            isGradient={isGradientTypeSelected}
            errorMessage={errorMessage}
          />
        </LastGridContainer>
      </BoxContent>
    </ExpandableCard>
  );
};

// TODO 4231 - SEE COMMENTS FOR SIMILAR ERRORS IN CornersDotsStyle AND BackgroundStyle
export default DotsStyle;
