import { WppInput } from '@wppopen/components-library-react';
import { ChangeEvent, MouseEvent, FocusEvent, ComponentProps } from 'react';

export const DSInputMock = ({
  onWppChange,
  onWppBlur,
  onClick,
  ...props
}: {
  onWppChange?: (event: { detail: { value: string } }) => void;
  onWppBlur?: (event: { detail: { value: string } }) => void;
  onClick?: (event: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => void;
  'data-testid': string;
} & ComponentProps<typeof WppInput>) => (
  <div>
    <input
      data-testid={props?.['data-testid'] ?? 'mock-input'}
      value={props.value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onWppChange?.({ detail: { value: event.target.value } })}
      onClick={(e) => onClick?.(e)}
      onBlur={(event: FocusEvent<HTMLInputElement>) => onWppBlur?.({ detail: { value: event.target.value } })}
      disabled={props.disabled}
    />
    {props.message && <span>{props.message}</span>}
  </div>
);
