import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MenuItemEnum } from '../../../shared/types';
import { secondTierSidebarManager } from '../services';
import { SecondTierItemQueryEnum } from '../types/SecondTierItemQueryEnum';

export const useSecondTierData = (selectedOrganisationId: string, selectedSideMenuItem: MenuItemEnum | null) => {
  const {
    data: dataUnit,
    isPending: isPendingUnit,
    isFetching: isFetchingUnit,
    isError: isErrorUnit,
    refetch: refetchUnit,
  } = useQuery({
    queryKey: [SecondTierItemQueryEnum.UNIT_QUERY, selectedOrganisationId],
    queryFn: () => secondTierSidebarManager.getUnitList(selectedOrganisationId),
    enabled: !!selectedOrganisationId && selectedSideMenuItem === MenuItemEnum.UNIT,
  });

  const {
    data: dataBrand,
    isPending: isPendingBrand,
    isFetching: isFetchingBrand,
    isError: isErrorBrand,
    refetch: refetchBrand,
  } = useQuery({
    queryKey: [SecondTierItemQueryEnum.BRAND_QUERY, selectedOrganisationId],
    queryFn: () => secondTierSidebarManager.getBrandList(selectedOrganisationId),
    enabled: !!selectedOrganisationId && selectedSideMenuItem === MenuItemEnum.BRANDS,
  });

  const {
    data: dataQrCode,
    isPending: isPendingQrCode,
    isFetching: isFetchingQrCode,
    isError: isErrorQrCode,
    refetch: refetchQrCode,
  } = useQuery({
    queryKey: [SecondTierItemQueryEnum.QRSTYLE_QUERY, selectedOrganisationId],
    queryFn: () => secondTierSidebarManager.getQRStyleList(selectedOrganisationId),
    enabled: !!selectedOrganisationId && selectedSideMenuItem === MenuItemEnum.UNIT,
  });

  const getData = (selectedSideMenuItem: MenuItemEnum) => {
    switch (selectedSideMenuItem) {
      case MenuItemEnum.UNIT:
        return dataUnit?.data || [];
      case MenuItemEnum.BRANDS:
        return dataBrand?.data || [];
      case MenuItemEnum.QRSTYLES:
        return dataQrCode?.data || [];
      default:
        return [];
    }
  };

  const getLoadingStatus = (selectedSideMenuItem: MenuItemEnum) => {
    switch (selectedSideMenuItem) {
      case MenuItemEnum.UNIT:
        return isFetchingUnit || isPendingUnit;
      case MenuItemEnum.BRANDS:
        return isFetchingBrand || isPendingBrand;
      case MenuItemEnum.QRSTYLES:
        return isFetchingQrCode || isPendingQrCode;
      default:
        return false;
    }
  };

  const getErrorStatus = (selectedSideMenuItem: MenuItemEnum) => {
    switch (selectedSideMenuItem) {
      case MenuItemEnum.UNIT:
        return isErrorUnit;
      case MenuItemEnum.BRANDS:
        return isErrorBrand;
      case MenuItemEnum.QRSTYLES:
        return isErrorQrCode;
      default:
        return false;
    }
  };

  const refetchData = useCallback(
    (selectedSideMenuItem: MenuItemEnum) => {
      switch (selectedSideMenuItem) {
        case MenuItemEnum.UNIT:
          return refetchUnit();
        case MenuItemEnum.BRANDS:
          return refetchBrand();
        case MenuItemEnum.QRSTYLES:
          return refetchQrCode();
        default:
          return;
      }
    },
    [refetchBrand, refetchQrCode, refetchUnit],
  );

  return {
    getData,
    getLoadingStatus,
    getErrorStatus,
    refetchData,
  };
};
