import { useEffect } from 'react';
import { useSecondTierSelectedItemState } from './useSecondTierSelectedItemState';
import { useSecondTierItemIdToSelectOnReloadState } from './useSecondTierItemIdToSelectOnReloadState';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';

export const useResetSecondTierSelection = () => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();

  useEffect(() => {
    if (selectedOrganisation && selectedSideMenuItem) {
      setSelectedSecondTierItem(null);
    }
  }, [selectedOrganisation, selectedSideMenuItem, setItemIdToSelectOnReload, setSelectedSecondTierItem]);
};
