import { useSliceState, useSliceSetter } from '../../../shared/hooks/useStore';
import {
  IOrganisationCreateState,
  IOrganisationCreateActions,
} from '../../OrganisationCreate/sliceStore/organisationCreateSliceStore';
import { ISideMenuState } from '../../SideMenu';

export const useModalControl = () => {
  const isModalOpened = useSliceState<IOrganisationCreateState, 'isModalOpened'>('isModalOpened');
  const isMenuOpened = useSliceState<ISideMenuState, 'isMenuOpened'>('isMenuOpened');
  const setOpenModalWithAction = useSliceSetter<IOrganisationCreateActions, 'setOpenModalWithAction'>(
    'setOpenModalWithAction',
  );
  return {
    isModalOpened,
    isMenuOpened,
    setOpenModalWithAction,
  };
};
