// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useImageState = () => {
  const image = useSliceState<IQRStyleState, 'image'>('image');
  const setImage = useSliceSetter<IQRStyleActions, 'setImage'>('setImage');

  return { image, setImage };
};
