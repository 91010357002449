import { useState } from 'react';
import { SecondTierSidebarContainerBare, SecondTierSidebarContainerOs } from './styles/SecondTierSidebar.styled';
import { StatusEnum } from '../../../shared/types';
import { translate } from '../../../shared/locales';
import { SecondTierSidebarItem } from '../types';
import { PlatformWrapper, ConfirmationModal, Title, Button, SearchField } from '../../../shared/components';
import { formatName } from '../utils';
import {
  useSecondTierSelectedItemState,
  useNavigateOnSelectionChange,
  useResetSecondTierSelection,
  useManageQRStyleState,
  useSecondTierReloadState,
  useHandleSecondTierReload,
  useSecondTierSidebar,
} from '../hooks';
import Tree from './Tree';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { getUpperMenuItems } from '../../SideMenu/utils/sideMenuUtils';
import { useUnitValueChangesState } from '../../UnitPage/hooks/useUnitValueChangesState';
import { useBrandValueChangesState } from '../../BrandPage/hooks';

export interface ISecondTierSidebarProps {}

const SecondTierSidebar: React.FC<ISecondTierSidebarProps> = () => {
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const { secondTierReload, setSecondTierReload } = useSecondTierReloadState();
  const { selectedSecondTierItem, setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();
  const { unitValueChanges, setUnitValueChanges } = useUnitValueChangesState();
  const { brandValueChanges, setBrandValueChanges } = useBrandValueChangesState();
  const { data, refetchData, isLoading, isError } = useSecondTierSidebar();
  const { shouldRenderData } = useHandleSecondTierReload({
    secondTierReload,
    setSecondTierReload,
    selectedSideMenuItem,
    refetchData,
    isLoading,
    isError,
  });

  useNavigateOnSelectionChange();
  useResetSecondTierSelection();
  useManageQRStyleState(data);

  const addNewItem = () => {
    if (selectedSideMenuItem) {
      const newItem: SecondTierSidebarItem = {
        id: 'new',
        name: translate('txtNewSomething', { something: formatName(selectedSideMenuItem, true) }),
        status: StatusEnum.UNPUBLISHED,
      };
      setSelectedSecondTierItem(newItem);
    }
  };

  const handleClickButtonCreateNew = () => {
    if (unitValueChanges || brandValueChanges) {
      setOpenConfirmationModal(true);
    } else {
      addNewItem();
      setOpenConfirmationModal(false);
    }
  };

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleCancelConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setUnitValueChanges(false);
    setBrandValueChanges(false);
    addNewItem();
  };

  const getSelectedMenuItemTitle = () => {
    const menuItems = getUpperMenuItems();
    const selectedItem = menuItems.find((item) => item.id === selectedSideMenuItem);
    return selectedItem ? selectedItem.value : '';
  };

  return (
    <>
      {selectedSideMenuItem && (
        <PlatformWrapper
          componentBare={SecondTierSidebarContainerBare}
          componentOs={SecondTierSidebarContainerOs}
          selectedSideMenuItem={selectedSideMenuItem}
          data-testid="platform-wrapper">
          <ConfirmationModal
            title={translate('txtConfirmationModalLeaveEditTitle')}
            text={translate('txtConfirmationModalLeaveEditText')}
            buttonCancelText={translate('txtButtonModalCancel')}
            buttonText={translate('txtButtonModal')}
            buttonAction={handleConfirmationModal}
            buttonCancelAction={handleCancelConfirmationModal}
            isOpen={openConfirmationModal}
            setIsOpen={setOpenConfirmationModal}
            isWarning={false}
          />
          <Title name={getSelectedMenuItemTitle()} />
          <SearchField value={searchFieldValue} setValue={setSearchFieldValue} />
          <Tree
            searchFieldValue={searchFieldValue}
            itemList={shouldRenderData ? data : []}
            loading={isLoading}
            error={isError}
          />
          <Button
            name={getSelectedMenuItemTitle()}
            onClick={handleClickButtonCreateNew}
            disabled={isLoading || selectedSecondTierItem?.id === 'new'}
          />
        </PlatformWrapper>
      )}
    </>
  );
};

export default SecondTierSidebar;
