import { CornerDotType, CornerSquareType, DotType, Gradient } from 'qr-code-styling';
import { translate } from '../../../shared/locales';
import { QRBackgroundOptions, QRDefaultType } from '../types/QRStyleTypes';
import { STYLE_DEFAULT_OPTIONS } from '../utils/constants';

export const getColorErrorMessage = (
  color: { color: string; offset: number }[],
  backgroundOptions: QRBackgroundOptions | undefined,
  isGradientTypeSelected: boolean,
) => {
  const errorMessage = ['', ''];

  if (backgroundOptions?.gradient) {
    if (
      color[0]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[0]?.color.toLowerCase() ||
      color[0]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[1]?.color.toLowerCase()
    ) {
      errorMessage[0] = translate('txtColorErrorMessage');
    } else {
      errorMessage[0] = '';
    }
    if (
      isGradientTypeSelected &&
      (color[1]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[0]?.color?.toLowerCase() ||
        color[1]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[2]?.color?.toLowerCase())
    ) {
      errorMessage[1] = translate('txtColorErrorMessage');
    } else {
      errorMessage[1] = '';
    }
  } else {
    if (color[0]?.color?.toLowerCase() === backgroundOptions?.color?.toLowerCase()) {
      errorMessage[0] = translate('txtColorErrorMessage');
    } else {
      errorMessage[0] = '';
    }
    if (isGradientTypeSelected && color[1]?.color?.toLowerCase() === backgroundOptions?.color?.toLowerCase()) {
      errorMessage[1] = translate('txtColorErrorMessage');
    } else {
      errorMessage[1] = '';
    }
  }

  return errorMessage;
};

export const getGradientValues = (type: QRDefaultType, gradient: Gradient | undefined, color: string | undefined) => {
  const defaultOptions = STYLE_DEFAULT_OPTIONS[type];

  return {
    colorStops: gradient?.colorStops ?? [
      { color: color ?? defaultOptions.COLOR[0].color, offset: 0 },
      defaultOptions.COLOR[1],
    ],
    type: gradient?.type ?? defaultOptions.GRADIENT_TYPE,
    rotation: gradient?.rotation ?? Number(defaultOptions.ROTATION),
  };
};
