import {
  CornerDotType,
  CornerSquareType,
  DotType,
  DrawType,
  ErrorCorrectionLevel,
  GradientType,
  Mode,
  Options,
  TypeNumber,
} from 'qr-code-styling';
import { QRColorType, QRGradientType } from '../types/QRStyleTypes';

export const QRSTYLE_FORM = {
  colorType: 'colorType',
  rotation: 'rotation',
  gradient: 'gradient',
  dotsType: 'dotsType',
  cornersDotsType: 'cornersDotsType',
  cornerSquareType: 'cornerSquareStyle',
  imageSize: 'imageSize',
  margin: 'margin',
  hideBackgroundDots: 'hideBackgroundDots',
};

export const DEFAULT_COLOR = '#231A1A';
export const DEFAULT_COLOR_BACKGROUND = '#ffffff';

export const BACKGROUND_STYLE_DEFAULT_OPTIONS = {
  COLOR_TYPE: 'single' as QRColorType,
  ROTATION: '0',
  GRADIENT_TYPE: 'linear' as GradientType,
  COLOR: [
    {
      color: DEFAULT_COLOR_BACKGROUND,
      offset: 0,
    },
    {
      color: DEFAULT_COLOR,
      offset: 1,
    },
  ],
};

export const CORNERS_STYLE_DEFAULT_OPTIONS = {
  CORNERS_TYPE: 'square' as CornerDotType,
  COLOR_TYPE: 'single' as QRColorType,
  ROTATION: '0',
  GRADIENT_TYPE: 'linear' as QRGradientType,
  COLOR: [
    {
      color: DEFAULT_COLOR,
      offset: 0,
    },
    {
      color: DEFAULT_COLOR,
      offset: 1,
    },
  ],
};

export const DOTS_STYLE_DEFAULT_OPTIONS = {
  DOTS_TYPE: 'square' as DotType,
  COLOR_TYPE: 'single' as QRColorType,
  ROTATION: '0',
  GRADIENT_TYPE: 'linear' as QRGradientType,
  COLOR: [
    {
      color: DEFAULT_COLOR,
      offset: 0,
    },
    {
      color: DEFAULT_COLOR,
      offset: 1,
    },
  ],
};

export const IMAGE_STYLE_DEFAULT_OPTIONS = {
  IMAGE_SIZE: '0.4',
  MARGIN: '0',
  HIDE_BACKGROUND_DOTS: true,
};

export const STYLE_DEFAULT_OPTIONS = {
  Background: BACKGROUND_STYLE_DEFAULT_OPTIONS,
  Corners: CORNERS_STYLE_DEFAULT_OPTIONS,
  Dots: DOTS_STYLE_DEFAULT_OPTIONS,
};

export const PREVIEW_STYLE_DEFAULT_OPTIONS: Options = {
  width: 300,
  height: 300,
  type: 'svg' as DrawType,
  data: 'Https://url.url.com/channel/1e0f2b22-2beb-4c64-9e08',
  margin: 10,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel,
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 0,
    crossOrigin: 'anonymous',
  },
  backgroundOptions: {
    color: '#FFF',
    gradient: undefined,
    round: undefined,
  },
  dotsOptions: {
    color: '#000',
    type: 'square',
    gradient: undefined,
  },
  cornersDotOptions: {
    color: '#000',
    type: 'square',
    gradient: undefined,
  },
  cornersSquareOptions: {
    color: '#000',
    type: 'square',
    gradient: undefined,
  },
  image: '',
};

export const DEFAULT_ERROR_CORRECTION = 'Q' as ErrorCorrectionLevel;

export const CORNERS_DOTS_STYLE_OPTIONS: Array<{ value: CornerDotType | 'default'; label: string }> = [
  { label: 'Dot', value: 'dot' },
  { label: 'Square', value: 'square' },
  { label: 'None', value: 'default' },
];

export const CORNERS_SQUARE_STYLE_OPTIONS: Array<{ value: CornerSquareType | 'default'; label: string }> = [
  { label: 'Dot', value: 'dot' },
  { label: 'Square', value: 'square' },
  { label: 'Extra rounded', value: 'extra-rounded' },
  { label: 'None', value: 'default' },
];

export const DOTS_STYLE_OPTIONS: Array<{ value: DotType; label: string }> = [
  { label: 'Rounded', value: 'rounded' },
  { label: 'Dots', value: 'dots' },
  { label: 'Classy', value: 'classy' },
  { label: 'Classy rounded', value: 'classy-rounded' },
  { label: 'Square', value: 'square' },
  { label: 'Extra rounded', value: 'extra-rounded' },
];

export const QR_OPTIONS: Array<{ value: ErrorCorrectionLevel; label: string }> = [
  { value: 'L', label: 'Low' },
  { value: 'M', label: 'Medium' },
  { value: 'Q', label: 'Quartile' },
  { value: 'H', label: 'High' },
];
