// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useValueChangesState = () => {
  const valueChanges = useSliceState<IQRStyleState, 'valueChanges'>('valueChanges');
  const setValueChanges = useSliceSetter<IQRStyleActions, 'setValueChanges'>('setValueChanges');

  return { valueChanges, setValueChanges };
};
