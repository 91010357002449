import styled from '@emotion/styled';
import IconWarning from '../../DSComponents/DSIconWarning/DSIconWarning';
import Tab from '../../DSComponents/DSTab/DSTab';

export const TabContainer = styled.div(({ show = false }: { show: boolean }) => ({
  display: show ? 'initial' : 'none',
}));

export const StyledTab = styled(Tab)({
  '--tab-width': '100%',
});

export const StyledIconWarning = styled(IconWarning)({ marginRight: '9px' });
