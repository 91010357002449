import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { OrganisationUnitGetDTO } from '@connected-core-system/utils/organisation-unit-http-dto';
import { MenuItemEnum } from '../../../shared/types';
import { SecondTierSidebarItem } from '../types';
import repoInstance, { SecondTierSidebarRepository } from './SecondTierSidebarRepository';
import { qrStyleManager } from '../../QRStyle/services';
import { toSecondTierItem } from '../utils/mappers';

export class SecondTierSidebarManager {
  constructor(private repo: SecondTierSidebarRepository) {}

  async getUnitList(organisationId: string) {
    try {
      const res = await this.repo.getOrganisationUnitsList(organisationId);
      const units: SecondTierSidebarItem[] = res.data as Omit<OrganisationUnitGetDTO, 'organisationId'>[];

      return { menuItem: MenuItemEnum.UNIT, data: units };
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getBrandList(organisationId: string) {
    try {
      const res = await this.repo.getBrandList(organisationId);
      const brands: SecondTierSidebarItem[] = res.data as Omit<BrandGetDTO, 'organisationId'>[];

      return { menuItem: MenuItemEnum.BRANDS, data: brands };
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getQRStyleList(organisationId: string) {
    try {
      const res = await qrStyleManager.getQRStyleList(organisationId);
      return toSecondTierItem(res);
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new SecondTierSidebarManager(repoInstance);
