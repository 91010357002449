import { useEffect } from 'react';
import { MenuItemEnum } from '../../../shared/types/MenuItemEnum.enum';

interface IUseHandleSecondTierReloadProps {
  secondTierReload: boolean;
  setSecondTierReload: (value: boolean) => void;
  selectedSideMenuItem?: MenuItemEnum | null;
  refetchData: (selectedSideMenuItem: MenuItemEnum) => void;
  isLoading: boolean;
  isError: boolean;
}

export const useHandleSecondTierReload = ({
  secondTierReload,
  setSecondTierReload,
  selectedSideMenuItem,
  refetchData,
  isLoading,
  isError,
}: IUseHandleSecondTierReloadProps) => {
  const shouldRenderData = !!(selectedSideMenuItem && !isLoading && !isError);

  useEffect(() => {
    if (shouldRenderData && secondTierReload) {
      refetchData(selectedSideMenuItem);
      setSecondTierReload(false);
    }
  }, [secondTierReload, refetchData, setSecondTierReload, selectedSideMenuItem, shouldRenderData]);
  return { shouldRenderData };
};
