import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { EmptyState } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import DSIconSearch from '../../../shared/DSComponents/DSIconSearch/DSIconSearch';
import DSIconPlus from '../../../shared/DSComponents/DSIconPlus/DSIconPlus';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar/hooks';
import { SecondTierSidebarItem, StatusEnum } from '../../SecondTierSidebar/types';
import { formatName } from '../../SecondTierSidebar/utils';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { EmptyStateContainer } from './styles/QRStylePage.styled';

interface IEmptyContent {}

const EmptyContent: React.FC<IEmptyContent> = () => {
  const theme = useTheme() as ITheme;
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();
  const { setSelectedSecondTierItem } = useSecondTierSelectedItemState();

  const NEW_QRSTYLE_ID = 'new';

  const createQRStyle = () => {
    if (selectedSideMenuItem) {
      const newItem: SecondTierSidebarItem = {
        id: NEW_QRSTYLE_ID,
        name: translate('txtNewSomething', { something: formatName(selectedSideMenuItem, true) }),
        status: StatusEnum.UNPUBLISHED,
      };
      setSelectedSecondTierItem(newItem);
    }
  };

  return (
    <EmptyStateContainer data-testid="empty-state-container">
      <EmptyState
        title={translate('txtNoQRStylesCreatedTitle')}
        titleIcon={
          <DSIconSearch slot="icon-start" aria-label="Search icon" color={theme.palette['grey1000']} size="m" />
        }
        text={translate('txtNoQRStylesCreated')}
        buttonText={translate('txtCreateQRStyle')}
        buttonIcon={<DSIconPlus slot="icon-start" />}
        buttonAction={createQRStyle}
      />
    </EmptyStateContainer>
  );
};

export default EmptyContent;
