import { HttpService } from '@core/http-service';
import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { OrganisationUnitGetDTO } from '@connected-core-system/utils/organisation-unit-http-dto';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { interpolateWithValues } from '../../../shared/utils';
import { ISecondTierSidebarRepository } from '../types';

export class SecondTierSidebarRepository implements ISecondTierSidebarRepository {
  constructor(private readonly httpService: HttpService) {}

  async getOrganisationUnitsList(organisationId: string): Promise<any> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_UNITS_GET, organisationId);
    return await this.httpService.get<OrganisationUnitGetDTO[]>(url);
  }

  async getBrandList(organisationId: string): Promise<any> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_BRANDS_GET, organisationId);
    return await this.httpService.get<BrandGetDTO[]>(url);
  }
}

export default new SecondTierSidebarRepository(HttpResolver.getHttpService());
