import { useEffect } from 'react';
import { translate } from '../../../shared/locales';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar/hooks';
import { useLoadChannelsLinked } from '../hooks';
import { WppSpinner } from '@wppopen/components-library-react';

interface IQRChannelCount {
  setMessage: (message: string) => void;
}

export const QRChannelCount: React.FC<IQRChannelCount> = ({ setMessage }) => {
  const { data, isError, isLoading, refetch } = useLoadChannelsLinked();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  useEffect(() => {
    refetch();
    if (isError) {
      setMessage(translate('txtSomethingWentWrongTryAgain'));
    }
  }, [isError, refetch, selectedSecondTierItem, setMessage]);

  if (isLoading) {
    return <WppSpinner size="m" data-testid='spinner'/>;
  }

  return <DSTypography type="m-strong">{data}</DSTypography>;
};
