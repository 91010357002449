import styled from '@emotion/styled';

import Colorful from '@uiw/react-color-colorful';
import DSInput from '../../../../shared/DSComponents/DSInput/DSInput';

export const ColorDemo = styled.div(({ backgroundColor = '#fff' }: { backgroundColor?: string }) => ({
  display: 'inline-block',
  position: 'relative',
  float: 'left',
  width: '44px',
  height: '22px',
  borderRadius: '5px',
  border: '1px solid #121619',
  backgroundColor,
}));

export const SketchPicker = styled.div(() => ({
  position: 'absolute',
  marginTop: '1rem',
  zIndex: 9999,
}));

export const SelectColorContainer = styled.div(() => ({
  position: 'relative',
}));

export const InputsColors = styled(DSInput)(() => ({
  minWidth: '12rem',
}));

export const StyledColorful = styled(Colorful)(() => ({
  marginLeft: 20,
}));
