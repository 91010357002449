import React, { useMemo, useEffect, useState } from 'react';
import { CornerDotType, CornerSquareType, Options } from 'qr-code-styling';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar/hooks';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { PREVIEW_STYLE_DEFAULT_OPTIONS } from '../utils/constants';
import QRCodePreview from './QRCodePreview';

export interface IQRStylePreviewProps {}

const QRStylePreview: React.FC<IQRStylePreviewProps> = () => {
  const [options, setOptions] = useState<Options>(PREVIEW_STYLE_DEFAULT_OPTIONS);

  const { dotsOptions, cornersSquareOptions, cornersDotOptions, backgroundOptions, imageOptions, qrOptions, image } =
    useQRStyleState();

  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const validImage = useMemo(() => {
    const isValidDataURL = (url: string): boolean => {
      const regex = /^data:([a-z]+\/[a-z\-]+(;[a-z\-]+=[a-z\-]+)?)?(;base64)?,[a-zA-Z0-9!$&',()*+;=\-._~:@\/?\s]*$/i;
      return regex.test(url);
    };

    return image && isValidDataURL(image) ? image : undefined;
  }, [image]);

  useEffect(() => {
    setOptions((options) => ({
      ...options,
      dotsOptions,
      cornersSquareOptions: {
        ...cornersSquareOptions,
        type: !cornersSquareOptions?.type ? ('' as CornerSquareType) : cornersSquareOptions.type,
      },
      cornersDotOptions: {
        ...cornersDotOptions,
        type: !cornersDotOptions?.type ? ('' as CornerDotType) : cornersDotOptions.type,
      },
      backgroundOptions,
      imageOptions,
      qrOptions,
      image: validImage,
    }));
  }, [
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    imageOptions,
    qrOptions,
    image,
    validImage,
  ]);

  useEffect(() => {
    const resetStateOnCreation = () => {
      if (selectedSecondTierItem?.id === 'new') {
        setOptions(PREVIEW_STYLE_DEFAULT_OPTIONS);
      }
    };

    resetStateOnCreation();
  }, [selectedSecondTierItem]);

  return <QRCodePreview configuration={options} />;
};

export default QRStylePreview;
