import { RadioGroupChangeEvent, SelectChangeEventDetail } from '@wppopen/components-library';
import { WppRadioGroupCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { ComponentProps, useCallback, useEffect, useMemo, useRef } from 'react';
import { BoxContent, ExpandableCard } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { CornerDotType, Gradient } from 'qr-code-styling';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import { useQRStyleOptions } from '../hooks/useQRStyleOptions';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { QRColorType, QRGradientType } from '../types/QRStyleTypes';
import { CORNERS_DOTS_STYLE_OPTIONS, CORNERS_STYLE_DEFAULT_OPTIONS, QRSTYLE_FORM } from '../utils/constants';
import { getColorErrorMessage, getGradientValues } from '../utils/QrStyleUtils';
import { ColorInputGrid } from './ColorInputGrid';
import { ColorTypeRadioGroup } from './ColorTypeRadioGroup';
import { CustomIconError } from './CustomIconError';
import GradientSelect from './GradientSelect';
import { GridContainer, StyledSelectDropDown } from './styles/ExpandablesStyle.styled';

export interface ICornersDotsStyleProps {
  setHasError: (hasError: boolean) => void;
}

const CornersDotsStyle: React.FC<ICornersDotsStyleProps> = ({ setHasError }) => {
  const { cornersDotOptions, setCornersDotOptions, backgroundOptions, valueChanges, setValueChanges } =
    useQRStyleState();

  const hasGradient = cornersDotOptions?.gradient?.colorStops.length === 2;
  const { errorMessages, setErrorMessages, colorType, setColorType } = useQRStyleOptions(hasGradient);

  const isGradientTypeSelected = colorType === 'gradient' || hasGradient;

  const gradientRef = useRef<undefined | null | Gradient>(null);

  const gradientValues = useMemo(() => {
    return getGradientValues('Corners', cornersDotOptions?.gradient, cornersDotOptions?.color);
  }, [cornersDotOptions]);

  const getColors = useCallback(() => {
    if (isGradientTypeSelected) {
      return gradientValues.colorStops;
    } else {
      return [
        {
          color: cornersDotOptions?.color ?? gradientRef.current!.colorStops[0].color,
          offset: 0,
        },
      ];
    }
  }, [cornersDotOptions?.color, gradientValues.colorStops, isGradientTypeSelected]);

  useEffect(() => {
    setColorType(hasGradient ? 'gradient' : 'single');
  }, [hasGradient, setColorType]);

  useEffect(() => {
    const newErrorMessages = getColorErrorMessage(getColors(), backgroundOptions, isGradientTypeSelected);
    const hasErrorMessage = newErrorMessages[0] || newErrorMessages[1];

    if (hasErrorMessage) {
      setErrorMessages(newErrorMessages);
      setHasError(true);
    } else {
      setErrorMessages([]);
      setHasError(false);
    }
  }, [backgroundOptions, getColors, isGradientTypeSelected, setErrorMessages, setHasError]);

  const handleColorTypeChange = (newColorType: typeof colorType) => {
    setColorType(newColorType);
    if (newColorType === 'single') {
      gradientRef.current = cornersDotOptions?.gradient;
      setCornersDotOptions({
        ...cornersDotOptions,
        gradient: undefined,
      });
    } else {
      setCornersDotOptions({
        ...cornersDotOptions,
        gradient: gradientRef.current ?? undefined,
      });
    }
  };

  const handleCornersDotsTypeChange = (selectedCornerDotType: CornerDotType | 'default') => {
    const newCornerDotType = selectedCornerDotType !== 'default' ? selectedCornerDotType : 'square';
    setCornersDotOptions({
      ...cornersDotOptions,
      type: newCornerDotType as CornerDotType,
    });
  };

  const handleRotationChange = (newRotation: string) => {
    if (gradientValues) {
      setCornersDotOptions({
        ...cornersDotOptions,
        gradient: { ...gradientValues, rotation: Number(newRotation) },
      });
    }
  };

  const handleGradientChange = (newGradientType: QRGradientType) => {
    if (gradientValues) {
      setCornersDotOptions({
        ...cornersDotOptions,
        gradient: { ...gradientValues, type: newGradientType },
      });
    }
  };

  const handleChangeColor = (params: ComponentProps<typeof ColorInputGrid>['color']) => {
    if (gradientValues) {
      setCornersDotOptions({
        ...cornersDotOptions,
        color: params[0].color,
        gradient: { ...gradientValues, colorStops: params },
      });
      setValueChanges({ ...valueChanges, newValue: true });
    }
  };

  const handleChange = (
    field: string,
    event: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.cornersDotsType:
        handleCornersDotsTypeChange(event.detail.value as CornerDotType);
        break;
      case QRSTYLE_FORM.colorType:
        handleColorTypeChange(event.target.value as QRColorType);
        break;
      case QRSTYLE_FORM.rotation:
        handleRotationChange(event.detail.value as string);
        break;
      case QRSTYLE_FORM.gradient:
        handleGradientChange(event.target.value as QRGradientType);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  return (
    <ExpandableCard title={translate('txtCornersDotsTitle')}>
      <CustomIconError errorMessage={errorMessages} />
      <BoxContent direction="column" data-testid="corners-dots-style-container">
        <GridContainer>
          <DSTypography type="s-strong">{translate('txtCornersDotsStyleLabel')}</DSTypography>
          <DSTypography type="s-strong">{translate('txtColorTypeLabel')}</DSTypography>
          <div>&nbsp;</div>

          <StyledSelectDropDown
            data-testid="dots-style-dropdown"
            options={CORNERS_DOTS_STYLE_OPTIONS}
            value={cornersDotOptions?.type ?? CORNERS_STYLE_DEFAULT_OPTIONS.CORNERS_TYPE}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.cornersDotsType, e)}
          />
          <ColorTypeRadioGroup colorType={colorType} handleChange={handleChange} />
        </GridContainer>
        <GradientSelect
          data-testid="gradient-select"
          isGradientTypeSelected={isGradientTypeSelected}
          rotation={gradientValues?.rotation?.toString() ?? CORNERS_STYLE_DEFAULT_OPTIONS.ROTATION}
          gradientType={gradientValues?.type ?? CORNERS_STYLE_DEFAULT_OPTIONS.GRADIENT_TYPE}
          handleChange={handleChange}
        />
        <ColorInputGrid
          color={getColors()}
          onChangeColor={handleChangeColor}
          disabled={false}
          isGradient={isGradientTypeSelected}
          errorMessage={errorMessages}
        />
      </BoxContent>
    </ExpandableCard>
  );
};

export default CornersDotsStyle;
