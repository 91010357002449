import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  WppIconSearch,
  WppIconCross,
  WppGrid,
  WppDivider,
  WppAvatar,
  WppActionButton,
} from '@wppopen/components-library-react';
import { BoxContent, ConditionalTooltip } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import OrganisationOption from './OrganisationOption';
import { getStringEllipsed } from '../../../shared/utils';
import { IOrganisation, MenuItemEnum } from '../../../shared/types';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import DSInput from '../../../shared/DSComponents/DSInput/DSInput';
import {
  OrganisationsListContainer,
  SearchInputContainer,
  SyledWppSelect,
  SyledWppProgressIndicator,
} from './styles/SelectOrganisation.styled';
import { filterOrganisations, findOrganisationById } from '../../SideMenu/utils/sideMenuUtils';
import { OrganisationForm } from '../../OrganisationCreate';
import { useSelectedOrganisation } from '../../SideMenu';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useLoadOrganisation } from '../hooks/useLoadOrganisation';
import { useModalControl } from '../hooks/useModalControl';
import OrganisationMoreActions from './OrganisationMoreActions';

export interface ISelectOrganisationProps {}

const ORGANISATION_TITLE_MAX_LENGHT = 16;
const ORGANISATION_OPTION_MAX_LENGHT = 16;

const SelectOrganisation: React.FC<ISelectOrganisationProps> = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedOrganisation, setSelectedOrganisation } = useSelectedOrganisation();
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();

  const { setOpenModalWithAction, isMenuOpened, isModalOpened } = useModalControl();
  const [listOrganisation, setListOrganisation] = useState<IOrganisation[]>([]);
  const { data, loading, error, refetch } = useLoadOrganisation();

  useEffect(() => {
    if (data && !loading) {
      setListOrganisation(data);
    } else {
      setListOrganisation([]);
    }
  }, [data, loading]);

  const handleSelectOrganisation = (organisationId: string) => {
    if (listOrganisation) {
      const organisation = findOrganisationById(listOrganisation, organisationId);
      if (organisation) {
        setSelectedOrganisation(organisation);
        setSelectedSideMenuItem(MenuItemEnum.UNIT);
        navigate(`${organisation.id}/${MenuItemEnum.UNIT}`);
      }
    }
  };

  const handleClickCreateOrganisation = () => {
    setOpenModalWithAction(true);
  };

  const renderOrganisationForm = () => {
    if (isModalOpened) {
      return <OrganisationForm organisationList={listOrganisation} setOrganisationList={setListOrganisation} />;
    }
    return null;
  };

  const renderColapsed = () => {
    return (
      <BoxContent flex center direction="column">
        <div>{renderOrganisationForm()}</div>
        <WppAvatar src={selectedOrganisation?.filePath} size="m" color={selectedOrganisation?.avatarColor} />
        <div>
          <OrganisationMoreActions />
        </div>
      </BoxContent>
    );
  };

  const renderLoadingIndicator = () => {
    if (loading) {
      return (
        <BoxContent marg="0 0 0 0">
          <SyledWppProgressIndicator variant="circle" data-testid="spinner" />
        </BoxContent>
      );
    }
    return null;
  };

  const renderOrganisationList = () => {
    if (data) {
      return filterOrganisations(data, searchTerm).map((o) => (
        <BoxContent marg="2 0" key={o.id}>
          <OrganisationOption
            organisation={o}
            maxLenght={ORGANISATION_OPTION_MAX_LENGHT}
            active={o.id === selectedOrganisation?.id}
          />
        </BoxContent>
      ));
    }
    return null;
  };

  const renderErrorState = () => {
    if (error) {
      return (
        <EmptyState
          title={translate('txtSomethingWentWrong')}
          text={translate('txtErrorMessage')}
          buttonText={translate('txtTryAgain')}
          buttonAction={() => {
            refetch();
          }}
        />
      );
    }
    return null;
  };

  const renderOrganisationsList = () => {
    return (
      <OrganisationsListContainer flex direction="column">
        {renderLoadingIndicator()}
        {renderOrganisationList()}
        {renderErrorState()}
      </OrganisationsListContainer>
    );
  };

  const renderMenuContent = () => {
    if (isMenuOpened) {
      return renderExpanded();
    }
    return renderColapsed();
  };

  const renderWppSelect = () => {
    const isToolTipVisible = () => {
      if (selectedOrganisation) {
        return selectedOrganisation.title?.length > ORGANISATION_TITLE_MAX_LENGHT;
      }
      return false;
    };

    const toolTipContent = () => {
      if (selectedOrganisation) {
        return selectedOrganisation.title;
      }
      return '';
    };

    const placeHolderText = () => {
      if (selectedOrganisation) {
        return getStringEllipsed(selectedOrganisation.title, ORGANISATION_TITLE_MAX_LENGHT);
      }
      return translate('txtPlaceholderSelect', { something: '' });
    };

    return (
      <ConditionalTooltip
        showTooltip={isToolTipVisible()}
        tooltipText={toolTipContent()}
        config={{ placement: 'bottom' }}>
        <SyledWppSelect
          type="text"
          onWppChange={(e: CustomEvent) => handleSelectOrganisation(e.detail.value)}
          placeholder={placeHolderText()}
          value={selectedOrganisation?.id}>
          <SearchInputContainer>
            <DSInput
              data-testid="input-search-org"
              placeholder={translate('txtPlaceholderSearch')}
              message=""
              value={searchTerm}
              onWppChange={(e: CustomEvent) => {
                setSearchTerm(e.detail.value);
              }}
              type="search">
              <WppIconSearch slot="icon-start" aria-label="Search icon" />
              <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={() => setSearchTerm('')} />
            </DSInput>
            {renderOrganisationsList()}
          </SearchInputContainer>
          <WppDivider />
          <div>
            <BoxContent pad="8 16 4 16" w="auto">
              <WppActionButton onClick={() => handleClickCreateOrganisation()} variant="primary">
                {translate('txtCreateNewSomething', { something: translate('txtOrganisation') })}
              </WppActionButton>
            </BoxContent>
          </div>
        </SyledWppSelect>
      </ConditionalTooltip>
    );
  };

  const renderExpanded = () => {
    return (
      <WppGrid container fullHeight direction="row" alignItems="center" justifyContent="space-between">
        <WppGrid container item direction="row" all={20} alignItems="center">
          <BoxContent flex align="center">
            <BoxContent marg="0 4 0 0" w="auto">
              <WppAvatar src={selectedOrganisation?.filePath} size="m" color={selectedOrganisation?.avatarColor} />
            </BoxContent>
            <div>
              {renderWppSelect()}
              {renderOrganisationForm()}
            </div>
          </BoxContent>
        </WppGrid>
        <WppGrid item fullHeight container all={4} alignItems="center" justifyContent="flex-end">
          <OrganisationMoreActions />
        </WppGrid>
      </WppGrid>
    );
  };

  return (
    <BoxContent flex align="center">
      {renderMenuContent()}
    </BoxContent>
  );
};

export default SelectOrganisation;
