import styled from '@emotion/styled';
import DSButton from '../../DSComponents/DSButton/DSButton';


export const DrawerActionButtonsDiv = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
});

export const DrawerActionButton = styled(DSButton)({
  alignItems: 'end',
  marginLeft: 12,
});
