import { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { ExceptionBase, ExceptionCode } from '@core/exceptions';

import { errorHandler } from './http-error-handler';
import { IHttpDefaultConfig } from './http-config';

type TCustomConfig = AxiosRequestConfig | string | undefined;

export type RequestInterceptor = (
  requestConfig: IHttpDefaultConfig,
) => IHttpDefaultConfig | Promise<IHttpDefaultConfig>;

export class HttpService {
  public constructor(private readonly axiosInstance: AxiosInstance) {
    this.initializeInterceptors();
  }

  public addRequestInterceptors(interceptors: RequestInterceptor[]) {
    for (const interceptor of interceptors) {
      this.axiosInstance.interceptors.request.use(interceptor, this.handleError);
    }
  }

  public dontCustomizeErrorHandler() {
    this.axiosInstance.interceptors.response.clear();
  }

  private initializeInterceptors() {
    this.setRetryOtions(this.axiosInstance);
    this.axiosInstance.interceptors.response.use(this.handleResponse, this.handleError);
  }

  private handleResponse(axiosResponse: AxiosResponse) {
    return axiosResponse;
  }

  private handleError(error: AxiosError<ExceptionBase>) {
    errorHandler(error);
  }

  private setRetryOtions(axiosInstance: AxiosInstance) {
    const DELAY_RETRY = 3000;
    const retryOtions = {
      retries: 3,
      retryDelay: (retryCount: number) => retryCount * DELAY_RETRY,
      retryCondition: (error: AxiosError<ExceptionBase>) => {
        const shouldRetry = this.getRetryCondition(error);
        return shouldRetry;
      },
    };

    return axiosRetry(axiosInstance as AxiosInstance, retryOtions as IAxiosRetryConfig);
  }

  private getRetryCondition(error: AxiosError<ExceptionBase>) {
    const defaultStatusError = error?.response?.status === 500;
    const noErrorCode = !error.code;
    const internalServerError = error.code === ExceptionCode.InternalServerError;
    return defaultStatusError && (noErrorCode || internalServerError);
  }

  private injetctTokenIntoHeadersMethod(config: TCustomConfig) {
    if (config) {
      if (typeof config === 'string') {
        const token = config;
        return {
          ...this.axiosInstance.defaults,
          headers: {
            ...this.axiosInstance.defaults.headers.common,
            Authorization: token,
          },
        };
      }
      return config;
    }
    return;
  }

  public get defaultBaseUrl(): string | undefined {
    return this.axiosInstance.defaults.baseURL;
  }

  public get<T>(url: string, token?: string): Promise<AxiosResponse<T>>;
  public get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  public get<T>(url: string, config?: TCustomConfig): Promise<AxiosResponse<T>> {
    const newConfig = this.injetctTokenIntoHeadersMethod(config);
    return this.axiosInstance.get(url, newConfig);
  }

  public post<T = unknown>(url: string, body?: unknown, token?: string): Promise<AxiosResponse<T>>;
  public post<T = unknown>(url: string, body?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  public post<T = unknown>(url: string, body?: unknown, config?: TCustomConfig): Promise<AxiosResponse<T>> {
    const newConfig = this.injetctTokenIntoHeadersMethod(config);
    return this.axiosInstance.post(url, body, newConfig);
  }

  public patch<T = unknown>(url: string, body?: unknown, token?: string): Promise<AxiosResponse<T>>;
  public patch<T = unknown>(url: string, body?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  public patch<T = unknown>(url: string, body?: unknown, config?: TCustomConfig): Promise<AxiosResponse<T>> {
    const newConfig = this.injetctTokenIntoHeadersMethod(config);
    return this.axiosInstance.patch(url, body, newConfig);
  }

  public put<T = unknown>(url: string, body?: unknown, token?: string): Promise<AxiosResponse<T>>;
  public put<T = unknown>(url: string, body?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  public put<T = unknown>(url: string, body?: unknown, config?: TCustomConfig): Promise<AxiosResponse<T>> {
    const newConfig = this.injetctTokenIntoHeadersMethod(config);
    return this.axiosInstance.put(url, body, newConfig);
  }

  public delete<T = unknown>(url: string, token?: string): Promise<AxiosResponse<T>>;
  public delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  public delete<T = unknown>(url: string, config?: TCustomConfig): Promise<AxiosResponse<T>> {
    const newConfig = this.injetctTokenIntoHeadersMethod(config);
    return this.axiosInstance.delete(url, newConfig);
  }

  public getDefaultConfig() {
    return this.axiosInstance.defaults;
  }
}
