import { WppIconWarning } from '@wppopen/components-library-react';
import { ComponentProps } from 'react';

export interface IDSIconWarning extends ComponentProps<typeof WppIconWarning> {}

const DSIconWarning = ({ children, ...wppProps }: IDSIconWarning) => {
  return <WppIconWarning {...wppProps}>{children}</WppIconWarning>;
};

export default DSIconWarning;
