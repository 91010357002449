import { ErrorCorrectionLevel } from 'qr-code-styling';
import { SelectChangeEventDetail } from '@wppopen/components-library';
import { WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { BoxContent, ExpandableCard } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { DEFAULT_ERROR_CORRECTION, QR_OPTIONS } from '../utils/constants';
import { LastGridContainer, StyledQROptionsLabel, StyledSelectDropDown } from './styles/ExpandablesStyle.styled';

export interface IQROptionsStyleProps {}

const QROptionsStyle: React.FC<IQROptionsStyleProps> = () => {
  const { qrOptions, setQrOptions, valueChanges, setValueChanges } = useQRStyleState();

  const handleChange = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setQrOptions({
      ...qrOptions,
      errorCorrectionLevel: e.detail.value as ErrorCorrectionLevel,
    });

    setValueChanges({ ...valueChanges, newValue: true });
  };

  return (
    <ExpandableCard title={translate('txtQROptionsTitle')}>
      <BoxContent direction="column" data-testid="qrOptions-style-container">
        <LastGridContainer>
          <StyledQROptionsLabel type="s-strong">{translate('txtQROptionsLabel')}</StyledQROptionsLabel>
          <div>&nbsp;</div>

          <StyledSelectDropDown
            options={QR_OPTIONS}
            value={qrOptions?.errorCorrectionLevel ?? DEFAULT_ERROR_CORRECTION}
            onWppChange={(e) => handleChange(e)}
            data-testid="select-drop-down"
          />
        </LastGridContainer>
      </BoxContent>
    </ExpandableCard>
  );
};

export default QROptionsStyle;
