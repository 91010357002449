import { ChannelDTO } from '@connected-core-system/utils/channel-http-dto';
import { QRCodeTemplateDTO, QRCodeTemplatePutOrPostDto } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { UniquePropertyExistsException } from '@core/exceptions';
import { HttpService } from '@core/http-service';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { addQueryString, interpolateWithValues } from '../../../shared/utils';
import { IQRStyleRepository } from '../types';

export class QRStyleRepository implements IQRStyleRepository {
  constructor(private readonly httpService: HttpService) {}

  async createQRStyle(
    organisationId: string,
    qrStyle: QRCodeTemplatePutOrPostDto,
  ): Promise<QRCodeTemplateDTO | UniquePropertyExistsException> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_QRCODES_GET, organisationId);
    const result = await this.httpService.post<QRCodeTemplateDTO>(url, qrStyle);
    return result.data;
  }

  async updateQRStyle(
    organisationId: string,
    templateId: string,
    qrStyle: QRCodeTemplatePutOrPostDto,
  ): Promise<QRCodeTemplateDTO | UniquePropertyExistsException> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_QRCODES_PUT, organisationId, templateId);
    const result = await this.httpService.put<QRCodeTemplateDTO>(url, qrStyle);
    return result.data;
  }

  async getChannelCount(organisationId: string, qrCodeTemplateId: string) {
    let url = interpolateWithValues(BFF_ENDPOINTS.CHANNEL, organisationId);
    url = addQueryString(url, {
      qrCodeTemplateId: qrCodeTemplateId,
    });
    const result = await this.httpService.get<ChannelDTO[]>(url);
    return result.data.length;
  }

  getQRStyleList(organisationId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_QRCODES_GET, organisationId);
    return this.httpService.get<QRCodeTemplateDTO[]>(url);
  }
}

export default new QRStyleRepository(HttpResolver.getHttpService());
