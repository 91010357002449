// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useQRTitleState = () => {
  const title = useSliceState<IQRStyleState, 'title'>('title');
  const setTitle = useSliceSetter<IQRStyleActions, 'setTitle'>('setTitle');

  return { title, setTitle };
};
