import { QRCodeTemplateDTO } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { MenuItemEnum } from '../../../shared/types';
import { SecondTierSidebarItem } from '../types';

export const toSecondTierItem = (data: QRCodeTemplateDTO[]) => {
  const qrStyles = data as Omit<QRCodeTemplateDTO, 'organisationId'>[];
  const menuItems: SecondTierSidebarItem[] = qrStyles
    .filter((qr) => qr.templateId)
    .map((qr) => ({ ...qr, id: qr.templateId }));

  return { menuItem: MenuItemEnum.QRSTYLES, data: menuItems };
};
