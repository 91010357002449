import { WppRadioGroup } from '@wppopen/components-library-react';
import { ComponentProps } from 'react';

export const DSRadioGroupMock = ({
  onWppChange,
  value,
  ...props
}: {
  onWppChange?: (event: { detail: { value: string } }) => void;
  value: string;
} & ComponentProps<typeof WppRadioGroup>) => <div>{props.children}</div>;
