import { RadioGroupChangeEvent, SelectChangeEventDetail } from '@wppopen/components-library';
import { WppRadioGroupCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { ComponentProps, useCallback, useEffect, useMemo, useRef } from 'react';
import { BoxContent, ExpandableCard } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { CornerSquareType, Gradient } from 'qr-code-styling';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import { useQRStyleOptions } from '../hooks/useQRStyleOptions';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { QRColorType, QRGradientType } from '../types';
import { CORNERS_SQUARE_STYLE_OPTIONS, CORNERS_STYLE_DEFAULT_OPTIONS, QRSTYLE_FORM } from '../utils/constants';
import { getColorErrorMessage, getGradientValues } from '../utils/QrStyleUtils';
import { ColorInputGrid } from './ColorInputGrid';
import { ColorTypeRadioGroup } from './ColorTypeRadioGroup';
import { CustomIconError } from './CustomIconError';
import GradientSelect from './GradientSelect';
import { GridContainer, StyledSelectDropDown } from './styles/ExpandablesStyle.styled';

export interface ICornersSquareStyleProps {
  setHasError: (hasError: boolean) => void;
}

const CornersSquareStyle: React.FC<ICornersSquareStyleProps> = ({ setHasError }) => {
  const { valueChanges, backgroundOptions, cornersSquareOptions, setCornersSquareOptions, setValueChanges } =
    useQRStyleState();

  const hasGradient = cornersSquareOptions?.gradient?.colorStops.length === 2;
  const { colorType, setColorType, setErrorMessages, errorMessages } = useQRStyleOptions(hasGradient);

  const isGradientTypeSelected = colorType === 'gradient' || hasGradient;

  const gradientRef = useRef<undefined | null | Gradient>(null);

  const gradientValues = useMemo(() => {
    return getGradientValues('Corners', cornersSquareOptions?.gradient, cornersSquareOptions?.color);
  }, [cornersSquareOptions]);

  const getColors = useCallback(() => {
    if (isGradientTypeSelected) {
      return gradientValues.colorStops;
    } else {
      return [
        {
          color: cornersSquareOptions?.color ?? gradientRef.current!.colorStops[0].color,
          offset: 0,
        },
      ];
    }
  }, [cornersSquareOptions?.color, gradientValues.colorStops, isGradientTypeSelected]);

  useEffect(() => {
    setColorType(hasGradient ? 'gradient' : 'single');
  }, [hasGradient, setColorType]);

  useEffect(() => {
    const errors = getColorErrorMessage(getColors(), backgroundOptions, isGradientTypeSelected);
    const hasErrorMessage = errors[0] || errors[1];

    if (hasErrorMessage) {
      setErrorMessages(errors);
      setHasError(true);
    } else {
      setErrorMessages([]);
      setHasError(false);
    }
  }, [backgroundOptions, getColors, isGradientTypeSelected, setErrorMessages, setHasError]);

  const handleColorTypeChange = (newColorType: QRColorType) => {
    if (newColorType === 'single') {
      if (cornersSquareOptions?.gradient) {
        gradientRef.current = cornersSquareOptions?.gradient;
      }
      setCornersSquareOptions({
        ...cornersSquareOptions,
        gradient: undefined,
      });
    } else {
      setCornersSquareOptions({
        ...cornersSquareOptions,
        gradient: gradientRef.current as Gradient,
      });
    }
    setColorType(newColorType);
  };

  const handleChangeCornerSquareType = (selectedCornerSquareType: CornerSquareType | 'default') => {
    const newCornerDotType = selectedCornerSquareType !== 'default' ? selectedCornerSquareType : 'square';
    setCornersSquareOptions({
      ...cornersSquareOptions,
      type: newCornerDotType,
    });
  };

  const handleRotationChange = (newRotation: string) => {
    if (gradientValues) {
      setCornersSquareOptions({
        ...cornersSquareOptions,
        gradient: { ...gradientValues, rotation: Number(newRotation) },
      });
    }
  };

  const handleGradientChange = (newGradientType: QRGradientType) => {
    if (gradientValues) {
      setCornersSquareOptions({
        ...cornersSquareOptions,
        gradient: { ...gradientValues, type: newGradientType },
      });
    }
  };

  const handleChangeColor = (params: ComponentProps<typeof ColorInputGrid>['color']) => {
    if (gradientValues) {
      setCornersSquareOptions({
        ...cornersSquareOptions,
        color: params[0].color,
        gradient: { ...gradientValues, colorStops: params },
      });
      setValueChanges({ ...valueChanges, newValue: true });
    }
  };

  const handleChange = (
    field: string,
    event: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.cornerSquareType:
        handleChangeCornerSquareType(event.detail.value as CornerSquareType);
        break;
      case QRSTYLE_FORM.colorType:
        handleColorTypeChange(event.detail.value as QRColorType);
        break;
      case QRSTYLE_FORM.rotation:
        handleRotationChange(event.detail.value as string);
        break;
      case QRSTYLE_FORM.gradient:
        handleGradientChange(event.target.value as QRGradientType);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  return (
    <ExpandableCard title={translate('txtCornersSquareTitle')}>
      <CustomIconError errorMessage={errorMessages} />
      <BoxContent direction="column" data-testid="corners-square-style-container">
        <GridContainer>
          <DSTypography type="s-strong">{translate('txtCornersSquareStyleLabel')}</DSTypography>
          <DSTypography type="s-strong">{translate('txtColorTypeLabel')}</DSTypography>
          <div>&nbsp;</div>

          <StyledSelectDropDown
            data-testid="dots-style-dropdown"
            options={CORNERS_SQUARE_STYLE_OPTIONS}
            value={cornersSquareOptions?.type ?? CORNERS_STYLE_DEFAULT_OPTIONS.CORNERS_TYPE}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.cornerSquareType, e)}
          />
          <ColorTypeRadioGroup colorType={colorType} handleChange={handleChange} />
        </GridContainer>
        <GradientSelect
          isGradientTypeSelected={isGradientTypeSelected}
          rotation={gradientValues?.rotation?.toString() ?? CORNERS_STYLE_DEFAULT_OPTIONS.ROTATION}
          gradientType={gradientValues?.type ?? CORNERS_STYLE_DEFAULT_OPTIONS.GRADIENT_TYPE}
          handleChange={handleChange}
        />
        <ColorInputGrid
          color={getColors()}
          onChangeColor={handleChangeColor}
          disabled={false}
          isGradient={isGradientTypeSelected}
          errorMessage={errorMessages}
        />
      </BoxContent>
    </ExpandableCard>
  );
};

export default CornersSquareStyle;
