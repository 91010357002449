import { AxiosResponse } from 'axios';
import { UniquePropertyExistsException } from '@core/exceptions';
import { QRCodeTemplateDTO, QRCodeTemplatePutOrPostDto } from '@connected-core-system/utils/qrcodetemplate-http-dto';

export interface IQRStyleRepository {
  createQRStyle(
    organisationId: string,
    qrStyle: QRCodeTemplatePutOrPostDto,
  ): Promise<void | QRCodeTemplateDTO | UniquePropertyExistsException>;

  updateQRStyle(
    organisationId: string,
    templateId: string,
    qrStyle: QRCodeTemplatePutOrPostDto,
  ): Promise<void | QRCodeTemplateDTO | UniquePropertyExistsException>;

  getChannelCount(organisationId: string, qrCodeTemplateId: string): Promise<number>;
  getQRStyleList: (organisationId: string) => Promise<AxiosResponse<QRCodeTemplateDTO[], any>>;
}
