// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState

import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useQROptionsStyleState = () => {
  const qrOptions = useSliceState<IQRStyleState, 'qrOptions'>('qrOptions');
  const setQrOptions = useSliceSetter<IQRStyleActions, 'setQrOptions'>('setQrOptions');

  return { qrOptions, setQrOptions };
};
