import React, { useCallback, useEffect, useState } from 'react';
import {
  WppActionButton,
  WppIconLink,
  WppIconDataTransfer,
  WppIconExternalLink,
  WppSpinner,
  WppTypography,
  WppCard,
} from '@wppopen/components-library-react';
import { BoxContentCenter } from '../../../shared/components';
import { usePromise } from '../../../shared/hooks';
import { translate } from '../../../shared/locales';
import { useSelectedChannelState } from '../../Channel/hooks';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { useIsContentStreamModalOpenedState } from '../../ContentStreamCreate/hooks/useIsContentStreamModalOpenedState';
import { useSelectedContentStreamState } from '../../ContentStreamCreate/hooks/useSelectedContentStreamState';
import { CardChannels, ChannelsHeader } from '../../ContentStreamCreate/views/styles/ContentStreamForm.styled';
import { TargetingText } from '../../ContentStreamCreate/views/styles/Targets.styled';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import channelManager from '../services/ChannelCreateEditManager';
import LinkedFallbackDrawer from './LinkedFallbackDrawer';
import { FallbackCard, FallbackHeader } from './styles/ChannelDetailForm.styled';
import { useContentStreamModalZIndexState } from '../../ContentStreamCreate/hooks/useContentStreamModalZIndexState';
import WarningIconMessage from '../../../shared/components/WarningIconMessage/WarningIconMessage';
import { useIsFallbackContentStreamState } from '../../ContentStreamCreate/hooks/useIsFallbackContentStreamState';
import { useFallbackChangedTryAgainState } from '../hooks/useFallbackChangedTryAgainState';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface ILinkedFallbackProps {
  selectedLinkedFallback: ContentStreamTableDataType | null;
  setSelectedLinkedFallback: (selectedLinkedFallback: ContentStreamTableDataType) => void;
}

const LinkedFallback: React.FC<ILinkedFallbackProps> = ({ selectedLinkedFallback, setSelectedLinkedFallback }) => {
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const { setIsContentStreamModalOpened } = useIsContentStreamModalOpenedState();
  const { setIsFallbackContentStream } = useIsFallbackContentStreamState();
  const { setSelectedContentStream } = useSelectedContentStreamState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedChannel } = useSelectedChannelState();
  const { setContentStreamModalZIndex } = useContentStreamModalZIndexState();
  const { fallbackTryAgain, setFallbackTryAgain } = useFallbackChangedTryAgainState();

  const loadFallbackContentStream = useCallback(async () => {
    if (fallbackTryAgain) setFallbackTryAgain(false);
    if (selectedOrganisation) {
      if (selectedLinkedFallback?.id)
        return channelManager.getFallbackContentStream(selectedOrganisation.id, selectedLinkedFallback.id);
      if (selectedChannel?.fallbackContentStreamId)
        return channelManager.getFallbackContentStream(
          selectedOrganisation.id,
          selectedChannel.fallbackContentStreamId,
        );
    }
    return null;
  }, [
    fallbackTryAgain,
    selectedChannel?.fallbackContentStreamId,
    selectedLinkedFallback?.id,
    selectedOrganisation,
    setFallbackTryAgain,
  ]);

  const { data, ...fallbackPromiseStatusOptions } = usePromise(loadFallbackContentStream);

  useEffect(() => {
    if (data) {
      setSelectedLinkedFallback(data);
    }
  }, [data, setSelectedLinkedFallback]);

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="m" />
      </BoxContentCenter>
    );
  };

  const renderFallbackText = () => {
    if (selectedChannel && !selectedChannel?.fallbackContentStreamId)
      return <WarningIconMessage message={translate('txtChannelFallbackWarning')} />;
    else
      return (
        <TargetingText data-testid={'empty-text'} type="s-body">
          {translate('txtChannelsFallback')}
        </TargetingText>
      );
  };

  const renderFallbackCard = () => {
    if (selectedLinkedFallback) {
      return (
        <FallbackCard variant="secondary">
          <div slot="header">
            <FallbackHeader type="s-strong" data-testid="title">
              {selectedLinkedFallback.name}
            </FallbackHeader>
          </div>
          <div slot="actions">
            <WppActionButton
              onClick={() => {
                setSelectedContentStream(selectedLinkedFallback);
                setContentStreamModalZIndex(10);
                setIsContentStreamModalOpened(true);
                setIsFallbackContentStream(true);
              }}
              variant="secondary"
              data-testid="open-content-stream">
              <WppIconExternalLink slot="icon-start" />
            </WppActionButton>
          </div>
        </FallbackCard>
      );
    }
    return renderFallbackText();
  };

  return (
    <>
      <PlatformWrapper componentBare={CardChannels} componentOs={WppCard}>
        <ChannelsHeader marg="8 0 8 0" h="auto">
          <WppTypography type="m-strong" data-testid="title">
            {translate('txtFallbackUpCase')}
          </WppTypography>
          <WppActionButton
            disabled={fallbackPromiseStatusOptions.loading}
            onClick={() => {
              setIsSideModalOpen(true);
            }}>
            {selectedLinkedFallback ? (
              <>
                <WppIconDataTransfer slot="icon-start" />
                {translate('txtChangeFallback')}
              </>
            ) : (
              <>
                <WppIconLink slot="icon-start" />
                {translate('txtLinkFallback')}
              </>
            )}
          </WppActionButton>
        </ChannelsHeader>
        {fallbackPromiseStatusOptions.loading && renderLoading()}
        {!fallbackPromiseStatusOptions.loading && renderFallbackCard()}
      </PlatformWrapper>

      {isSideModalOpen && (
        <LinkedFallbackDrawer
          selectedLinkedFallback={selectedLinkedFallback}
          setSelectedLinkedFallback={setSelectedLinkedFallback}
          isSideModalOpen={isSideModalOpen}
          setIsSideModalOpen={setIsSideModalOpen}
        />
      )}
    </>
  );
};

export default LinkedFallback;
