import React, { useCallback, useContext } from 'react';
import { BoxContent } from '@connected-core-system/utils/frontend';
import { WppInput } from '@wppopen/components-library-react';
import { SelectDropDownBottom } from '../../../shared/components';
import { useHostName, usePromise } from '../../../shared/hooks';
import { translate } from '../../../shared/locales';
import { IPromiseStatus } from '../../../shared/types';
import { interpolateWithValues, isArrayEmpty } from '../../../shared/utils';
import { useSelectedBrandState, useSelectedChannelState, useSelectedUnitState } from '../../Channel/hooks';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { StatusEnum } from '../../SecondTierSidebar/types';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { TagCategoryForm } from '../../TagCategory';
import { UpdatedCardInfo } from '../../UpdatedInfo';
import { getQrCodeUrl, getValue } from '../channelCreateUtils';
import channelManager from '../services/ChannelCreateEditManager';
import { ChannelFormCreate } from '../types/ChannelCreate';
import ChannelQRCode from './ChannelQRCode';
import LinkedFallback from './LinkedFallback';
import {
  ContainerCard,
  ContainerSelectUnitBrand,
  ContainerTitle,
  FormContainer,
  WrapperSelectUnitBrand,
} from './styles/ChannelDetailForm.styled';
import LinkUrl from './LinkUrl';
import { IChannelRequestsDoneStates } from '../types/IChannelRequestsDoneStates';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface IChannelDetailFormProps {
  channel: ChannelFormCreate & { channelURL?: string; status: StatusEnum };
  setChannel: (channel: ChannelFormCreate & { channelURL?: string; status: StatusEnum }) => void;
  setChannelTagsFromScreen: any;
  promise: IPromiseStatus;
  selectedFallbackState: ContentStreamTableDataType | null;
  setSelectedFallbackState: (selectedLinkedFallback: ContentStreamTableDataType) => void;
  reloadTagCategory: boolean;
  setReloadTagCategory: (reload: boolean) => void;
  areObjectsEqual?: boolean;
  qrCodeDataFlag?: boolean;
  requestsDone: IChannelRequestsDoneStates;
  setRequestsDone: (requestsDone: React.SetStateAction<IChannelRequestsDoneStates>) => void;
}

const ChannelDetailForm: React.FC<IChannelDetailFormProps> = ({
  channel,
  setChannel,
  setChannelTagsFromScreen,
  promise,
  selectedFallbackState,
  setSelectedFallbackState,
  reloadTagCategory,
  setReloadTagCategory,
  areObjectsEqual,
  qrCodeDataFlag,
  requestsDone,
  setRequestsDone,
}) => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedBrandId } = useSelectedBrandState();
  const { selectedUnitId } = useSelectedUnitState();
  const { selectedChannel } = useSelectedChannelState();
  const { insideWppOs } = useContext(BackofficeAppContext);
  const baseHostName = useHostName();

  const loadOptions = useCallback(async () => {
    if (selectedOrganisation) {
      return channelManager.getFormSelectOptions(selectedOrganisation.id);
    }
    return null;
  }, [selectedOrganisation]);

  const { data, ...promiseStatusOptions } = usePromise(loadOptions);

  const txtChannelNamePlaceholder = translate('txtChannelNamePlaceholder');
  const txtChannelTitle = translate('txtTitle');
  const txtChannelUnitPlaceholder = promiseStatusOptions.loading
    ? translate('txtLoading', { something: translate('txtUnit') })
    : translate('txtChannelUnitPlaceholder');
  const txtChannelUnit = translate('txtChannelUnit');
  const txtChannelBrandPlaceholder = promiseStatusOptions.loading
    ? translate('txtLoading', { something: translate('txtBrands') })
    : translate('txtChannelBrandPlaceholder');
  const txtChannelBrand = translate('txtChannelBrand');

  const handleSelectChange = (name: string, event: CustomEvent) => {
    setChannel({
      ...channel,
      [name]: event.detail.value,
    });
  };

  const handleInputChange = (value: string) => {
    setChannel({
      ...channel,
      name: value,
    });
  };

  return (
    <BoxContent flex marg="16 0 16 0" data-id="teste">
      <BoxContent w="75%" marg="0 16 0 0">
        <ContainerCard>
          <FormContainer>
            <ContainerTitle>
              <WppInput
                placeholder={txtChannelNamePlaceholder}
                data-testid="input-channel-title"
                required
                autoFocus
                name="title"
                labelConfig={{
                  text: txtChannelTitle,
                }}
                value={channel?.name || ''}
                onWppChange={(event: CustomEvent) => handleInputChange(event.detail.value)}
              />
            </ContainerTitle>
            <WrapperSelectUnitBrand>
              <ContainerSelectUnitBrand>
                <SelectDropDownBottom
                  data-testid="select-unit"
                  onWppChange={(e: CustomEvent) => handleSelectChange('unitId', e)}
                  value={data && data.units.length ? getValue(selectedUnitId ?? undefined, channel.unitId) : ''}
                  placeholder={txtChannelUnitPlaceholder}
                  labelConfig={{
                    text: txtChannelUnit,
                  }}
                  required
                  disabled={!!(promiseStatusOptions.error || isArrayEmpty(data?.units) || selectedUnitId)}
                  options={data ? data.units : []}
                  {...(promiseStatusOptions.error && {
                    messageType: 'error',
                    message: translate('txtLoadFail', {
                      type: translate('txtUnit'),
                    }),
                  })}
                />
              </ContainerSelectUnitBrand>
              <ContainerSelectUnitBrand>
                <SelectDropDownBottom
                  data-testid="select-brand"
                  onWppChange={(e: CustomEvent) => handleSelectChange('brandId', e)}
                  value={data && data.units.length ? getValue(selectedBrandId ?? undefined, channel.brandId) : ''}
                  placeholder={txtChannelBrandPlaceholder}
                  labelConfig={{
                    text: txtChannelBrand,
                  }}
                  required
                  disabled={!!(promiseStatusOptions.error || isArrayEmpty(data?.brands) || selectedBrandId)}
                  options={data ? data.brands : []}
                  {...(promiseStatusOptions.error && {
                    messageType: 'error',
                    message: translate('txtLoadFail', {
                      type: translate('txtBrands'),
                    }),
                  })}
                />
              </ContainerSelectUnitBrand>
            </WrapperSelectUnitBrand>
          </FormContainer>
        </ContainerCard>
        <BoxContent marg={insideWppOs ? '10 0' : '16 0 0 0'}>
          <ContainerCard>
            <BoxContent>
              {baseHostName && (
                <ChannelQRCode
                  isSaving={promise.loading && !channel?.channelURL}
                  baseDomain={interpolateWithValues(baseHostName, selectedOrganisation?.domain ?? '')}
                  handleSelectChange={handleSelectChange}
                  areObjectsEqual={areObjectsEqual}
                  setChannel={setChannel}
                  channel={channel}
                  qrCodeDataFlag={qrCodeDataFlag}
                />
              )}
            </BoxContent>
          </ContainerCard>
        </BoxContent>
        {selectedOrganisation && (
          <TagCategoryForm
            loadedTagCategory={requestsDone.tags}
            setLoadedTagCategory={(hasLoadedTagCategory: boolean) => {
              setRequestsDone((prevLoadingStates) => ({
                ...prevLoadingStates,
                tags: hasLoadedTagCategory,
              }));
            }}
            organisationId={selectedOrganisation.id}
            existentEntityTags={channel.channelTags}
            setCategoryTags={setChannelTagsFromScreen}
            reloadTagCategory={reloadTagCategory}
            setReloadTagCategory={setReloadTagCategory}
          />
        )}
      </BoxContent>
      <BoxContent w="25%">
        <BoxContent marg={insideWppOs ? '0 0 10 0' : '0 0 16 0'} h="auto">
          <LinkUrl
            url={
              selectedChannel ? getQrCodeUrl(baseHostName, selectedOrganisation?.domain ?? '', selectedChannel?.id) : ''
            }
          />
        </BoxContent>
        <BoxContent marg={insideWppOs ? '0 0 10 0' : '0 0 16 0'} h="auto">
          <UpdatedCardInfo
            createMode={!selectedChannel}
            userEmail={selectedChannel?.updatedBy}
            lastUpdated={selectedChannel ? selectedChannel?.updatedAt : new Date()}
          />
        </BoxContent>
        <BoxContent marg={insideWppOs ? '0 0 10 0' : '0 0 16 0'} h="auto">
          <LinkedFallback
            selectedLinkedFallback={selectedFallbackState}
            setSelectedLinkedFallback={setSelectedFallbackState}
          />
        </BoxContent>
      </BoxContent>
    </BoxContent>
  );
};

export default ChannelDetailForm;
