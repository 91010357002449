/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useEffect, useState } from 'react';
import { WppTypography } from '@wppopen/components-library-react';
import { AutoCompleteDropDown, BoxContent } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { ContainerCard } from './styles/ContentStreamForm.styled';
import contentCreateManager from '../services/ContentStreamCreateManager';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { usePromise } from '../../../shared/hooks';
import { TOption } from '../../TagCategory/types/TagCategoryFormField';
import { useSelectedContentStreamState } from '../hooks/useSelectedContentStreamState';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';

export interface ICampaignFormProps {
  selectedCampaign: TOption[];
  setSelectedCampaign: (campaign: TOption[]) => void;
  setRequestsDone: (requestsDone: React.SetStateAction<IContentStreamRequestsDoneStates>) => void;
}

const CampaignForm: React.FC<ICampaignFormProps> = ({ selectedCampaign, setSelectedCampaign, setRequestsDone }) => {
  const [campaignOptions, setCampaignOptions] = useState<TOption[]>([]);

  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedContentStream } = useSelectedContentStreamState();

  const loadOptions = useCallback(async () => {
    if (selectedOrganisation?.id) {
      return contentCreateManager.getCampaigns(selectedOrganisation?.id);
    }
    return null;
  }, [selectedOrganisation?.id, selectedContentStream]);

  const { data, ...promiseStatus } = usePromise(loadOptions);

  const handleCreateNewCampaign = (newCampign: TOption) => {
    setCampaignOptions([...campaignOptions, newCampign]);
    setSelectedCampaign([newCampign]);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setCampaignOptions(data);
      if (selectedContentStream) {
        setSelectedCampaign(data.filter((campaign) => campaign.value === selectedContentStream.campaignId));
      }
      setRequestsDone((prevLoadingStates) => ({
        ...prevLoadingStates,
        campaign: true,
      }));
    }
  }, [data, setSelectedCampaign, selectedContentStream, setRequestsDone]);

  const txtCampaign = translate('txtCampaign');
  const placeHolderTxt = translate('txtChooseCampaign');

  const renderAutocomplete = () => {
    return (
      <AutoCompleteDropDown
        dataTestId="select-campaign"
        isMultipleSelection={false}
        isLoading={promiseStatus.loading}
        placeHolder={placeHolderTxt}
        entityName={txtCampaign}
        disabled={promiseStatus.loading || promiseStatus.error}
        options={campaignOptions}
        selectedOptions={selectedCampaign}
        setSelectedOptions={setSelectedCampaign}
        showCreateNewElement
        handleCreateNewOption={handleCreateNewCampaign}
      />
    );
  };

  return (
    <ContainerCard>
      <BoxContent>
        <WppTypography type="m-strong">{txtCampaign}</WppTypography>
      </BoxContent>
      <BoxContent marg="16 0">
        {campaignOptions && campaignOptions.length > 0 && renderAutocomplete()}
        {(!campaignOptions || campaignOptions.length <= 0) && renderAutocomplete()}
      </BoxContent>
    </ContainerCard>
  );
};

export default CampaignForm;
