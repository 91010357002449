import { PlatformWrapper } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import DSInput from '../../../shared/DSComponents/DSInput/DSInput';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { TitleContainerBare, TitleContainerOs } from './styles/QRStylePage.styled';

export interface IQRTitleProps {}

const QRTitle: React.FC<IQRTitleProps> = () => {
  const { valueChanges, setValueChanges, title, setTitle } = useQRStyleState();

  const handleInputChange = (value: string) => {
    setTitle(value);
    setValueChanges({
      ...valueChanges,
      newTitle: true,
    });
  };

  return (
    <PlatformWrapper componentBare={TitleContainerBare} componentOs={TitleContainerOs} data-testid="title-container">
      <DSInput
        data-testid="input-title"
        autoFocus
        name="title"
        labelConfig={{ text: translate('txtTitle') }}
        size="m"
        required
        placeholder={translate('txtQRTitlePlaceholder')}
        value={title}
        onWppChange={(event: CustomEvent) => handleInputChange(event.detail.value)}
      />
    </PlatformWrapper>
  );
};

export default QRTitle;
