
// TODO 4230 -  CHECK IMPORTS ORDER: 3RD PARTY IMPORTS, SHARED, FEATURES, CURRENT FEATURE
import React, { useEffect, useState } from 'react';

import { ConflictException, ExceptionBase, UniquePropertyExistsException } from '@core/exceptions';
import { TabsChangeEventDetail } from '@wppopen/components-library';
import { WppIconArchive } from '@wppopen/components-library-react';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderActions, Tabs } from '../../../shared/components';
import { IHeaderActionsItem } from '../../../shared/components/HeaderActions/HeaderActions';
import { translate } from '../../../shared/locales/i18n';
import { MenuItemEnum, StatusEnum } from '../../../shared/types';
import { ChannelTable } from '../../Channel/index';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { tabs } from '../../Home/utils/homeUtils';
import {
  useSecondTierItemIdToSelectOnReloadState,
  useSecondTierReloadState,
  useSecondTierSelectedItemState,
} from '../../SecondTierSidebar';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import {
  ContainerBare,
  ContainerOs,
  HeaderActionsContainer,
  HeaderContainer,
  MainContentTableContainer,
  TabsContainer,
} from './styles/BrandPage.styled';
import TabDetails from './TabDetails';
import { brandPageManager } from '../services';
import { useBrandValueChangesState } from '../hooks';
import { verifyWebSiteLink } from '../utils/brandPageUtils';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBrandPageProps {}

const BrandPage: React.FC<IBrandPageProps> = () => {
  const [selectedTab, setSelectedTab] = useState('details');

  const { setSecondTierReload } = useSecondTierReloadState();
  const { selectedSecondTierItem, setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { setBrandValueChanges } = useBrandValueChangesState();

  const navigate = useNavigate();
  const [newBrandTitle, setNewBrandTitle] = useState(selectedSecondTierItem?.name || '');
  // TODO 4230 - AVOID ANY
  const [brandUrl, setBrandUrl] = useState<string | undefined>((selectedSecondTierItem as any)?.brandUrl);
  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    successToastMessage: '',
    errorToastMessage: '',
  });
  const [hasErrorMessage, setHasErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    setHasErrorMessage(false);
    if (selectedSecondTierItem && selectedSecondTierItem.status !== StatusEnum.UNPUBLISHED) {
      setNewBrandTitle(selectedSecondTierItem?.name);
      setSelectedTab('details');
      setBrandUrl((selectedSecondTierItem as any)?.brandUrl);
    } else {
      setNewBrandTitle('');
      setBrandUrl(undefined);
    }
  }, [selectedSecondTierItem]);

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setSelectedTab(event.detail.value);
  };

  const getSaveActionButtonState = () => {
    if (selectedSecondTierItem) {
      if (!newBrandTitle) return true;
      return newBrandTitle === selectedSecondTierItem.name && brandUrl === (selectedSecondTierItem as any)?.brandUrl;
    }
    return false;
  };

  const archiveAction: IHeaderActionsItem = {
    title: translate('txtArchive'),
    type: 'secondary',
    icon: <WppIconArchive slot="icon-start" />,
    action: () => {
      alert('Archive Clicked');
    },
  };

  const saveAction: IHeaderActionsItem = {
    title: translate('txtSave'),
    type: 'primary',
    disabled: getSaveActionButtonState(),
    loading: promise.loading,
    action: () => {
      if (selectedOrganisation && selectedSecondTierItem) {
        setPromise({
          loading: true,
          error: false,
          success: false,
          successToastMessage: '',
          errorToastMessage: '',
        });
        if (selectedSecondTierItem.status === StatusEnum.UNPUBLISHED) createNewBrand(selectedOrganisation.id);
        else updateBrand(selectedOrganisation.id, selectedSecondTierItem);
      }
    },
  };

  // TODO 4230 - MOVE THIS TO A HOOK THAT WILL BE RESPONSIBLE TO DISPATCH THE PROMISE AND MANAGE ITS STATUS (useBrandSendData - USE REACT QUERY)
  // AND USE ASYNC AWAIT NOTATION
  const createNewBrand = (organisationId: string) => {
    brandPageManager
      .createNewBrand(organisationId, newBrandTitle, brandUrl ? verifyWebSiteLink(brandUrl) : undefined)
      .then((returnedId) => {
        setHasErrorMessage(false);
        setPromise({
          loading: false,
          error: false,
          success: true,
          successToastMessage: translate('txtSuccessCreated', { something: translate('txtBrand') }),
          errorToastMessage: '',
        });
        setBrandValueChanges(false);
        setSecondTierReload(true);
        setItemIdToSelectOnReload(returnedId ?? null);
        setSelectedSecondTierItem({
          name: newBrandTitle,
          id: returnedId ?? '',
          status: StatusEnum.ACTIVE,
        });
        navigate(`/organisation/${organisationId}/brands/${returnedId}`);
      })
      .catch((error) => {
        if (error instanceof ConflictException) setHasErrorMessage(true);
        catchBrandError(error);
      });
  };

  // TODO 4230 - MOVE THIS TO A HOOK THAT WILL BE RESPONSIBLE TO DISPATCH THE PROMISE AND MANAGE ITS STATUS (useBrandSendData - USE REACT QUERY)
  // AND USE ASYNC AWAIT NOTATION
  const updateBrand = (organisationId: string, secondTierItem: SecondTierSidebarItem) => {
    brandPageManager
      .updateBrand(
        organisationId,
        { ...secondTierItem, name: newBrandTitle },
        brandUrl ? verifyWebSiteLink(brandUrl) : undefined,
      )
      .then(() => {
        setHasErrorMessage(false);
        setPromise({
          loading: false,
          error: false,
          success: true,
          successToastMessage: translate('txtChangesSaved'),
          errorToastMessage: '',
        });
        setBrandValueChanges(false);
        setSecondTierReload(true);
        setItemIdToSelectOnReload(secondTierItem.id);
        navigate(`/organisation/${organisationId}/brands/${secondTierItem.id}`);
      })
      .catch((error) => {
        if (error instanceof ConflictException) setHasErrorMessage(true);
        catchBrandError(error);
      });
  };

  const catchBrandError = (error: ExceptionBase) => {
    const errorMessage =
      error instanceof UniquePropertyExistsException ? translate('txtTitleUsed') : translate('txtErrorMessage');
    setPromise({
      loading: false,
      error: true,
      success: false,
      successToastMessage: '',
      errorToastMessage: errorMessage,
    });
  };

  const getTagColor = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? 'positive' : 'neutral';
  };

  const getHeaderActions = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? [archiveAction, saveAction] : [saveAction];
  };

  const detailsContent = () => {
    return (
      <TabDetails
        newBrandTitle={newBrandTitle}
        setNewBrandTitle={setNewBrandTitle}
        brandUrl={brandUrl}
        setBrandUrl={setBrandUrl}
        hasErrorMessage={hasErrorMessage}
        setHasErrorMessage={setHasErrorMessage}
      />
    );
  };

  const channelsContent = () => {
    return (
      <MainContentTableContainer>
        <ChannelTable location={MenuItemEnum.BRANDS} data-testid={'channel-table-brand'} />
      </MainContentTableContainer>
    );
  };

  return selectedSecondTierItem ? (
    <PlatformWrapper componentBare={ContainerBare} componentOs={ContainerOs}>
      <FallbackWrapper {...promise} />
      <HeaderContainer>
        <Header
          title={selectedSecondTierItem?.name || ''}
          tag={selectedSecondTierItem.status}
          tagColor={getTagColor()}
        />
      </HeaderContainer>
      <HeaderActionsContainer>
        <HeaderActions actions={getHeaderActions()} />
      </HeaderActionsContainer>
      <TabsContainer>
        <Tabs tabs={tabs} handleTabChange={handleTabChange} selectedTab={selectedTab} />
      </TabsContainer>
      {/* // TODO 4231 - AVOID CONDITIONAL RENDERING WITH EXPRESSIONS ({... && ...}) OR TERNARY OPERATOR IN JSX - CREATE A RENDER FUNCTION WITH IF STATEMENT (MAKE THE CODE MORE READABLE) */}
      {selectedTab === 'channels' ? channelsContent() : detailsContent()}
    </PlatformWrapper>
  ) : null;
};

export default BrandPage;
