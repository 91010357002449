import DSIconError from "../../../shared/DSComponents/DSIconError/DSIconError";

interface CustomIconErrorProps {
  errorMessage: string[];
}

export const CustomIconError: React.FC<CustomIconErrorProps> = ({ errorMessage }) => {
  const hasError = errorMessage[0] || errorMessage[1];
  if (!hasError) {
    return null;
  }
  return <DSIconError slot="actions" data-testid="error-icon"/>;
};

export default CustomIconError;