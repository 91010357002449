import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BoxContent } from '../../../shared/components';
import { WppTypography, WppCheckbox } from '@wppopen/components-library-react';
import ExpandableCard from '../../../shared/components/ExpandableCard/ExpandableCard';
import { Checkbox, GridContainer, StyledFileUpload, StyledInput } from '../styles/ExpandablesStyle.styled';
import { translate } from '../../../shared/locales';
import { useImageState } from '../hooks/useImageState';
import { useImageOptionsState } from '../hooks/useImageOptionsState';
import { isArrayEmpty } from '../../../shared/utils';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { Options } from 'qr-code-styling';
import { QRImageOptions } from '../types/QRStyleTypes';
import { useValueChangesState } from '../hooks/useValueChangesState';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IImageStyleProps {}

// TODO 4231 - MOVE INITIAL OPTIONS TO FEATURE UTILS FILE
const DEFAULT_OPTIONS = {
  IMAGE_SIZE: '0.4',
  MARGIN: '0',
  HIDE_BACKGROUND_DOTS: true,
};

const ImageStyle: React.FC<IImageStyleProps> = () => {
  // TODO 4231 - MAYBE INSTEAD OF HAVING ONE STATE FOR EACH PROPERTY, IT'S BETTER TO HAVE A OBJECT STATE WITH ALL PROPERTIES
  const [imageSize, setImageSize] = useState<string>(DEFAULT_OPTIONS.IMAGE_SIZE);
  const [margin, setMargin] = useState<string>(DEFAULT_OPTIONS.MARGIN);
  const [hideBackgroundDots, setHideBackgroundDots] = useState<boolean>(DEFAULT_OPTIONS.HIDE_BACKGROUND_DOTS);

  const { image, setImage } = useImageState();
  const { setImageOptions } = useImageOptionsState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const fileUploadRef = useRef(null);

  const { valueChanges, setValueChanges } = useValueChangesState();

  const handleFileUploadChange = (event: CustomEvent) => {
    if (!isArrayEmpty(event.detail.value)) {
      const files = event.detail.value as Array<File>;
      const reader = new FileReader();
      const newFile = files[0] ?? files;
      if (newFile) {
        reader.readAsDataURL(newFile);
        reader.onload = function () {
          setImage(reader.result as string);
          setValueChanges({ ...valueChanges, newValue: true });
        };
      } else {
        setImage('');
      }
    } else {
      setImage('');
      setValueChanges({ ...valueChanges, newValue: true });
    }
  };

  const fillStyleFields = useCallback(async () => {
    // TODO 4231 - AVOID EXPRESSIONS IN IF CONDITIONS
    // TODO 4231 - AVOID ANY
    // TODO 4231 - AVOID ONE LINE IF STATEMENT
    if ((selectedSecondTierItem as any)?.configuration) {
      const setImageData = async () => {
        if (!fileUploadRef.current) return;
        await (fileUploadRef.current as any).reset();
        if (image) {
          const response = await fetch(image);
          const blob = await response.blob();
          const file: File = new File([blob], 'unknown');
        }
        if (options?.image) {
          const response = await fetch(options.image);
          const blob = await response.blob();
          const fileArr: File[] = [new File([blob], 'unknown')];
          (fileUploadRef.current as any).successList = fileArr;
          setImage(options.image);
        } else {
          setImage('');
        }
      };
      const setImageOptionsData = () => {
        if (imageOptions?.imageSize) setImageSize(imageOptions.imageSize.toString());
        if (imageOptions?.margin) setMargin(imageOptions.margin.toString());
        if (imageOptions?.hideBackgroundDots) setHideBackgroundDots(imageOptions.hideBackgroundDots);
      };

      const options: Options = (selectedSecondTierItem as any)?.configuration;
      const imageOptions: QRImageOptions = options.imageOptions;

      await setImageData();
      setImageOptionsData();
    } else {
      // TODO 4231 - CREATE A RESET FUNCTION LIKE  - resetDefaultOptions - AND JUST CALL IT HERE
      const setDefaultOptions = async () => {
        setImageSize(DEFAULT_OPTIONS.IMAGE_SIZE);
        setMargin(DEFAULT_OPTIONS.MARGIN);
        setHideBackgroundDots(DEFAULT_OPTIONS.HIDE_BACKGROUND_DOTS);
        if (!fileUploadRef.current) return;
        await (fileUploadRef.current as any).reset();
        setImage('');
      };

      await setDefaultOptions();
    }
    // TODO 4231 - REVIEW THIS, A DEPENDENCY ARRAY SHOULD NEVER IGNORE DEPENDENCIES
  }, [selectedSecondTierItem, setImage]); //Warning: Do not add the image state variable to the dependency array, it may cause bugs!

  useEffect(() => {
    // TODO 4231 - INSTEAD OF CREATING A FUNCTION WITH useCallback JUST TO CALL IT HERE, IN THE useEffect
    // JUST MOVE THE fillStyleFields IMPLEMENTATION INTO THE useEffect
    fillStyleFields();
  }, [fillStyleFields, selectedSecondTierItem]);

  useEffect(() => {
    setImageOptions({
      margin: Number(margin),
      imageSize: Number(imageSize),
      hideBackgroundDots,
    });
  }, [margin, imageSize, hideBackgroundDots, setImageOptions]);

  return (
    <ExpandableCard title={translate('txtImageTitle')}>
      {' '}
      <BoxContent direction="column" data-testid="image-style-container">
        <GridContainer>
          <WppTypography type="s-strong">{translate('txtImageSizeLabel')}</WppTypography>

          <WppTypography type="s-strong">{translate('txtMarginLabel')}</WppTypography>
          <div>&nbsp;</div>

          <StyledInput
            type="number"
            value={imageSize as string}
            onWppChange={(e) => setImageSize(e.detail.value as string)}
          />
          <StyledInput
            type="number"
            value={margin as string}
            onWppChange={(e) => setMargin(e.detail.value as string)}
          />
        </GridContainer>
        <Checkbox>
          <BoxContent flex align="center">
            <WppCheckbox
              checked={hideBackgroundDots}
              labelConfig={{ text: translate('txtHideBackgroundDots') }}
              name="hide-background-dots"
              onWppChange={({ detail: { checked } }) => setHideBackgroundDots(checked)}
              required
            />
          </BoxContent>
        </Checkbox>
        <BoxContent flex>
          <StyledFileUpload
            ref={fileUploadRef}
            onWppChange={handleFileUploadChange}
            multiple={false}
            disabled={!!image}
            locales={{
              label: translate('txtFileLabel'),
              text: translate('txtFileText'),
              sizeError: translate('txtFileSizeError'),
              formatError: translate('txtFileFormatError'),
              info: (accept: string, size: number) => translate('txtFileUploadInfo', { accept, size }),
            }}
          />
        </BoxContent>
      </BoxContent>
    </ExpandableCard>
  );
};

// TODO 4231 - SEE COMMENTS FOR SIMILAR ERRORS IN CornersDotsStyle AND BackgroundStyle

export default ImageStyle;
