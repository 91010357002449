import { ILanguageTexts } from '../ILanguageTexts';

const pt_BRLanguage: ILanguageTexts = {
  btnCancel: 'Cancelar',
  btnCreate: 'Criar',
  btnTryAgain: 'Tentar novamnte',
  btnSave: 'Salvar',
  btnSaveSomething: 'Salvar {{something}}',
  btnEdit: 'Editar',
  btnDownloadQRCode: 'Baixar',
  btnAdd: 'Adicionar {{something}}',
  btnClearSelection: 'Limpar Seleção',
  btnPublish: 'Publicar',
  btnUnpublish: 'Despublicar',

  txtLoading: 'Carregando {{something}}...',
  txtLoadFail: 'Falha ao carregar {{type}}',
  tabDetails: 'Detalhes',
  tabContentStream: 'Conteúdos',
  tabContentStreamPriority: 'Prioridade dos Conteúdos',
  tabTargets: 'Segmentações',
  txtTarget: 'Segmentação',
  tabContent: 'Conteúdo',
  txtChoosePlaceHolder: 'Selecione um arquivo',
  txtPlaceholderSearch: 'Buscar',
  txtPlaceholderSelect: 'Selecionar',
  txtDefaulTextPlaceholder: 'Texto',
  txtDefaulText: 'Default',
  txtChannelNamePlaceholder: 'Informe o nome do canal',
  txtChannelUnitPlaceholder: 'Selecione a unidade',
  txtChannelBrandPlaceholder: 'Selecione a marca',
  txtPlaceholderCategoryFirst: 'Selecione a categoria primeiro',
  txtChooseCampaign: 'Selecione uma campanha',
  txtWelcomeMessage: 'Bem vindo',
  txtUnit: 'Unidade',
  txtBrands: 'Marcas',
  txtChannels: 'Canais',
  txtQrStyles: 'Estilos QR',
  txtStyle: 'Estilo',
  txtCms: 'CMS',
  txtTags: 'Tags',
  txtCampaigns: 'Campanhas',
  txtCampaign: 'Campanha',
  txtCategories: 'Categorias',
  txtChooseCategory: 'Selecione uma categoria',
  txtCategory: 'Categoria',
  txtContentStream: 'Fluxo de Conteúdo',
  txtUsers: 'Usuários',
  txtRoles: 'Regras e permissões',
  txtAdmin: 'ADMINISTRADOR',
  txtDomain: 'Domínio Escaneável',
  txtOrganisationExternalDomain: 'Domínio da Organização',
  txtTitle: 'Título',
  txtName: 'Nome',
  txtChangesSaved: 'Alterações salvas com sucesso',
  txtContentLoadingErrorMessage:
    'Ocorreu um problema ao carregar as informações sobre o status dos conteúdos, tente novamente mais tarde.',
  txtErrorMessage: 'Ocorreu um problema inesperado, por favor tente novamente mais tarde',
  txtDomainExistsMessage: 'Já existe uma organização com este domínio. Por favor escolha outro domínio.',
  txtSuccess: 'Sucesso',
  txtSuccessPublished: '{{something}} publicado(a) com sucesso',
  txtSuccessUnpublished: '{{something}} despublicado(a) com sucesso',
  txtSuccessCreated: '{{something}} criado(a) com sucesso',
  txtSuccessUpdated: '{{something}} atualizado(a) com sucesso',
  txtError: 'Erro',
  txtTryAgain: 'Tentar novamente',
  txtInformation: 'Informação',
  txtLastUpdate: 'Última atualização',
  txtBy: 'Por',
  txtJustNow: 'Agora',
  txtYesterday: 'Ontem',
  txtAgo: 'atrás',
  txtEditOrganisation: 'Editar organização',
  txtMustSelectOrganisation: 'Você deve selecionar uma organização',
  txtChooseFile: 'Selecione um arquivo',
  txtTenantId: 'ID do inquilino',
  txtUploadHere: 'para subir ou arraste-o aqui',
  txtAcceptUpload: 'Apenas arquivos .jpg ou .png de até 5 MB',
  txtCreateChannel: 'Criar canal',
  txtTitlePlaceholder: 'Informe o título',
  txtFilter: 'Filtros',
  txtArchive: 'Arquivar',
  txtSave: 'Salvar',
  txtEnterYourUnitName: 'Digite o nome da nova unidade',
  txtEnterYourBrandName: 'Digite o nome da nova marca',
  txtChannel: 'Canal',
  txtNoChannelsYet: 'Não existem canais criados ainda',
  txtNoChannelsCreated: 'Parece que não temos canais criados ainda. Você pode criar um clicando no botão abaixo',
  txtChannelUnit: 'Unidade',
  txtChannelBrand: 'Marca',
  txtCreateContentStream: 'Criar Fluxo de Conteúdo ',
  txtCreateFallback: 'Criar Fallback',
  txtAssociateContentStream: 'Ir para página de Fluxo de Conteúdos',
  txtNoContentStreamYet: 'Não existem fluxos de conteúdos criados ainda',
  txtNoContentStreamAssociatedYet: 'Não há fluxos de conteúdo associados ainda',
  txtNoContentCreated: 'Parece que não temos conteúdos criados ainda. Você pode criar um clicando no botão abaixo',
  txtNoContentStreamAssociated: 'Associe um conteúdo a este canal',
  txtNewContentStream: 'Novo Fluxo de Conteúdo',
  txtBrand: 'Marca',
  txtOrganisation: 'Organização',
  txtNewSomething: 'Novo(a) {{something}}',
  txtCreateNewSomething: 'Criar novo(a) {{something}}',
  txtFeedbackDownloadQRCode:
    'É necessário preencher e salvar os campos de\ntítulo, unidade e marca para gerar o QR code.',
  txtFeedbackEmptyQRCode:
    'Nenhum QR Code encontrado para seu canal.\n Defina um estilo QR para gerar um QR Code e personaliza-lo ao seu Canal.',
  txtWatingQR: 'Aguardando geração de QR code',
  txtSomethingWentWrong: 'Algo de errado aconteceu',
  txtTargetingRules: 'Regras de segmentação',
  txtTargetingFallbackContent: 'Regras de segmentação não se aplicam a conteúdos de fallback',
  txtFallbackContent: 'Conteúdo de fallback',
  txtSaveAs: 'Salvar como {{something}}?',
  txtFallback: 'fallback',
  txtFallbackUpCase: 'Fallback',
  txtFallbackQuestionMark: 'Fallback?',
  txtTargetingRulesQuestionMark: 'Regras de segmentação?',
  txtTime: 'Tempo',
  txtScanHistory: 'Scan History',
  txtLocation: 'Local',
  txtInclude: 'Incluir',
  txtExclude: 'Excluir',
  txtMandatoryLocation: 'Você deve incluir no mínimo um local antes de criar uma campanha.',
  txtSearchLocation: 'Digite um local',
  txtSchedule: 'Planejamento',
  txtWeekdays: 'Dias da Semana',
  txtPeriod: 'Período',
  txtAllDay: 'Dia Inteiro',
  txtSaveFallbackModal:
    'Content Streams sem pelo menos uma regra de targeting serão salvos como fallback. Você tem certeza que não quer adicionar uma regra de targeting?',
  txtFallbackDeleteTargetsModal:
    "Regras de segmentação não se aplicam a conteúdos de fallback. Se você selecionar 'conteúdo de fallback' para este fluxo de conteúdo, todas as regras de segmentação serão removidas.",
  txtDeleteSomething: 'Deletar {{something}}',
  txtDiscardSomething: 'Descartar {{something}}',
  txtMon: 'Seg',
  txtTue: 'Ter',
  txtWed: 'Qua',
  txtThu: 'Qui',
  txtFri: 'Sex',
  txtSat: 'Sab',
  txtSun: 'Dom',
  txtStartDate: 'Data Inicial',
  txtTimeZone: 'Fuso Horário',
  txtEndDate: 'Data Final',
  txtSetEndDate: 'Definir uma data final',
  txtSelectValidNumber: 'Por favor, selecione um número válido',
  txtSelectValidDate: 'Por favor, selecione uma data válida',
  txtSelectValidEndDate: 'Por favor, selecione uma data de término maior que a data de início',
  txtSelectValidEndDateCurrent: 'Por favor, selecione uma data de término maior que a data atual',
  txtEnterValidTime: 'Por favor, digite um horário válido',
  txtEnterValidEndTime: 'Por favor, selecione um horário de término maior que o horário de início',
  txtEnterValidEndTimeCurrent: 'Por favor, selecione um horário de término maior que o horário atual',
  txtLink: 'Link',
  txtLinkChannels: 'Vincular canais',
  txtLinkFallback: 'Vincular fallback',
  txtSaveToUpdate: 'Salvar para atualizar',
  txtChangeFallback: 'Trocar fallback',
  txtContentStreamChannels: 'Por favor vincule canais a este content stream',
  txtFallbackContentStreamNoChannels:
    'Sentimos muito, mas não há canais vinculados a este content stream. Para vincular ou editar um vínculo existente, por favor visite a página de canais ',

  txtChannelsFallback: 'Por favor vincule um fallback a este canal',
  txtNoResultsForSearch: 'Nenhum resultado encontrado para a busca ',
  txtQRStyleCreationSuccess: 'Estilo do QR Code criado',
  txtSomethingWentWrongTryAgain: 'Algo deu errado. Por favor, tente novamente',
  txtQRStyleUpdateSuccess: 'Estilo do QR code atualizado',
  txtQRStyleNameUsed: 'O nome já está em uso',
  txtChannelsLinked: 'Canais Vinculados',
  txtQRTitlePlaceholder: 'Digite o título do novo estilo QR',
  txtDefaultQRStyleTitle: 'Novo estilo QR',
  txtDotsTitle: 'Pontos',
  txtDotsStyleLabel: 'Estilo dos pontos',
  txtBackgroundTitle: 'Fundo',
  txtColorTypeLabel: 'Tipo de cor',
  txtSingleColor: 'Cor única',
  txtGradientColor: 'Cor gradiente',
  txtColor: 'Cor',
  txtCornersDotsTitle: 'Opções dos pontos das arestas',
  txtCornersDotsStyleLabel: 'Estilo dos pontos das arestas',
  txtCornersSquareTitle: 'Opções dos quadrados das arestas',
  txtCornersSquareStyleLabel: 'Estilos dos quadrados das arestas',
  txtQROptionsTitle: 'Opções QR',
  txtQROptionsLabel: 'Nível de correção de erro',
  txtImageTitle: 'Opções da imagem',
  txtImageSizeLabel: 'Tamanho da imagem',
  txtMarginLabel: 'Margem',
  txtHideBackgroundDots: 'Ocultar pontos de fundo',
  txtFileUploadInfo: 'Apenas arquivos no(s) formato(s) {{accept}} com {{size}} MB ou menos',
  txtFileLabel: 'Escolha um arquivo',
  txtFileText: 'para realizar o upload ou arraste-o aqui',
  txtFileSizeError: 'O arquivo excede o limite de tamanho',
  txtFileFormatError: 'O arquivo possui formato inválido',
  txtSelectedStory: 'Story Selecionado',
  txtSelectedContents: 'Conteúdos Selecionados',
  txtContentNavigation: 'Navegação de Conteúdo',
  txtConfirmationModalLeaveEditTitle: 'Sair da edição',
  txtConfirmationModalLeaveEditText: 'Você tem certeza que deseja sair? Suas alterações não serão salvas.',
  txtButtonModalCancel: 'Sair sem salvar',
  txtButtonModal: 'Continuar editando',
  txtUploadFormatError: 'Formato do arquivo inválido',
  txtUploadSizeError: 'Tamanho do arquivo excede o limite',
  txtSelection: 'Seleção',
  txtStories: 'Stories',
  txtNoStoriesYet: 'Não existem stories criados ainda',
  txtNoContentsYet: 'Não existem conteúdos criados ainda',
  txtNoStoriesCreated: 'Parece que não temos stories criados ainda.',
  txtNoContentsCreated: 'Parece que não temos conteúdos criados ainda.',
  txtNoQRStylesCreatedTitle: 'Não existem estilos QR criados ainda',
  txtNoQRStylesCreated:
    'Parece que não temos estilos QR criados ainda. Você pode criar um estilo QR usando o botão abaixo.',
  txtCreateQRStyle: 'Criar estilo QR',
  txtFallbackAssociationTitle: 'Associação de Fallback',
  txtFallbackAssociation:
    'Todos os canais precisam de um conteúdo fallback associado a eles. Acesse a página de canais para vincular ou editar um vínculo existente',
  txtPublishedModalTitle: 'Salvar irá alterar o conteúdo publicado',
  txtPublishedModalBody: 'Este conteúdo está publicado, salvar agora pode alterar a experiência do usuário.',
  txtDeleted: 'Deletado',
  txtUnpublished: 'Não publicado',
  txtCantLoadContent: 'Não foi possível carregar este conteúdo',
  txtInvalidStoryWarning: 'Adicione um story ativo para publicar esse content stream',
  txtInvalidContentWarning: 'Adicione um conteúdo ativo para publicar esse content stream',
  txtAddChannelsToPublish: 'Adicione um canal para publicar este content stream',
  txtAddTargetsToPublish: 'Adicione um target para publicar este content stream',
  txtCreate: 'Criar',
  txtClearAll: 'Limpar seleção',
  txtSelectAll: 'Selecionar todos',
  txtNothingFound: 'Nada encontrado',
  txtNothingFoundTypeToCreateANewOne: 'Nada encontrado. Digite para criar um novo registro',
  txtSelected: 'selecionado',
  txtInvalidEmptyField: 'O campo não pode estar vazio',
  txtAnalytics: 'Analytics',
  txtInvalidEmptySelection: 'Selecione pelo menos uma opção',
  txtInvalidEmptySelectionLocation: 'Seleção não pode estar vazia',
  txtOptions: 'opções',
  txtValidDomainInput: 'Você atingiu o limite de 20 caracteres. Por favor, reduza o seu domínio.',
  txtTitleUsed: 'O título já está em uso',
  txtUniqueDomainTitle: 'Por favor, escolha um domínio cuidadosamente.',
  txtUniqueDomainMessage: 'Uma vez salvo, o domínio escolhido será permanente e não poderá ser alterado.',
  txtOrganisationExternalDomainAlert: 'Lembre-se de configurar o link no seu domínio.',
  txtConfigureCms: 'Acesse o CMS e configure esta marca.',
  txtConfigureCmsTitle: 'Configure o estilo da marca',
  txtGoToCms: 'Acessar o CMS',
  txtChannelFallbackWarning: 'Um fallback é necessário para habilitar este canal',
  txtTextCopied: 'Copiado para a área de transferência!',
  txtColorErrorMessage: 'Escolha uma cor diferente da cor do plano de fundo.',
  txtPreview: 'Visualizar',
  txtBrandFallbackTitle: 'Fallback da marca',
  txtBrandFallbackPlaceHolder: 'Digite a URL da sua marca',
  txtValidURL: 'Use uma URL válida',
  txtShortUrlPlaceholder: 'URL do canal',
  txtChannelLinkPlaceholder: 'O Link será gerado após salvar o canal',
  txtAnalyticsEmptyStateTitle: 'Nenhum relatório criado.',
  txtAnalyticsEmptyStateDescription:
    'Por favor, aguarde um momento e tente novamente mais tarde para verificar se a funcionalidade de relatórios está disponível.',
  txtRotation: 'Rotação',
  txtGradientType: 'Tipo de gradiente',
};

export default pt_BRLanguage;
