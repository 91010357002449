import { WppTab } from '@wppopen/components-library-react';
import { ComponentProps } from 'react';

export interface IDSTab extends ComponentProps<typeof WppTab> {}

const DSTab = ({ children, ...wppProps }: IDSTab) => {
  return <WppTab {...wppProps}>{children}</WppTab>;
};

export default DSTab;
