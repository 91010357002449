import { BoxContent, TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { DSIconCopy, DSInput, DSTypography } from 'libs/backoffice-frontend/src/shared';
import { SelectDropDownBottom } from 'libs/backoffice-frontend/src/shared/components';

export const FeedbackInfoQRCode = styled.span(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey500'],
  display: 'flex',
  alignItems: 'center',
  gap: '0.3rem',
}));

export const QRChannelContainer = styled(BoxContent)({
  gap: '2rem',
  padding: 8,
});

export const StyleContainer = styled(BoxContent)({
  display: 'inline-block',
  width: '100%',
});

export const QrStyleInput = styled(SelectDropDownBottom)({
  width: '45%',
  minWidth: 160,
  display: 'inline-block',
  marginRight: 16,
});

export const ContainerCreateStyleButton = styled.div({
  display: 'inline-block',
  verticalAlign: 'bottom',
  padding: '.9rem 0',
});

export const QRCodeContainer = styled(BoxContent)({
  flex: 'unset',
  gap: '1rem',
});

export const QRCodePreviewTitle = styled(DSTypography)({
  paddingLeft: 12,
  paddingTop: 8,
});

export const Container = styled(BoxContent)({
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const ContainerChannelShortUrl = styled(BoxContent)({
  display: 'inline-block',
  width: '100%',
});

export const ContainerDomainInput = styled(BoxContent)({
  display: 'inline-block',
  width: '50%',
  '@media (max-width: 905px)': {
    display: 'inline',
  },
});

export const DomainInput = styled(SelectDropDownBottom)({
  float: 'left',
  width: 'calc(100% - 46px)',
  minWidth: 160,
});

export const Slash = styled(BoxContent)({
  display: 'inline-block',
});

export const ContainerShortIdInput = styled(BoxContent)({
  display: 'inline-block',
  verticalAlign: 'top',
  width: '50%',
  '@media (max-width: 905px)': {
    display: 'inline',
  },
});

export const ShortIdInput = styled(DSInput)({
  display: 'inline-block',
  width: 'calc(100% - 46px)',
  marginRight: 4,
  minWidth: 120,
  '@media (max-width: 905px)': {
    marginTop: 16,
  },
});
export const ContainerToolTip = styled.div({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginTop: -2,
  '@media (max-width: 905px)': {
    marginTop: 0,
  },
});

export const PreviewContainer = styled.div(({ theme }: TStyledDefaultProps) => ({
  border: `1px solid ${theme?.palette?.['grey500']}`,
  borderRadius: '10px',

  '> *': {
    margin: '0.6rem 0.6rem 0 0.6rem',
  },
}));

export const IconCopyBare = styled(DSIconCopy)(
  ({ copyEnabled, theme }: TStyledDefaultProps & { copyEnabled: boolean }) => ({
    color: copyEnabled ? theme?.palette?.['primary500'] : theme?.palette?.['grey400'],
  }),
);

export const IconCopyOs = styled(DSIconCopy)(
  ({ copyEnabled, theme }: TStyledDefaultProps & { copyEnabled: boolean }) => ({
    color: copyEnabled ? 'inherit' : theme?.palette?.['grey400'],
  }),
);
