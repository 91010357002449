/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { StyledGrid } from './ContentPage.styled';

export interface IContentPageProps {}

const ContentPage = (props: React.PropsWithChildren<IContentPageProps>) => {
  return (
    <StyledGrid item all={24} fullHeight>
      {props.children}
    </StyledGrid>
  );
};

export default ContentPage;
