import { SetCallback } from '../../../shared/store';
import { DOTS_STYLE_DEFAULT_OPTIONS, PREVIEW_STYLE_DEFAULT_OPTIONS, STYLE_DEFAULT_OPTIONS } from '../utils/constants';

import {
  QRCornersDotsOptions,
  QRCornersSquareOptions,
  QRDotsOptions,
  QRImageOptions,
  QRBackgroundOptions,
  QROptions,
  QRCodeOptions,
  IQRValueChanges,
} from '../types';

export interface IQRStyleState {
  title: string;
  valueChanges: IQRValueChanges | null;
  hasAnyQRStyle?: boolean;
  synchronizedQrStyleId?: string; // TODO 4231: REMOVE SYNC LOGIC

  dotsOptions: QRDotsOptions; // ok
  cornersDotOptions: QRCornersDotsOptions; // ok
  cornersSquareOptions: QRCornersSquareOptions; // ok
  backgroundOptions: Required<QRBackgroundOptions>; // ok
  qrOptions: QROptions; // ok
  qrCodeOptions?: QRCodeOptions; // ok
  imageOptions: QRImageOptions; // ok
  image: string; // ok
}

export const initialState: IQRStyleState = {
  title: 'New QR Style',
  valueChanges: null,
  hasAnyQRStyle: false,
  synchronizedQrStyleId: 'new',

  dotsOptions: {
    color: DOTS_STYLE_DEFAULT_OPTIONS.COLOR[0].color,
    gradient: {
      colorStops: STYLE_DEFAULT_OPTIONS['Dots'].COLOR,
      type: STYLE_DEFAULT_OPTIONS['Dots'].GRADIENT_TYPE,
      rotation: parseFloat(STYLE_DEFAULT_OPTIONS['Dots'].ROTATION),
    },
  },
  cornersDotOptions: {
    color: STYLE_DEFAULT_OPTIONS['Corners'].COLOR[0].color,
    gradient: {
      colorStops: STYLE_DEFAULT_OPTIONS['Corners'].COLOR,
      type: STYLE_DEFAULT_OPTIONS['Corners'].GRADIENT_TYPE,
      rotation: parseFloat(STYLE_DEFAULT_OPTIONS['Corners'].ROTATION),
    },
  },
  cornersSquareOptions: {
    color: STYLE_DEFAULT_OPTIONS['Corners'].COLOR[0].color,
    gradient: {
      colorStops: STYLE_DEFAULT_OPTIONS['Corners'].COLOR,
      type: STYLE_DEFAULT_OPTIONS['Corners'].GRADIENT_TYPE,
      rotation: parseFloat(STYLE_DEFAULT_OPTIONS['Corners'].ROTATION),
    },
  },
  backgroundOptions: {
    round: 0,
    color: STYLE_DEFAULT_OPTIONS['Background'].COLOR[0].color,
    gradient: {
      colorStops: STYLE_DEFAULT_OPTIONS['Background'].COLOR,
      type: STYLE_DEFAULT_OPTIONS['Background'].GRADIENT_TYPE,
      rotation: parseFloat(STYLE_DEFAULT_OPTIONS['Background'].ROTATION),
    },
  },
  imageOptions: PREVIEW_STYLE_DEFAULT_OPTIONS.imageOptions,
  qrOptions: PREVIEW_STYLE_DEFAULT_OPTIONS.qrOptions,
  image: '',
};

const actions = (set: SetCallback<IQRStyleState>) => ({
  setTitle: (title?: string) => set((state) => ({ ...state, title: title ?? initialState.title })),

  setValueChanges: (valueChanges: IQRValueChanges | null) => set((state) => ({ ...state, valueChanges })),

  setHasAnyQRStyle: (value: boolean) => set((state) => ({ ...state, hasAnyQRStyle: value })),

  syncQRStylesOptions: (value: string) => set((state) => ({ ...state, synchronizedQrStyleId: value })),

  setDotsOptions: (dotsOptions?: QRDotsOptions) =>
    set((state) => ({ ...state, dotsOptions: dotsOptions ?? initialState.dotsOptions })),

  setCornersDotOptions: (cornersDotOptions?: QRCornersDotsOptions) =>
    set((state) => ({ ...state, cornersDotOptions: cornersDotOptions ?? initialState.cornersDotOptions })),

  setCornersSquareOptions: (cornersSquareOptions?: QRCornersSquareOptions) =>
    set((state) => ({ ...state, cornersSquareOptions: cornersSquareOptions ?? initialState.cornersSquareOptions })),

  setBackgroundOptions: (backgroundOptions?: QRBackgroundOptions) =>
    set((state) => ({ ...state, backgroundOptions: backgroundOptions ?? initialState.backgroundOptions })),

  setQrOptions: (qrOptions?: QROptions) =>
    set((state) => ({ ...state, qrOptions: qrOptions ?? initialState.qrOptions })),

  setQRCodeOptions: (qrCodeOptions?: QRCodeOptions) =>
    set((state) => ({ ...state, qrCodeOptions: qrCodeOptions ?? initialState.qrCodeOptions })),

  setImageOptions: (imageOptions?: QRImageOptions) =>
    set((state) => ({ ...state, imageOptions: imageOptions ?? initialState.imageOptions })),

  setImage: (image?: string) => set((state) => ({ ...state, image: image ?? initialState.image })),
});

export const slice = (set: SetCallback<IQRStyleState>) => ({
  ...initialState,
  ...actions(set),
});

export type IQRStyleActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
