import React from 'react';

import { interpolateWithValues } from '@connected-core-system/utils/types';
import { WppActionButton, WppCard, WppIconChevron, WppTypography } from '@wppopen/components-library-react';
import { BoxContent } from '../../../shared/components';
import { useHostName } from '../../../shared/hooks';
import { translate } from '../../../shared/locales/i18n';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { IconWarning, WarningText } from './styles/BrandPage.styled';

// TODO 4230 - REMOVE UNNECESSARY eslint-disable..
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISendToCmsCardProps {}

const SendToCmsCard: React.FC<ISendToCmsCardProps> = () => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const baseHostName = useHostName();

  const sendToCMS = () => {
      // TODO 4230 - MOVE THIS TO A UTILS FUNCTION OUT SIDE OF THE COMPONENT
    if (selectedOrganisation) {
      window.open(`${interpolateWithValues(baseHostName, selectedOrganisation?.domain ?? '')}/admin`, '_blank');
      return;
    }
  };
  return (
    // TODO 4230 - AVOID INLINE STYLES
    <WppCard style={{ marginTop: '12px' }} data-testid="warning-cms-card">
      <BoxContent>
        <BoxContent flex>
          <WppTypography type="m-strong">{translate('txtConfigureCmsTitle')}</WppTypography>
        </BoxContent>
        <BoxContent flex marg="8 0">
          <WarningText type="xs-body">{translate('txtConfigureCms')}</WarningText>
        </BoxContent>
        <WppActionButton onClick={sendToCMS} data-testid="go-to-cms-button">
          {translate('txtGoToCms')}
          <WppIconChevron direction="right" slot="icon-end" />
        </WppActionButton>
      </BoxContent>
    </WppCard>
  );
};

export default SendToCmsCard;
