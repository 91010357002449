/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { BoxContent, Divider } from '../../../shared/components';
import { getContentMenuItems, getLowerMenuItems, getUpperMenuItems, shouldCloseMenu } from '../utils/sideMenuUtils';
import { ISideMenuActions, ISideMenuState } from '../sliceStore/sideMenuSliceStore';
import MenuItem from './MenuItem';
import {
  AppTitleOs,
  ContainerBare,
  ContainerOs,
  DivisionTitleBare,
  DivisionTitleOs,
  FooterContainerBare,
  FooterContainerOs,
  MenuSectionBare,
  MenuSectionOs,
  SpaceExpandedResized,
} from './styles/SideMenu.styled';
import LogoText from './LogoText';
import { SelectOrganisation } from '../../Organisation';
import AdminSection from './AdminSection';
import { MenuItemEnum } from '../../../shared/types';
import { useSelectedOrganisation } from '../hooks/useSelectedOrganisation';
import { useIsAddTargetButtonOpenedState } from '../../ContentStreamCreate/hooks/useIsAddTargetButtonOpenedState';
import { useQRStyleState } from '../../QRStyle/hooks/useQRStyleState';
import ConfirmationModal from '../../../shared/components/Modals/ConfirmationModal';
import { translate } from '../../../shared/locales';
import { useSelectedSideMenuItemState } from '../hooks/useSelectedSideMenuItemState';
import { useUnitValueChangesState } from '../../UnitPage/hooks/useUnitValueChangesState';
import { useBrandValueChangesState } from '../../BrandPage/hooks';
import { useHostName } from '../../../shared/hooks';
import { interpolateWithValues } from '@connected-core-system/utils/types';
import { AppOsHider } from '../../../app';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface ISideMenuProps {}

const SideMenu: React.FC<ISideMenuProps> = () => {
  const isMenuOpened = useSliceState<ISideMenuState, 'isMenuOpened'>('isMenuOpened');
  const selectedMenuItem = useSliceState<ISideMenuState, 'selectedMenuItem'>('selectedMenuItem');
  const { setIsAddTargetButtonOpened } = useIsAddTargetButtonOpenedState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const { selectedOrganisation } = useSelectedOrganisation();
  const { valueChanges, setValueChanges } = useQRStyleState();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();
  const isSmallScreen = useSliceState<ISideMenuState, 'isSmallScreen'>('isSmallScreen');
  const setSideMenu = useSliceSetter<ISideMenuActions, 'switchSideMenu'>('switchSideMenu');
  const setSelectedMenuItem = useSliceSetter<ISideMenuActions, 'setSelectedMenuItem'>('setSelectedMenuItem');
  const setSmallScreen = useSliceSetter<ISideMenuActions, 'switchIsSmallScreen'>('switchIsSmallScreen');
  const navigate = useNavigate();
  const [clickedMenuItem, setClickedMenuItem] = useState<MenuItemEnum>();
  const { brandValueChanges, setBrandValueChanges } = useBrandValueChangesState();
  const { unitValueChanges, setUnitValueChanges } = useUnitValueChangesState();
  const baseHostName = useHostName();
  const { insideWppOs } = useContext(BackofficeAppContext);

  useEffect(() => {
    const handleResize = () => {
      const closeMenu = shouldCloseMenu();
      setSmallScreen(closeMenu);
      if (closeMenu && isMenuOpened) {
        setSideMenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    setSmallScreen(shouldCloseMenu());
    return () => window.removeEventListener('resize', handleResize);
  }, [setSideMenu, isMenuOpened, setSmallScreen]);

  const handleOnSelectMenuItem = (value: MenuItemEnum) => {
    if (value === MenuItemEnum.CMS && selectedOrganisation) {
      window.open(`${interpolateWithValues(baseHostName, selectedOrganisation?.domain ?? '')}/admin`, '_blank');
      return;
    }
    if (valueChanges?.newTitle === true || valueChanges?.newValue === true || unitValueChanges || brandValueChanges) {
      setClickedMenuItem(value);
      setOpenConfirmationModal(true);
    } else {
      setOpenConfirmationModal(false);
      navigateToMenuItem(value);
    }
  };

  const navigateToAnotherPage = (value: MenuItemEnum) => {
    if (selectedSideMenuItem === MenuItemEnum.QRSTYLES) {
      if (!valueChanges?.newTitle && !valueChanges?.newValue) {
        setSelectedMenuItem(value);
        navigate(`${selectedOrganisation?.id}/${value.toLowerCase()}`);
      }
    } else {
      setSelectedMenuItem(value);
      navigate(`${selectedOrganisation?.id}/${value.toLowerCase()}`);
    }
  };

  const navigateToMenuItem = (value: MenuItemEnum) => {
    if (selectedOrganisation) {
      if (value === MenuItemEnum.CMS) {
        window.open('/', '_blank');
        return;
      }
      navigateToAnotherPage(value);
    }
    setClickedMenuItem(value);
  };

  const handleClickSideMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsAddTargetButtonOpened(false);
  };

  const handleCancelConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setValueChanges({ newTitle: false, newValue: false });
    setUnitValueChanges(false);
    setBrandValueChanges(false);
    if (clickedMenuItem) {
      setSelectedMenuItem(clickedMenuItem);
      navigate(`${selectedOrganisation?.id}/${clickedMenuItem.toLowerCase()}`);
    }
  };

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <>
      {isMenuOpened && isSmallScreen && <SpaceExpandedResized />}
      <PlatformWrapper
        componentBare={ContainerBare}
        componentOs={ContainerOs}
        expanded={isMenuOpened}
        smallScreen={isSmallScreen}
        onClick={handleClickSideMenu}>
        <ConfirmationModal
          title={translate('txtConfirmationModalLeaveEditTitle')}
          text={translate('txtConfirmationModalLeaveEditText')}
          buttonCancelText={translate('txtButtonModalCancel')}
          buttonText={translate('txtButtonModal')}
          buttonAction={handleConfirmationModal}
          buttonCancelAction={handleCancelConfirmationModal}
          isOpen={openConfirmationModal}
          setIsOpen={setOpenConfirmationModal}
          isWarning={false}
        />

        <AppOsHider>
          <MenuSectionBare expanded={isMenuOpened}>
            <LogoText />
            <Divider {...(!isMenuOpened && { width: '50%' })} />
          </MenuSectionBare>
        </AppOsHider>

        <AppOsHider>
          <MenuSectionBare expanded={isMenuOpened}>
            <SelectOrganisation />
            <Divider {...(!isMenuOpened && { width: '50%' })} />
          </MenuSectionBare>
        </AppOsHider>

        <PlatformWrapper componentBare={MenuSectionBare} componentOs={MenuSectionOs} expanded={isMenuOpened}>
          {insideWppOs && isMenuOpened && <AppTitleOs>Scannable</AppTitleOs>}
          <BoxContent>
            {getUpperMenuItems().map((item) => (
              <MenuItem
                key={item.id}
                id={item.id}
                onSelect={handleOnSelectMenuItem}
                value={item.value}
                onlyIcon={!isMenuOpened}
                active={selectedMenuItem === item.id}
                icon={<item.Icon slot="left" />}
                disabled={!selectedOrganisation}
              />
            ))}
            <Divider margin={insideWppOs ? '.8rem 0 0 0' : 0} {...(!isMenuOpened && { width: '50%' })} />
            {!isSmallScreen ? (
              <PlatformWrapper componentBare={DivisionTitleBare} componentOs={DivisionTitleOs} expanded={isMenuOpened}>
                CONTENT
              </PlatformWrapper>
            ) : (
              ''
            )}
            {getContentMenuItems().map((item) => (
              <MenuItem
                key={item.id}
                id={item.id}
                onSelect={handleOnSelectMenuItem}
                value={item.value}
                onlyIcon={!isMenuOpened}
                active={selectedMenuItem === item.id}
                icon={<item.Icon slot="left" />}
                secondIcon={item.SecondIcon ? <item.SecondIcon slot="right" /> : undefined}
                disabled={!selectedOrganisation}
              />
            ))}
            <Divider margin={insideWppOs ? '.8rem 0 0 0' : 0} {...(!isMenuOpened && { width: '50%' })} />
            {getLowerMenuItems().map((item) => (
              <MenuItem
                key={item.id}
                id={item.id}
                onSelect={handleOnSelectMenuItem}
                value={item.value}
                onlyIcon={!isMenuOpened}
                active={selectedMenuItem === item.id}
                icon={<item.Icon slot="left" />}
                disabled={!selectedOrganisation}
              />
            ))}
          </BoxContent>
        </PlatformWrapper>

        <PlatformWrapper componentBare={FooterContainerBare} componentOs={FooterContainerOs} expanded={isMenuOpened}>
          <AdminSection />
        </PlatformWrapper>
      </PlatformWrapper>
    </>
  );
};

export default SideMenu;
