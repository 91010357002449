import { ComponentProps } from 'react';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import { translate } from '../../../shared/locales';
import { LastGridContainer } from './styles/ExpandablesStyle.styled';
import SelectColor from './SelectColor';

interface ColorInputGridProps {
  color: Array<{ color: string; offset: number }>;
  onChangeColor?: ComponentProps<typeof SelectColor>['onSelectColor'];
  disabled: boolean;
  isGradient: boolean;
  errorMessage?: string[];
}

export const ColorInputGrid: React.FC<ColorInputGridProps> = ({
  color,
  onChangeColor,
  isGradient,
  disabled,
  errorMessage,
}) => {
  const renderSelectSecondColor = () => {
    if (isGradient) {
      return (
        <SelectColor
          stateValue={color}
          disabled={disabled}
          onSelectColor={(params) => onChangeColor?.(params)}
          offset={1}
          errorMessage={errorMessage ? errorMessage[1] : ''}
          data-testid="gradient-select-color"
        />
      );
    }
    return null;
  };

  return (
    <LastGridContainer>
      <DSTypography type="s-strong">{translate('txtColor')}</DSTypography>
      <div>&nbsp;</div>
      <div>&nbsp;</div>

      <>
        <SelectColor
          stateValue={color}
          disabled={disabled}
          onSelectColor={(params) => onChangeColor?.(params)}
          offset={0}
          errorMessage={errorMessage ? errorMessage[0] : ''}
        />
        {renderSelectSecondColor()}
      </>
    </LastGridContainer>
  );
};
