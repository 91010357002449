/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppToggle } from '@wppopen/components-library-react';
import { BoxContent, BoxContentCenter } from '../../../shared/components';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';
import { contentStreamPageManager } from '../services';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { WppStyledWppProgressIndicator } from './styles/ContentStreamPublishToggleCell.styled';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { translate } from '../../../shared/locales';
import { UNCLICKABLE_TABLE_IDS } from '../../../shared/Constants/Constants';

export interface IContentStreamPublishToggleCellProps {
  isPublished: boolean;
  contentId: ContentStreamTableDataType['id'];
  handleToogleState?: (id: string, isPublished: boolean) => void;
  isDisabled: boolean;
  setPublishCellId?: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  promise: { error: boolean; errorToastMessage: string; success: boolean };
  setPromise: React.Dispatch<React.SetStateAction<{ error: boolean; errorToastMessage: string; success: boolean }>>;
}

const ContentStreamPublishToggleCell: React.FC<IContentStreamPublishToggleCellProps> = ({
  contentId,
  isPublished,
  handleToogleState,
  isDisabled,
  setPublishCellId,
  isLoading,
  promise,
  setPromise,
}) => {
  const { selectedOrganisation } = useSelectedOrganisationState();

  const renderLoading = () => {
    return (
      <BoxContentCenter justify="flex-start">
        <WppStyledWppProgressIndicator size={'s'} />
      </BoxContentCenter>
    );
  };

  const handleChange = (checked: boolean) => {
    if (selectedOrganisation) {
      setPublishCellId?.(contentId);
      contentStreamPageManager
        .updatePublishState(selectedOrganisation?.id, contentId, checked)
        .then(() => {
          setPromise({
            error: false,
            success: true,
            errorToastMessage: translate('txtErrorMessage'),
          });
          handleToogleState?.(contentId, checked);
        })
        .catch((error) => {
          setPromise({
            error: true,
            errorToastMessage: error.message || translate('txtErrorMessage'),
            success: false,
          });
        })
        .finally(async () => {
          setPublishCellId?.('');
          await new Promise((resolve) => setTimeout(resolve, 1));
          setPromise({
            error: false,
            success: false,
            errorToastMessage: translate('txtErrorMessage'),
          });
        });
    }
  };

  return (
    <BoxContent flex align="center">
      <FallbackWrapper {...promise} />
      {isLoading ? (
        renderLoading()
      ) : (
        <WppToggle
          disabled={isDisabled}
          checked={isPublished}
          onWppChange={({ detail: { checked } }) => handleChange(checked)}
          data-testId="toggle"
          id={UNCLICKABLE_TABLE_IDS.publishIcon}></WppToggle>
      )}
    </BoxContent>
  );
};

export default ContentStreamPublishToggleCell;
