import { WppRadio } from '@wppopen/components-library-react';
import { SelectChangeEventDetail, RadioGroupChangeEvent } from '@wppopen/components-library';
import { WppSelectCustomEvent, WppRadioGroupCustomEvent } from '@wppopen/components-library/dist/types/components';
import { translate } from '../../../shared/locales';
import { QRColorType } from '../types/QRStyleTypes';
import { QRSTYLE_FORM } from '../utils/constants';
import { RadioContainer, StyledRadioGroup } from './styles/ExpandablesStyle.styled';

interface ColorTypeRadioGroupProps {
  hasStyleField?: boolean;
  colorType: QRColorType;
  handleChange: (
    field: string,
    e: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => void;
}

export const ColorTypeRadioGroup: React.FC<ColorTypeRadioGroupProps> = ({
  hasStyleField = true,
  colorType,
  handleChange,
}) => {
  return (
    <StyledRadioGroup
      value={colorType}
      onWppChange={(e) => handleChange(QRSTYLE_FORM.colorType, e)}
      data-testid="color-type-radio-group"
      hasStyleField={hasStyleField}>
      <RadioContainer>
        <WppRadio
          name="single-color"
          value="single"
          data-testid="single-radio-button"
          labelConfig={{ text: translate('txtSingleColor') }}
          required
          checked={colorType === 'single'}
        />
        <WppRadio
          name="gradient-color"
          value="gradient"
          data-testid="gradient-radio-button"
          labelConfig={{ text: translate('txtGradientColor') }}
          required
          checked={colorType === 'gradient'}
        />
      </RadioContainer>
    </StyledRadioGroup>
  );
};
