import { QRCodeTemplatePutOrPostDto } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';
import repoInstance, { QRStyleRepository } from './QRStyleRepository';

export class QRStyleManager {
  constructor(private repo: QRStyleRepository) {}

  handleError(error?: unknown) {
    throw error;
  }

  errorQRStyle(error: UniquePropertyExistsException) {
    if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
      throw new UniquePropertyExistsException('name', 'value');
    }
    return this.handleError(error);
  }

  async createQRStyle(organisationId: string, qrStyle: QRCodeTemplatePutOrPostDto) {
    try {
      return await this.repo.createQRStyle(organisationId, qrStyle);
    } catch (error) {
      return this.errorQRStyle(error as UniquePropertyExistsException);
    }
  }

  async updateQRStyle(organisationId: string, templateId: string, qrStyle: QRCodeTemplatePutOrPostDto) {
    try {
      const data = await this.repo.updateQRStyle(organisationId, templateId, qrStyle);
      return data;
    } catch (error) {
      return this.errorQRStyle(error as UniquePropertyExistsException);
    }
  }

  async getChannelCount(organisationId: string, qrCodeTemplateId: string) {
    try {
      const count = await this.repo.getChannelCount(organisationId, qrCodeTemplateId);
      return count;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getQRStyleList(organisationId: string) {
    const res = await this.repo.getQRStyleList(organisationId);
    const qrStyles = res.data;
    return qrStyles;
  }

  async getQRStyleById(organisationId: string, qrStyleId: string) {
    try {
      const qrStyles = await this.getQRStyleList(organisationId);

      if (qrStyles.length) {
        return qrStyles.filter((q) => q.templateId === qrStyleId)[0];
      }

      return null;
    } catch (error) {
      return this.handleError(error);
    }
  }
}

export default new QRStyleManager(repoInstance);
