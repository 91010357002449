/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { WppIconDataHistogram, WppSpinner } from '@wppopen/components-library-react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { GetPowerBIEmbedDTO } from '@connected-core-system/utils/bff-http-dto';
import { translate } from '../../../shared/locales';
import { usePromise } from '../../../shared/hooks';
import { BoxContent, BoxContentCenter, ContentPage, Header, EmptyState } from '../../../shared/components';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { analyticsManager } from '../services';
import './styles/AnalyticsPage.css';
import { EmptyStateCard } from './styles/AnalyticsPage.styled';

export interface IAnalyticsPageProps {}

const AnalyticsPage: React.FC<IAnalyticsPageProps> = () => {
  const theme = useTheme() as ITheme;
  const { selectedOrganisation } = useSelectedOrganisationState();

  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration | undefined>(undefined);

  const [expirationTime, setExpirationTime] = useState<number>(0);

  const loadEmbedUrlData = useCallback(async () => {
    if (tryAgain) setTryAgain(false);
    if (selectedOrganisation?.id) {
      const result = (await analyticsManager.getEmbedUrl(selectedOrganisation.id)) as GetPowerBIEmbedDTO;
      if (result) {
        setReportConfig({
          type: 'report',
          embedUrl: result.url,
          tokenType: models.TokenType.Embed,
          accessToken: result.token,
          id: result.reportId,
          permissions: models.Permissions.All,
          settings: {
            navContentPaneEnabled: false,
            filterPaneEnabled: false,
            background: models.BackgroundType.Default,
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
            },
          },
        });

        const expirationTime = new Date(result.tokenExpiration).getTime() - new Date().getTime();
        if (expirationTime > 0) {
          setExpirationTime(expirationTime);
        }
        return result;
      }
    }
    return null;
  }, [selectedOrganisation?.id, tryAgain]);

  const { ...promiseStatusLoadEmbedUrl } = usePromise(loadEmbedUrlData);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (expirationTime) {
      timer = setTimeout(() => {
        setReportConfig(undefined);
        setIsRendered(false);
        setTryAgain(true);
      }, expirationTime);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [expirationTime]);

  return (
    <ContentPage>
      {!isRendered && !promiseStatusLoadEmbedUrl.error && (
        <BoxContentCenter marg="0 0 24 0">
          <WppSpinner size="l" />
        </BoxContentCenter>
      )}
      {promiseStatusLoadEmbedUrl.error && (
        <BoxContent pad="24" h="calc(100% - 24px)">
          <Header title={translate('txtAnalytics')} />
          <EmptyStateCard>
            <EmptyState
              title={translate('txtAnalyticsEmptyStateTitle')}
              titleIcon={
                <WppIconDataHistogram
                  slot="icon-start"
                  aria-label="Search icon"
                  color={theme.palette['grey1000']}
                  height={40}
                  width={40}
                />
              }
              text={translate('txtAnalyticsEmptyStateDescription')}
              buttonText={translate('txtTryAgain')}
              buttonAction={() => {
                setTryAgain(true);
              }}
              hasButton={false}
            />
          </EmptyStateCard>
        </BoxContent>
      )}
      {reportConfig && (
        <PowerBIEmbed
          embedConfig={reportConfig}
          eventHandlers={
            new Map([
              ['rendered', () => setIsRendered(true)],
              ['pageChanged', () => setIsRendered(false)],
            ])
          }
          cssClassName={'report-container'}
        />
      )}
    </ContentPage>
  );
};

export default AnalyticsPage;
