import { useEffect } from 'react';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar/hooks';
import { useQRStyleState } from '../hooks/useQRStyleState';
import Content from './Content';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisationState';
import { useLoadQrStyle } from '../hooks/useLoadQrStyle';
import LoadingSpinner from './LoadingSpinner';
import EmptyContent from './EmptyContent';

export interface IQRStyleProps {}

const NEW_QRSTYLE_ID = 'new';

const QRStylePage: React.FC<IQRStyleProps> = () => {
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const { selectedOrganisation } = useSelectedOrganisationState();

  const { hasAnyQRStyle, setQrStyleData, syncQRStylesOptions, synchronizedQrStyleId, setImage } = useQRStyleState();
  const creatingNew = !!(selectedSecondTierItem && selectedSecondTierItem.id === NEW_QRSTYLE_ID);

  const { data, loading } = useLoadQrStyle(creatingNew, selectedSecondTierItem?.id, selectedOrganisation?.id);

  useEffect(() => {
    if (creatingNew && synchronizedQrStyleId !== NEW_QRSTYLE_ID) {
      setQrStyleData('default');
      syncQRStylesOptions(NEW_QRSTYLE_ID);
    }
  }, [creatingNew, synchronizedQrStyleId, setQrStyleData, syncQRStylesOptions]);

  useEffect(() => {
    if (!creatingNew && data && data.templateId !== synchronizedQrStyleId && !loading) {
      setQrStyleData(data);
      syncQRStylesOptions(data.templateId);
    }
  }, [
    creatingNew,
    data,
    loading,
    setQrStyleData,
    syncQRStylesOptions,
    synchronizedQrStyleId,
    selectedSecondTierItem?.id,
  ]);

  useEffect(() => {
    setImage(data?.configuration.image);
  }, [selectedSecondTierItem?.id, data?.configuration.image, setImage]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const renderContent = () => {
    const hasContent = hasAnyQRStyle || selectedSecondTierItem?.id === NEW_QRSTYLE_ID;

    if (hasContent) {
      return <Content />;
    } else {
      return <EmptyContent />;
    }
  };

  return renderContent();
};

export default QRStylePage;
