import { QRCodeTemplateDTO } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useQRStyleState = () => {
  const title = useSliceState<IQRStyleState, 'title'>('title');
  const setTitle = useSliceSetter<IQRStyleActions, 'setTitle'>('setTitle');

  const backgroundOptions = useSliceState<IQRStyleState, 'backgroundOptions'>('backgroundOptions');
  const setBackgroundOptions = useSliceSetter<IQRStyleActions, 'setBackgroundOptions'>('setBackgroundOptions');

  const cornersDotOptions = useSliceState<IQRStyleState, 'cornersDotOptions'>('cornersDotOptions');
  const setCornersDotOptions = useSliceSetter<IQRStyleActions, 'setCornersDotOptions'>('setCornersDotOptions');

  const cornersSquareOptions = useSliceState<IQRStyleState, 'cornersSquareOptions'>('cornersSquareOptions');
  const setCornersSquareOptions = useSliceSetter<IQRStyleActions, 'setCornersSquareOptions'>('setCornersSquareOptions');

  const dotsOptions = useSliceState<IQRStyleState, 'dotsOptions'>('dotsOptions');
  const setDotsOptions = useSliceSetter<IQRStyleActions, 'setDotsOptions'>('setDotsOptions');

  const hasAnyQRStyle = useSliceState<IQRStyleState, 'hasAnyQRStyle'>('hasAnyQRStyle');
  const setHasAnyQRStyle = useSliceSetter<IQRStyleActions, 'setHasAnyQRStyle'>('setHasAnyQRStyle');

  const imageOptions = useSliceState<IQRStyleState, 'imageOptions'>('imageOptions');
  const setImageOptions = useSliceSetter<IQRStyleActions, 'setImageOptions'>('setImageOptions');

  const image = useSliceState<IQRStyleState, 'image'>('image');
  const setImage = useSliceSetter<IQRStyleActions, 'setImage'>('setImage');

  const qrCodeOptions = useSliceState<IQRStyleState, 'qrCodeOptions'>('qrCodeOptions');
  const setQRCodeOptions = useSliceSetter<IQRStyleActions, 'setQRCodeOptions'>('setQRCodeOptions');

  const qrOptions = useSliceState<IQRStyleState, 'qrOptions'>('qrOptions');
  const setQrOptions = useSliceSetter<IQRStyleActions, 'setQrOptions'>('setQrOptions');

  const valueChanges = useSliceState<IQRStyleState, 'valueChanges'>('valueChanges');
  const setValueChanges = useSliceSetter<IQRStyleActions, 'setValueChanges'>('setValueChanges');

  const synchronizedQrStyleId = useSliceSetter<IQRStyleState, 'synchronizedQrStyleId'>('synchronizedQrStyleId');
  const syncQRStylesOptions = useSliceSetter<IQRStyleActions, 'syncQRStylesOptions'>('syncQRStylesOptions');

  const setQrStyleData = (data: void | QRCodeTemplateDTO | null | undefined | 'default') => {
    const values = data === 'default' ? null : data;
    setTitle(values?.name);
    setDotsOptions(values?.configuration.dotsOptions);
    setCornersDotOptions(values?.configuration.cornersDotOptions);
    setCornersSquareOptions(values?.configuration.cornersSquareOptions);
    setBackgroundOptions(values?.configuration.backgroundOptions);
    setQrOptions(values?.configuration.qrOptions);
    setImageOptions(values?.configuration.imageOptions);
    setImage(values?.configuration.image);
  };

  return {
    backgroundOptions,
    cornersDotOptions,
    cornersSquareOptions,
    dotsOptions,
    imageOptions,
    image,
    qrCodeOptions,
    qrOptions,
    title,
    valueChanges,
    synchronizedQrStyleId,
    hasAnyQRStyle,
    setBackgroundOptions,
    setCornersDotOptions,
    setCornersSquareOptions,
    setDotsOptions,
    setHasAnyQRStyle,
    setImageOptions,
    setImage,
    setQRCodeOptions,
    setQrOptions,
    setTitle,
    setValueChanges,
    syncQRStylesOptions,
    setQrStyleData,
  };
};
