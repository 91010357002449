import { useEffect, useRef } from 'react';
import { CheckboxChangeEvent, InputChangeEventDetail } from '@wppopen/components-library';
import { WppCheckboxCustomEvent, WppInputCustomEvent } from '@wppopen/components-library/dist/types/components';
import { BoxContent, ExpandableCard } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { isArrayEmpty } from '../../../shared/utils';
import DSTypography from '../../../shared/DSComponents/DSTypography/DSTypography';
import DSCheckbox from '../../../shared/DSComponents/DSCheckbox/DSCheckbox';
import { useQRStyleState } from '../hooks/useQRStyleState';
import { IMAGE_STYLE_DEFAULT_OPTIONS, QRSTYLE_FORM } from '../utils/constants';
import { CheckboxContainer, GridContainer, StyledFileUpload, StyledInput } from './styles/ExpandablesStyle.styled';

export interface IImageStyleProps {}

const ImageStyle: React.FC<IImageStyleProps> = () => {
  const { image, setImage, imageOptions, setImageOptions, valueChanges, setValueChanges } = useQRStyleState();

  const fileUploadRef = useRef<HTMLWppFileUploadElement>(null);
  const hasImage = !!image;

  useEffect(() => {
    const handleBlobImage = async () => {
      if (hasImage) {
        const response = await fetch(image);
        const blob = await response.blob();
        const fileArr: File[] = [new File([blob], 'unknown')];
        if (fileUploadRef.current) {
          (fileUploadRef.current as { successList?: File[] }).successList = fileArr;
        }
      } else {
        if (fileUploadRef.current) {
          await fileUploadRef.current.reset();
        }
      }
    };

    handleBlobImage();
  }, [hasImage, image, setImage]);

  const handleFileUploadChange = (event: CustomEvent) => {
    if (!isArrayEmpty(event.detail.value)) {
      const files = event.detail.value as Array<File>;
      const reader = new FileReader();
      const newFile = files[0] ?? files;
      if (newFile) {
        reader.readAsDataURL(newFile);
        reader.onload = async function () {
          setImage(reader.result as string);
          setValueChanges({ ...valueChanges, newValue: true });
        };
      }
    } else {
      setImage('');
      setValueChanges({ ...valueChanges, newValue: true });
    }
  };

  const handleImageSizeChange = (newImageSize: string) => {
    setImageOptions({
      ...imageOptions,
      imageSize: Number(newImageSize),
    });
  };

  const handleMarginChange = (newMargin: string) => {
    setImageOptions({
      ...imageOptions,
      margin: Number(newMargin),
    });
  };

  const handleHideBackgroundDotsChange = (newHideBackgroundDots: boolean) => {
    setImageOptions({
      ...imageOptions,
      hideBackgroundDots: newHideBackgroundDots,
    });
  };

  const handleChange = (
    field: string,
    e: WppInputCustomEvent<InputChangeEventDetail> | WppCheckboxCustomEvent<CheckboxChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.imageSize:
        handleImageSizeChange(e.detail.value as string);
        break;
      case QRSTYLE_FORM.margin:
        handleMarginChange(e.detail.value as string);
        break;
      case QRSTYLE_FORM.hideBackgroundDots:
        handleHideBackgroundDotsChange(Object.values(e.detail)[1] as boolean);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  return (
    <ExpandableCard title={translate('txtImageTitle')}>
      <BoxContent direction="column" data-testid="image-style-container">
        <GridContainer>
          <DSTypography type="s-strong">{translate('txtImageSizeLabel')}</DSTypography>
          <DSTypography type="s-strong">{translate('txtMarginLabel')}</DSTypography>
          <div>&nbsp;</div>

          <StyledInput
            type="number"
            value={imageOptions?.imageSize?.toString() ?? IMAGE_STYLE_DEFAULT_OPTIONS.IMAGE_SIZE}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.imageSize, e)}
          />
          <StyledInput
            type="number"
            value={imageOptions?.margin?.toString() ?? IMAGE_STYLE_DEFAULT_OPTIONS.MARGIN}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.margin, e)}
          />
        </GridContainer>
        <CheckboxContainer>
          <BoxContent flex align="center">
            <DSCheckbox
              data-testid='hide-background-dots-checkbox'
              checked={imageOptions?.hideBackgroundDots ?? IMAGE_STYLE_DEFAULT_OPTIONS.HIDE_BACKGROUND_DOTS}
              labelConfig={{ text: translate('txtHideBackgroundDots') }}
              name="hide-background-dots"
              onWppChange={(e) => handleChange(QRSTYLE_FORM.hideBackgroundDots, e)}
              required
            />
          </BoxContent>
        </CheckboxContainer>
        <BoxContent flex>
          <StyledFileUpload
            data-testid='file-upload'
            ref={fileUploadRef}
            onWppChange={handleFileUploadChange}
            multiple={false}
            disabled={hasImage}
            locales={{
              label: translate('txtFileLabel'),
              text: translate('txtFileText'),
              sizeError: translate('txtFileSizeError'),
              formatError: translate('txtFileFormatError'),
              info: (accept: string, size: number) => translate('txtFileUploadInfo', { accept, size }),
            }}
          />
        </BoxContent>
      </BoxContent>
    </ExpandableCard>
  );
};

export default ImageStyle;
