/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppInput, WppIconSearch } from '@wppopen/components-library-react';
import { SearchContainer } from './SearchField.styled';
import { translate } from '../../../shared/locales/i18n';

export interface ISearchFieldProps {
  value: string;
  setValue(value: string): void;
}

const SearchField: React.FC<ISearchFieldProps> = ({ value, setValue }) => {
  const handleChange = (event: CustomEvent) => {
    setValue(event.detail.value);
  };

  return (
    <SearchContainer>
      <WppInput
        name="second-tier-sidebar-search-field"
        value={value}
        placeholder={translate('txtPlaceholderSearch')}
        message=""
        onWppChange={handleChange}>
        <WppIconSearch slot="icon-start" aria-label="Search icon" />
      </WppInput>
    </SearchContainer>
  );
};

export default SearchField;
