// TODO 4231 - REDUCE THIS HOOK TO ONE SINGLE HOOK - useQRStyleState
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IQRStyleActions, IQRStyleState } from '../sliceStore/qrStyleSliceStore';

export const useBackgroundOptionsState = () => {
  const backgroundOptions = useSliceState<IQRStyleState, 'backgroundOptions'>('backgroundOptions');
  const setBackgroundOptions = useSliceSetter<IQRStyleActions, 'setBackgroundOptions'>('setBackgroundOptions');

  return { backgroundOptions, setBackgroundOptions };
};
