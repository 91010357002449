import { useState } from 'react';
import { TreeType } from '@wppopen/components-library';
import { LoadingIndicator, TreeContainer, ListTree } from './styles/Tree.styled';
import { SecondTierSidebarItem } from '../types';
import { generateData, getDataWithoutChildren, treeActiveId, treeArchivedId } from '../utils';
import { useSecondTierSelectedItemState, useSecondTierReloadState, useInitializeSelectedItem } from '../hooks/';
import { EmptyState, ConfirmationModal } from '../../../shared/components/';
import { translate } from '../../../shared/locales';
import { MenuItemEnum } from '../../../shared/types';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useBrandValueChangesState } from '../../BrandPage/hooks/useBrandValueChangesState';
import { useUnitValueChangesState } from '../../UnitPage/hooks/useUnitValueChangesState';
import { useQRStyleState } from '../../QRStyle/hooks/useQRStyleState';

export interface ITreeProps {
  searchFieldValue: string;
  itemList: SecondTierSidebarItem[];
  loading: boolean;
  error: boolean;
}

const Tree: React.FC<ITreeProps> = ({ searchFieldValue, itemList, loading, error }) => {
  const { setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setSecondTierReload } = useSecondTierReloadState();
  const [activeListOpen, setActiveListOpen] = useState<boolean>(true);
  const [archivedListOpen, setArchivedListOpen] = useState<boolean>(false);
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const { valueChanges, setValueChanges } = useQRStyleState();
  const { brandValueChanges, setBrandValueChanges } = useBrandValueChangesState();
  const { unitValueChanges, setUnitValueChanges } = useUnitValueChangesState();
  const [clickedItem, setClickedItem] = useState<SecondTierSidebarItem | undefined>();

  useInitializeSelectedItem(itemList);

  const checkConfirmationModal = (item: SecondTierSidebarItem | undefined) => {
    const hasChanges = valueChanges?.newTitle || valueChanges?.newValue || unitValueChanges || brandValueChanges;

    if (hasChanges) {
      setOpenConfirmationModal(true);
    } else {
      setSelectedSecondTierItem(item);
      setOpenConfirmationModal(false);
    }
  };

  const handleClick = (event: CustomEvent) => {
    if (event.detail.reason === 'search') return;

    const itemId = event.detail.currentItem?.id;

    if (itemId === treeActiveId) {
      setActiveListOpen(!activeListOpen);
    } else if (itemId === treeArchivedId) {
      setArchivedListOpen(!archivedListOpen);
    } else {
      const item = itemList.find((activeItem) => activeItem.id === itemId);
      setClickedItem(item);
      checkConfirmationModal(item);
    }
  };

  const handleError = () => {
    if (error) {
      return (
        <EmptyState
          title={translate('txtSomethingWentWrong')}
          text={translate('txtErrorMessage')}
          buttonText={translate('txtTryAgain')}
          buttonAction={() => {
            setSecondTierReload(true);
          }}
        />
      );
    }
    return null;
  };

  const handleTree = () => {
    if (itemList.length > 0) {
      return (
        <ListTree
          search={searchFieldValue}
          data={getTreeData()}
          onWppChange={handleClick}
          disableOpenCloseAnimation
          data-testid="StyledWppTree"
        />
      );
    }
    return;
  };

  const handleLoadingPage = () => {
    if (loading) {
      return <LoadingIndicator variant="circle" />;
    }
    return;
  };

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleCancelConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setUnitValueChanges(false);
    setBrandValueChanges(false);
    setValueChanges({ newTitle: false, newValue: false });
    setSelectedSecondTierItem(clickedItem);
  };

  const getTreeData = (): TreeType[] => {
    if (selectedSideMenuItem === MenuItemEnum.QRSTYLES) {
      return getDataWithoutChildren(itemList);
    }
    return generateData(itemList, activeListOpen, archivedListOpen);
  };

  return (
    <TreeContainer data-testid="tree-container">
      <ConfirmationModal
        title={translate('txtConfirmationModalLeaveEditTitle')}
        text={translate('txtConfirmationModalLeaveEditText')}
        buttonCancelText={translate('txtButtonModalCancel')}
        buttonText={translate('txtButtonModal')}
        buttonAction={handleConfirmationModal}
        buttonCancelAction={handleCancelConfirmationModal}
        isOpen={openConfirmationModal}
        setIsOpen={setOpenConfirmationModal}
        isWarning={false}
        data-testid="confirmation-modal"
      />
      {handleError()}
      {handleLoadingPage()}
      {handleTree()}
    </TreeContainer>
  );
};

export default Tree;
