import styled from '@emotion/styled';
import DSButton from '../../DSComponents/DSButton/DSButton';
import DSTypography from '../../DSComponents/DSTypography/DSTypography';

export const StyledButton = styled(DSButton)({
  maxWidth: '75%',
  '&::part(button)': {
    width: '26rem',
    height: '4rem',
  },
});

export const StyledTypography = styled(DSTypography)({
  textAlign: 'center',
  maxWidth: '56rem',
  whiteSpace: 'normal',
});
